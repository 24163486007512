import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"

import PractitionerDetailHeader from "./PractitionerDetailHeader"
import PractitionerPatientTable from "./PractitionerPatientTable"
import PractitionerPatientFilter from "./PractitionerPatientFilter"

import { sortBy } from "../../utils/sortBy"
import Loading from "../layouts/Loading/Loading"
import moment from "moment"

import {
  getFilterPractitionerPatientAction,
  getPractitionerPatientList,
  getSinglePractitioner,
  resetFilterPractitionerPatientAction,
  savePractitionerPatientAdvanceFilter
} from "../../redux/actions/practitionerAction"

const PractitionerProfile = () => {
  const { practitionerId } = useParams()
  const [searchQuery, setSearchQuery] = useState("")
  const [sortColumn, setSortColumn] = useState({
    path: sortBy.AtoZ,
    order: 1
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [sortedData, setSortedData] = useState([])

  const { loading: practitionerListLoading } = useSelector(state => state.singlePractitioner)
  const { loading: patientLoading, practitionerPatient } = useSelector(
    state => state.practitionerPatient
  )
  const { practitionerPatientAdvFilter: patientAdvFilter } = useSelector(
    state => state.practitionerPatientAdvFilter
  )

  const filter = useSelector(state => state.practitionerFilterPatient)

  const [filterUserType, setFilterUserType] = useState([])
  const [tempLoading, setTempLoading] = useState(false) // eslint-disable-line
  const [allPatientFiltered, setAllPatientFiltered] = useState([])
  const [isFilteredPatient, setIsFilteredPatient] = useState(false)
  const [filteredWithoutSearch, setFilteredWithoutSearch] = useState([])

  const dispatch = useDispatch()
  useEffect(() => {
    if (!practitionerId) return
    dispatch(getSinglePractitioner(practitionerId))
    if (practitionerId !== undefined) {
      dispatch(getPractitionerPatientList(practitionerId))
    }
  }, [dispatch, practitionerId])

  const handleSearch = query => {
    setSearchQuery(query)
    setCurrentPage(1)
  }
  const showSearchedData = () => {
    let allPatients = []
    const query = searchQuery?.toLowerCase()
    if (filter?.getFilterUsersData && patientAdvFilter.userType !== "outpatient") {
      if (searchQuery) {
        allPatients = filter?.getFilterUsersData?.filter(m => {
          const fullName = `${m?.firstName}${m?.middleName || ""}${m?.lastName}`.toLowerCase()
          const reversedFullName = `${m?.lastName}${m?.middleName || ""}${
            m?.firstName
          }`.toLowerCase()
          const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
          return (
            m?.firstName?.toLowerCase().includes(query) ||
            m?.middleName?.toLowerCase().includes(query) ||
            m?.lastName?.toLowerCase().includes(query) ||
            m?.email?.toLowerCase().includes(query) ||
            String(m?.mobileNumber)?.toLowerCase().includes(query) ||
            m?.userName?.toLowerCase().includes(query) ||
            m?.id?.toLowerCase().includes(query) ||
            fullName.includes(trimmedSearchValue) ||
            reversedFullName.includes(trimmedSearchValue)
          )
        })
      } else {
        allPatients = filter?.getFilterUsersData
      }
    } else if (practitionerPatient !== undefined) {
      if (searchQuery) {
        let tempAllPatient = []
        if (sortedData?.length === 0) {
          tempAllPatient = isFilteredPatient ? filteredWithoutSearch : practitionerPatient
        } else {
          tempAllPatient = sortedData
        }
        allPatients = tempAllPatient.filter(m => {
          const fullName = `${m.firstName}${m?.middleName || ""}${m.lastName}`.toLowerCase()
          const reversedFullName = `${m.lastName}${m?.middleName || ""}${m.firstName}`.toLowerCase()
          const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
          return (
            m?.firstName?.toLowerCase().includes(query) ||
            m?.middleName?.toLowerCase().includes(query) ||
            m?.lastName?.toLowerCase().includes(query) ||
            m?.email?.toLowerCase().includes(query) ||
            String(m?.mobileNumber)?.toLowerCase().includes(query) ||
            m?.userName?.toLowerCase().includes(query) ||
            m?.id?.toLowerCase().includes(query) ||
            fullName.includes(trimmedSearchValue) ||
            reversedFullName.includes(trimmedSearchValue)
          )
        })
      } else if (patientAdvFilter?.userType) {
        allPatients = (isFilteredPatient ? filteredWithoutSearch : practitionerPatient)?.filter(
          patient => patient?.userType === patientAdvFilter?.userType
        )
      } else if (isFilteredPatient) {
        allPatients = filteredWithoutSearch
      } else {
        allPatients = practitionerPatient
      }
    }
    allPatients = allPatients.map(patient => {
      if (patient?.manualLog?.length) {
        let ccmLogCount = 0
        let rpmLogCount = 0
        let manualLogCount = 0
        if (
          moment(patient?.manualLogActiveTime).month() === moment().month() &&
          moment(patient?.manualLogActiveTime).year() === moment().year()
        ) {
          patient?.manualLog?.map(manual => {
            if (
              manual?.careType === "ccm" &&
              moment(manual?.careDate).month() === moment().month() &&
              moment(manual?.careDate).year() === moment().year()
            ) {
              ccmLogCount += manual?.count
            } else if (
              manual?.careType === "rpm" &&
              moment(manual?.careDate).month() === moment().month() &&
              moment(manual?.careDate).year() === moment().year()
            ) {
              rpmLogCount += manual?.count
            }
          })
          patient = { ...patient, rpmManualLogCount: rpmLogCount, ccmManualLogCount: ccmLogCount }
        } else {
          patient = {
            ...patient,
            rpmManualLogCount: rpmLogCount,
            ccmManualLogCount: ccmLogCount,
            manualLogCount: manualLogCount
          }
        }
      }
      if (
        patient?.rpmActiveDate?.length !== 0 &&
        moment(patient?.rpmActiveDate).month() === moment().month() &&
        moment(patient?.rpmActiveDate).year() === moment().year()
      ) {
        return { ...patient, monthlyLastestTotalReadingRPM: patient?.rpmReadingCount }
      } else {
        return { ...patient, monthlyLastestTotalReadingRPM: 0 }
      }
    })
    let temp = []
    if (sortColumn.path === sortBy.AtoZ) {
      temp = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    if (sortColumn.path === sortBy.ZtoA) {
      temp = allPatients?.sort((a, b) =>
        a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1
      )
    }
    setSortedData(temp)
    setAllPatientFiltered(temp)
    if (temp?.length === practitionerPatient?.length) setFilterUserType(temp) // this is added to handle the advancce filter condition
  }

  useEffect(() => {
    showSearchedData()
  }, [filter, practitionerPatient, searchQuery, patientAdvFilter]) //eslint-disable-line

  const applyFilters = (type, data) => {
    dispatch(savePractitionerPatientAdvanceFilter(data))
    setCurrentPage(1)
    if (type === "normal" && data?.facilityId) {
      dispatch(getFilterPractitionerPatientAction({ formData: data, practitionerId }))
    } else if (type === "normal" && !data?.facilityId) {
      setSortedData(filterUserType?.filter(patient => patient?.userType === data?.userType))
    } else {
      getOnBoardingPatients()
    }
  }

  const getOnBoardingPatients = async () => await dispatch(resetFilterPractitionerPatientAction())

  // if (sortColumn.path === sortBy.AtoZ) {
  //     sortedData = allPatients?.sort((a, b) => (a?.firstName?.toLowerCase().trim() > b?.firstName?.toLowerCase().trim() ? 1 : -1));
  // }
  const resetFilters = async cb => {
    setIsFilteredPatient(false)
    setAllPatientFiltered([])
    await dispatch(resetFilterPractitionerPatientAction())
    if (cb) {
      cb()
    }
  }

  // if (sortColumn.path === sortBy.ZtoA) {
  //     sortedData = allPatients?.sort((a, b) => (a?.firstName?.toLowerCase().trim() < b?.firstName?.toLowerCase().trim() ? 1 : -1));
  // }

  const sortReadingCount = val => {
    setIsFilteredPatient(true)
    const tempData = []
    const countExistsArr = allPatientFiltered.filter(
      x =>
        x?.rpmReadingCount !== null &&
        x?.rpmReadingCount !== undefined &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noCountArr = practitionerPatient.filter(
      x => x?.rpmReadingCount === undefined || x?.rpmReadingCount === null
    )
    const ascCountArr = countExistsArr.sort((a, b) => a?.rpmReadingCount - b?.rpmReadingCount)
    const descCountArr = countExistsArr.sort((a, b) => b?.rpmReadingCount - a?.rpmReadingCount)
    if (val === "asc") {
      const sd = noCountArr.concat(ascCountArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setTimeout(() => {
        setTempLoading(false)
        setFilteredWithoutSearch([...sd])
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    } else {
      const sd = descCountArr.concat(noCountArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setTimeout(() => {
        setTempLoading(false)
        setFilteredWithoutSearch([...sd])
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    }
  }
  const sortLatestReading = val => {
    const tempData = []
    setIsFilteredPatient(true)
    const dateExistsArr = allPatientFiltered?.filter(
      x =>
        x?.rpmActiveDate !== null &&
        x?.rpmActiveDate !== undefined &&
        x?.rpmActiveDate !== "" &&
        x?.rpmActiveDate &&
        moment(x?.rpmActiveDate).month() === moment().month() &&
        moment(x?.rpmActiveDate).year() === moment().year()
    )
    const noDateArr = allPatientFiltered?.filter(
      x => x?.rpmActiveDate === undefined && x?.rpmActiveDate === null && x?.rpmActiveDate === ""
    )
    const ascDateArr = dateExistsArr.sort(
      (a, b) => new Date(a?.rpmActiveDate) - new Date(b?.rpmActiveDate)
    )
    const descDateArr = dateExistsArr.sort(
      (a, b) => new Date(b?.rpmActiveDate) - new Date(a?.rpmActiveDate)
    )
    if (val === "asc") {
      const sd = noDateArr.concat(ascDateArr)
      // console.log(sd)
      setTempLoading(true)
      setSortedData([...tempData])
      setFilteredWithoutSearch([...sd])
      setTimeout(() => {
        setTempLoading(false)
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    } else {
      const sd = descDateArr.concat(noDateArr)
      setTempLoading(true)
      setSortedData([...tempData])
      setTimeout(() => {
        setTempLoading(false)
        setFilteredWithoutSearch([...sd])
        setSortedData([...sd])
      }, 1500)
      //    setSortedData([...sd])
    }
  }
  const filterCriticalReading = val => {
    setIsFilteredPatient(true)
    const countExistsArr = allPatientFiltered.filter(x => x?.criticalStatus === true)
    setTempLoading(true)
    setTimeout(() => {
      setTempLoading(false)
      setSortedData([...countExistsArr])
      setFilteredWithoutSearch([...countExistsArr])
    }, 1500)
  }

  return practitionerListLoading ? (
    <Loading />
  ) : (
    <>
      <PractitionerDetailHeader practitionerId={practitionerId} />

      <PractitionerPatientFilter
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        setSearchQuery={setSearchQuery}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        sortReadingCount={sortReadingCount}
        sortLatestReading={sortLatestReading}
        filterCriticalReading={filterCriticalReading}
      />

      {patientLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spin size="large" />
        </div>
      ) : (
        <PractitionerPatientTable
          loading={patientLoading || tempLoading || filter?.loading}
          sortedData={sortedData}
        />
      )}
    </>
  )
}

export default PractitionerProfile
