import {
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAIL,
  GET_ADMIN_FAIL,
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPLOAD_REQUEST,
  IMAGE_UPLOAD_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  VALIDATE_LOGIN_DETAILS_FAIL,
  VALIDATE_LOGIN_DETAILS_REQUEST,
  VALIDATE_LOGIN_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  EMAIL_UPDATE_REQUEST,
  EMAIL_UPDATE_SUCCESS,
  EMAIL_UPDATE_FAIL,
  PHONE_UPDATE_REQUEST,
  PHONE_UPDATE_SUCCESS,
  PHONE_UPDATE_FAIL,
  CARETAKER_UPDATE_REQUEST,
  CARETAKER_UPDATE_SUCCESS,
  CARETAKER_UPDATE_FAIL,
  USER_LOGIN_RESET,
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAIL,
  POST_FEEDBACK_RESET,
  IMAGE_UPLOAD_RESET
} from "../types/userConstant"

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

const initialState = {
  userInfo: { loading: false, userInfo: userInfoFromStorage }
}

export const googleLoginReducer = (state = initialState.userInfo, action) => {
  switch (action.type) {
    case GOOGLE_LOGIN_REQUEST:
      return { loading: true }
    case GOOGLE_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case GOOGLE_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
    }
  }

export const userLoginReducer = (state = initialState.userInfo, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGIN_RESET:
      return {loading: false}
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const validateLoginDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VALIDATE_LOGIN_DETAILS_REQUEST:
      return { loading: true }
    case VALIDATE_LOGIN_DETAILS_SUCCESS:
      return { loading: false, data: action.payload }
    case VALIDATE_LOGIN_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}


export const passwordUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_UPDATE_REQUEST:
      return { loading: true }
    case PASSWORD_UPDATE_SUCCESS:
      return { loading: false, passwordUpdate: action.payload }
    case PASSWORD_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const ProfilePicReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_UPLOAD_REQUEST:
      return { loading: true }
    case IMAGE_UPLOAD_SUCCESS:
      return { loading: false, profilePic: action.payload }
    case IMAGE_UPLOAD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const ImageReducer = (state = { imageUploader: null }, action) => {
  switch (action.type) {
    case IMAGE_UPLOAD_REQUEST:
      return { loading: true }
    case IMAGE_UPLOAD_SUCCESS:
      return { loading: false, imageUploader: action.payload }
    case IMAGE_UPLOAD_FAIL:
      return { loading: false, error: action.payload }    
    case IMAGE_UPLOAD_RESET:
      return { loading: false, error: null }
    default:
      return state
  }
}

export const getAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ADMIN_REQUEST:
      return { loading: true }
    case GET_ADMIN_SUCCESS:
      return { loading: false, profileImage: action.payload }
    case GET_ADMIN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const emailUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_UPDATE_REQUEST:
      return { emailLoading: true }
    case EMAIL_UPDATE_SUCCESS:
      return { emailLoading: false, emailUpdate: action.payload }
    case EMAIL_UPDATE_FAIL:
      return { emailLoading: false, error: action.payload }
    default:
      return state
  }
}

export const phoneUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PHONE_UPDATE_REQUEST:
      return { phoneLoading: true }
    case PHONE_UPDATE_SUCCESS:
      return { phoneLoading: false, phoneUpdate: action.payload }
    case PHONE_UPDATE_FAIL:
      return { phoneLoading: false, error: action.payload }
    default:
      return state
  }
}

export const caretakerUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CARETAKER_UPDATE_REQUEST:
      return { caretakerLoading: true }
    case CARETAKER_UPDATE_SUCCESS:
      return { caretakerLoading: false, caretakerUpdate: action.payload }
    case CARETAKER_UPDATE_FAIL:
      return { caretakerLoading: false, error: action.payload }
    default:
      return state
  }
}

export const postFeedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_FEEDBACK_REQUEST:
      return { loading: true }
    case POST_FEEDBACK_SUCCESS:
      return { loading: false, status: action.payload }
    case POST_FEEDBACK_FAIL:
      return { loading: false, error: action.payload }
    case POST_FEEDBACK_RESET:
      return {}
    default:
      return state
  }
}
