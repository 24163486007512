export const DASHBOARD_KPI_REQUEST = "DASHBOARD_KPI_REQUEST"
export const DASHBOARD_KPI_SUCCESS = "DASHBOARD_KPI_SUCCESS"
export const DASHBOARD_KPI_FAIL = "DASHBOARD_KPI_FAIL"
export const CRITICAL_PATIENT_REQUEST = "CRITICAL_PATIENT_REQUEST"
export const CRITICAL_PATIENT_SUCCESS = "CRITICAL_PATIENT_SUCCESS"
export const CRITICAL_PATIENT_FAIL = "CRITICAL_PATIENT_FAIL"
export const CRITICAL_PATIENT_RESET = "CRITICAL_PATIENT_RESET"
export const FILTER_DASHBOARD_REQUEST = "FILTER_DASHBOARD_REQUEST"
export const FILTER_DASHBOARD_SUCCESS = "FILTER_DASHBOARD_SUCCESS"
export const FILTER_DASHBOARD_FAIL = "FILTER_DASHBOARD_FAIL"

export const GET_USER_COUNT_STATS_REQUEST = "GET_USER_COUNT_STATS_REQUEST"
export const GET_USER_COUNT_STATS_SUCCESS = "GET_USER_COUNT_STATS_SUCCESS"
export const GET_USER_COUNT_STATS_FAIL = "GET_USER_COUNT_STATS_FAIL"

export const GET_ENROLLMENT_STATS_REQUEST = "GET_ENROLLMENT_STATS_REQUEST"
export const GET_ENROLLMENT_STATS_SUCCESS = "GET_ENROLLMENT_STATS_SUCCESS"
export const GET_ENROLLMENT_STATS_FAIL = "GET_ENROLLMENT_STATS_FAIL"

export const GET_ENROLLMENT_PATIENT_COUNT_REQUEST = "GET_ENROLLMENT_PATIENT_COUNT_REQUEST"
export const GET_ENROLLMENT_PATIENT_COUNT_SUCCESS = "GET_ENROLLMENT_PATIENT_COUNT_SUCCESS"
export const GET_ENROLLMENT_PATIENT_COUNT_FAIL = "GET_ENROLLMENT_PATIENT_COUNT_FAIL"

export const GET_TRIAGE_STATS_ANALYSIS_REQUEST = "GET_TRIAGE_STATS_ANALYSIS_REQUEST"
export const GET_TRIAGE_STATS_ANALYSIS_SUCCESS = "GET_TRIAGE_STATS_ANALYSIS_SUCCESS"
export const GET_TRIAGE_STATS_ANALYSIS_FAIL = "GET_TRIAGE_STATS_ANALYSIS_FAIL"

export const GET_TRIAGE_STATS_COUNT_REQUEST = "GET_TRIAGE_STATS_COUNT_REQUEST"
export const GET_TRIAGE_STATS_COUNT_SUCCESS = "GET_TRIAGE_STATS_COUNT_SUCCESS"
export const GET_TRIAGE_STATS_COUNT_FAIL = "GET_TRIAGE_STATS_COUNT_FAIL"