import {
  GET_ALL_PATIENT_STATUS_FAIL,
  GET_ALL_PATIENT_STATUS_REQUEST,
  GET_ALL_PATIENT_STATUS_SUCCESS,
  GET_SHIPPING_STATUS_FAIL,
  GET_SHIPPING_STATUS_REQUEST,
  GET_SHIPPING_STATUS_SUCCESS,
  UPDATE_PATIENT_STATUS_FAIL,
  UPDATE_PATIENT_STATUS_REQUEST,
  UPDATE_PATIENT_STATUS_RESET,
  UPDATE_PATIENT_STATUS_SUCCESS,
  UPDATE_SHIPPING_STATUS_FAIL,
  UPDATE_SHIPPING_STATUS_REQUEST,
  UPDATE_SHIPPING_STATUS_RESET,
  UPDATE_SHIPPING_STATUS_SUCCESS
} from "../types/patientStatusConstant"

const initialState = {
  patientStatus: {
    patientStatus: {},
    loading: false
  },
  updatePatientStatus: { loading: false, error: null },
  shipmentStatus: {
    shipmentStatus: {},
    loading: false
  },
  updateshipmentStatus: { loading: false, error: null }
}

export const getPatientStatusReducer = (state = initialState.patientStatus, action) => {
  switch (action.type) {
    case GET_ALL_PATIENT_STATUS_REQUEST:
      return { loading: true }
    case GET_ALL_PATIENT_STATUS_SUCCESS:
      return { loading: false, patientStatus: action.payload }
    case GET_ALL_PATIENT_STATUS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updatePatientStatusReducer = (state = initialState.updatePatientStatus, action) => {
  switch (action.type) {
    case UPDATE_PATIENT_STATUS_REQUEST:
      return { loading: true }
    case UPDATE_PATIENT_STATUS_SUCCESS:
      return { loading: false, error: null }
    case UPDATE_PATIENT_STATUS_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_PATIENT_STATUS_RESET:
      return { loading: false, error: null }
    default:
      return state
  }
}

export const getShipmentStatusReducer = (state = initialState.shipmentStatus, action) => {
  switch (action.type) {
    case GET_SHIPPING_STATUS_REQUEST:
      return { loading: true }
    case GET_SHIPPING_STATUS_SUCCESS:
      return { loading: false, shipmentStatus: action.payload }
    case GET_SHIPPING_STATUS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateShipmentStatusReducer = (state = initialState.updateshipmentStatus, action) => {
  switch (action.type) {
    case UPDATE_SHIPPING_STATUS_REQUEST:
      return { loading: true }
    case UPDATE_SHIPPING_STATUS_SUCCESS:
      return { loading: false, error: null }
    case UPDATE_SHIPPING_STATUS_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_SHIPPING_STATUS_RESET:
      return { loading: false, error: null }
    default:
      return state
  }
}