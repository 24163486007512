import React from "react"
import { useParams } from "react-router-dom"

import { useToasts } from "react-toast-notifications"
import { Modal, Input, Row, Col, Button } from "antd"

import { useDispatch } from "react-redux"
import { getPatientInfo } from "../../../redux/actions/patientAction"
import { updateLandlineAction } from "../../../redux/actions/utilsActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const schema = yup
  .object({
    landLineCode: yup.string().min(1).max(5).required("Invalid Code"),
    extension: yup.string().min(1).max(5).required("Invalid Extension"),
    landLineNumber: yup.string().min(10).max(10).required("Invalid Landline Number")
  })
  .required()

const UpdatePatientLandline = props => {
  const {
    showLandlineModal,
    existingExtension,
    existingLandlineCode,
    existingLandlineNumber,
    handleShowLandlineModalClose
  } = props

  const { id } = useParams()

  const dispatch = useDispatch()

  const { addToast } = useToasts()

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      extension: existingExtension || "",
      landLineCode: existingLandlineCode || "",
      landLineNumber: existingLandlineNumber || ""
    }
  })

  const handleClose = () => {
    reset()
    handleShowLandlineModalClose()
  }

  const onSubmit = async data => {
    const result = await dispatch(
      updateLandlineAction(id, data.landLineCode, data.landLineNumber, data.extension)
    )
    if (result?.success) {
      handleClose()
      addToast("Landline Number Updated Successfully", {
        appearance: "success",
        autoDismiss: true
      })
      dispatch(getPatientInfo({ patientId: id }))
    } else {
      addToast(result, {
        appearance: "error",
        autoDismiss: true
      })
    }
  }

  return (
    <Modal
      footer={null}
      onCancel={handleClose}
      open={showLandlineModal}
      title={<h4 className="text-xl">Landline Number Update</h4>}>
      <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col xs={24} md={6} className="mb-4">
            <label className="mb-2" htmlFor="input-landLineCode">
              LandLine Code
            </label>
            <Controller
              control={control}
              name="landLineCode"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  status={errors.landLineCode ? "error" : undefined}
                  size="large"
                  type="number"
                  id="input-landLineCode"
                  {...field}
                />
              )}
            />
            {errors.landLineCode ? (
              <small className="text-danger">{errors.landLineCode.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={10} className="mb-4">
            <label className="mb-2" htmlFor="input-landLineNumber">
              LandLine Number
            </label>
            <Controller
              control={control}
              name="landLineNumber"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  status={errors.landLineNumber ? "error" : undefined}
                  size="large"
                  type="number"
                  id="input-landLineNumber"
                  {...field}
                />
              )}
            />
            {errors.landLineNumber ? (
              <small className="text-danger">{errors.landLineNumber.message}</small>
            ) : null}
          </Col>
          <Col xs={24} md={8} className="mb-4">
            <label className="mb-2" htmlFor="input-extension">
              Extension
            </label>
            <Controller
              control={control}
              name="extension"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  status={errors.extension ? "error" : undefined}
                  size="large"
                  type="number"
                  id="input-extension"
                  {...field}
                />
              )}
            />
            {errors.extension ? (
              <small className="text-danger">{errors.extension.message}</small>
            ) : null}
          </Col>
          <Col xs={24}>
            <div className="flex flex-wrap gap-3">
              <Button size="large" type="primary" htmlType="submit">
                Submit
              </Button>
              <Button size="large" danger onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </Modal>
  )
}

export default UpdatePatientLandline
