import React from "react"
import { Modal, Button } from "antd"

const HeartRateSubNoAlertModal = ({ showheartRateSubNoAlert, setShowHeartRateSubNoAlert }) => {
  return (
    <Modal
      open={showheartRateSubNoAlert}
      onCancel={() => setShowHeartRateSubNoAlert(false)}
      footer={null}
      title={<h4 className="text-xl">Note</h4>}>
      <div className="pt-4">
        <p className="font-medium">
          The answers to these questions will help you and the patient better understand why they
          may be experiencing an elevated blood pressure reading. Along the way, educate the patient
          to make healthy lifestyle changes and incorporate any appropriate nursing
          interventions/recommendations related to lowering blood pressure. Some lifestyle
          modifications for high blood pressure include: healthy diet, decrease salt/sodium intake,
          daily exercise, weight loss, reduce caffeine/alcohol/smoking, take blood pressure
          medication as prescribed.
        </p>

        <div className="mt-4">
          <Button type="primary" onClick={() => setShowHeartRateSubNoAlert(false)}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default HeartRateSubNoAlertModal
