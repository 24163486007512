import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import "./EligiblePatient.css"

import { useSelector, useDispatch } from "react-redux"
import OnboardedPatient from "./OnboardedPatient"
import OnboardingPendingPatient from "./OnboardingPendingPatient"
import { getSelfOnboardingPendingPatientAction } from "../../../redux/actions/selfOnboardingAction"
import { OnBoardedStage, OnBoardingPendingStage } from "./Types"
import { useParams } from "react-router-dom"

const EligiblePatient = () => {
  const [onboardPendingPatient, setOnboardPendingPatient] = useState()

  const [selfOnboardedPatients, setSelfOnboardedPatients] = useState()
  const [patientLoading, setPatientLoading] = useState(true)
  const dispatch = useDispatch()
  const {orgId} = useParams()

  const { selfOnboardingPendingPatient, loading: selfOnboardLoading } = useSelector(
    (state) => state.selfOnboardingPendingPatient
  )
  const { onboardpatientStatistics, loading: onboardPatientStatisticLoading } = useSelector(
    (state) => state.getOrgOnboardPatientStatistics
  )
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, patientDeatils) => (
        <p
          className="capitalize wrap-text mb-0">
          {name?.toLowerCase()}
        </p>
      )
    },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Onboarding Stage", dataIndex: "onBoardingStage", key: "onBoardingStage",
      sorter: (a, b) => a.onBoardingStage.localeCompare(b.onBoardingStage)
    },
    {
      title: "Patient Type", dataIndex: "userType", key: "userType ",
      sorter: (a, b) => a.userType.localeCompare(b.userType)
    },
    {
      title: <span className="dragHandler cursor-move">Enrolled By</span>,
      dataIndex: "createdBy",
      key: "createdBy",
      render: (createdBy, { deniedService }) => (
        <span className="capitalize">
          {deniedService ? "-" : createdBy}
        </span>
      )
    },
    {
      title: <span className="dragHandler cursor-move">Declined To</span>,
      dataIndex: "deniedService",
      key: "deniedService",
      render: (deniedService, { createdBy }) => (
        <span className="capitalize">
          {deniedService ? createdBy : "-"}
        </span>
      )
    },
    // { title: "Address", dataIndex: "address", key: "address" },
  ]

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("onboardComplete")
  // Function to handle tab change
  const handleTabChange = (key) => {
    setActiveTab(key)
  }

  useEffect(() => {
   // if (!caretakerLists) return

    if (selfOnboardingPendingPatient) {
      const pendingSignup = selfOnboardingPendingPatient?.filter((patient) => !patient.signup)
      const completeSignup = selfOnboardingPendingPatient?.filter((patient) => patient.signup)

      const mapPatient = (p) => ({
        id: p.id,
        dob: p.dob,
        email: p.email,
        phone: p.mobileNumber,
        name: `${p.firstName} ${p.middleName || ""} ${p.lastName}`,
        firstName: p.firstName,
        lastName: p.lastName,
        middleName: p.middleName,
        gender: p.gender,
        telephone: p.telephone,
        extension: p.extension,
        userType: p.typeOfPatient,
        createdBy: `${p?.enrolledByFirstName ?? "-"} ${p?.enrolledByMiddleName ?? ""} ${p?.enrolledByLastName ?? ""}`,
        deniedService: p?.deniedService,
        onBoardingStage: p?.deniedService
          ? OnBoardingPendingStage.DeniedService
          : p.signup
          ? OnBoardedStage.SignedUp
          : p.signupPending
          ? OnBoardingPendingStage.SignUpPending
          : OnBoardingPendingStage.SignUpIntiated
      })

      const pendingPatients = pendingSignup.map(mapPatient)
      const onboardedPatients = completeSignup.map(mapPatient)

      setOnboardPendingPatient(pendingPatients)
      //  setFilteredPatients(pendingPatients)
      setSelfOnboardedPatients(onboardedPatients)
      //  setFilteredOnboaredPatients(onboardedPatients)
    }
   
  }, [selfOnboardLoading, selfOnboardingPendingPatient])


  useEffect(() => {
    if (selfOnboardedPatients) setPatientLoading(false)
  }, [selfOnboardedPatients, onboardPendingPatient])

  useEffect(() => {
    if(!orgId) return
    dispatch(getSelfOnboardingPendingPatientAction(orgId))
  }, [dispatch])

  const patientTabItems = [
    {
      key: "onboardComplete",
      // @ts-expect-error
      label: `Onboarded Patients (${onboardpatientStatistics?.singedUpCount ?? "-"})`,
      children: (
        <OnboardedPatient
          columns={columns?.filter(c => c.key !== "deniedService")}
          selfOnboardedPatients={selfOnboardedPatients}
          patientLoading={patientLoading}
        />
      )
    },
    {
      key: "onboardPending",
      label: `Onboarding Pending Patients (${onboardpatientStatistics?.pendingCount ?? "-"})`,
      children: (
        <OnboardingPendingPatient
          columns={columns?.filter(c => c.key !== "createdBy")}
          onboardPendingPatient={onboardPendingPatient} />
      )
    }
  ]

  return (
    <div className="mt-2 ">
      <Tabs
        activeKey={activeTab}
        type="card"
        defaultActiveKey="onboardComplete"
        items={patientTabItems}
        onChange={handleTabChange}
      />
    </div>
  )
}
export default EligiblePatient
