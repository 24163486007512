import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import moment from "moment"
import { Card } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { getrpmCareplanDataActions } from "../../../../redux/actions/rpmAction"
import {
  getPatientCaretaker,
  getPatientMonitoringData,
  getPatientDiagnosticsData
} from "../../../../redux/actions/patientAction"

import RPMFollowUpQa from "./RPMFolllowUpQa"
import Loading from "../../../layouts/Loading/Loading"

const RPMFollowUpQuesView = props => {
  const { id } = useParams()
  const [treatementPlan, setTreatementPlan] = useState()
  const [dateOfService, setDateOfService] = useState("")
  const [patientICDCodes, setPatientICDCodes] = useState([])
  const [treatementPlanDate, setTreatementPlanDate] = useState(null)
  const [patientCaretakerData, setPatientCaretakerData] = useState([])
  const [patientMonitoringTypesData, setPatientMonitoringTypesData] = useState({})

  const dispatch = useDispatch()
  const location = useLocation()

  const patientInfoDetail = {
    id: id,
    firstName: location.state?.name.firstName,
    middleName: location.state?.name.middleName,
    lastName: location.state?.name.lastName,
    dob: location.state?.dob
  }

  // useSelectors
  const { loading: rpmLoading } = useSelector(state => state.rpmPatientCarePlan)
  // const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const { patientCaretaker } = useSelector(state => state.patientCaretaker)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { loading, patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)

  useEffect(() => {
    if (id !== undefined) {
      // dispatch(getPatientInfo({ patientId: id }))
      dispatch(getPatientCaretaker(id))
      dispatch(getPatientMonitoringData({ patientId: id }))
      dispatch(getPatientDiagnosticsData({ patientId: id }))
    }
  }, [dispatch, id])

  useEffect(() => {
    setPatientMonitoringTypesData(patientMonitoringData)
    setPatientCaretakerData(patientCaretaker)
    setPatientICDCodes(patientDiagnosticData)
  }, [patientMonitoringData, patientCaretaker, patientDiagnosticData])

  const getTreatementPlan = async () => {
    // function to get TreatementPlan
    await dispatch(getrpmCareplanDataActions(id, new Date().getFullYear())).then(res => {
      const data = res?.sort((a, b) => new Date(b.planDate) - new Date(a.planDate))[0]
      const serviceDate = res
        ?.sort((a, b) => new Date(a.planDate) - new Date(b.planDate))[0]
        .planDate.slice(0, 10)
      setTreatementPlan(data?.plan)
      setTreatementPlanDate(data?.planDate)
      setDateOfService(serviceDate?.split("-")?.reverse()?.join("-"))
    })
  }
  useEffect(() => {
    getTreatementPlan()
  }, [])
  console.log(location.state)
  return rpmLoading || treatementPlan === undefined || treatementPlan === null ? (
    <Loading />
  ) : (
    patientInfoDetail !== undefined && (
      <>
        <div className="mb-4">
          <Card>
            <h2 className="text-secondary text-xl">Patient Details</h2>
            <div className="d-flex justify-content-between">
              <div>
                <p>
                  <span className="font-medium">Date of Service :&nbsp;</span>
                  {dateOfService}
                </p>
                <p className="capitalize">
                  <span className="font-medium">Patient Name :&nbsp;</span>
                  {patientInfoDetail?.firstName}&nbsp;
                  {patientInfoDetail?.middleName}&nbsp;
                  {patientInfoDetail?.lastName}
                </p>
                <p>
                  <span className="font-medium">Patient DOB :&nbsp;</span>
                  {patientInfoDetail?.dob?.split("-")?.reverse()?.join("-")}
                </p>
                <div className="d-flex flex-wrap">
                  <span className="font-medium">Monitor Type :&nbsp;</span>
                  {patientMonitoringTypesData &&
                    Object.keys(patientMonitoringTypesData).map((item, index) => (
                      <p className="w-auto" key={index}>
                        {item.toUpperCase()},&nbsp;
                      </p>
                    ))}
                </div>
                <p>
                  <span className="font-medium"> Date&nbsp;of&nbsp;Plan&nbsp;Modified :&nbsp;</span>
                  {treatementPlanDate?.slice(0, 10).split("-")?.reverse()?.join("-")}
                </p>
              </div>
              <div className="mx-5">
                <p>
                  <div className="d-flex flex-wrap">
                    <span className="font-medium">CareTaker :&nbsp;</span>
                    {patientCaretakerData?.map((item, index) => (
                      <p key={index} className="capitalize ml-1">
                        {`${index + 1}. ${item.firstName} ${item.middleName ?? ""} ${
                          item.lastName
                        },`}
                      </p>
                    ))}
                  </div>
                </p>
                <div className="d-flex flex-wrap">
                  <span className="font-medium"> Provider :&nbsp; </span>
                  {patientMonitoringTypesData &&
                    patientMonitoringTypesData?.rpm?.map(item => (
                      <p className="capitalize">
                        {`${item.providerfirstName} ${item.providerMiddleName} ${item.providerLastName}`}
                        ,&nbsp;
                      </p>
                    ))}
                </div>
                {/* <p>
                  Chronic Conditions :{" "}
                  {Array.from(carePlanData?.careplanDetails ?? [].keys()).join(", ")}
                </p> */}
                <p>
                  <div className="d-flex flex-wrap">
                    <span className="font-medium">Diagnostic(ICD)&nbsp;Codes :&nbsp;</span>
                    {patientICDCodes &&
                      patientICDCodes?.map(item => <p>{item.icdCode} , &nbsp;</p>)}
                  </div>
                </p>
              </div>
            </div>
          </Card>
        </div>
        {treatementPlan ? (
          <RPMFollowUpQa
            treatementPlan={treatementPlan}
            patientInfoDetail={patientInfoDetail}
            refreshPlan={getTreatementPlan}
          />
        ) : null}
      </>
    )
  )
}

export default RPMFollowUpQuesView
