import { useState } from "react"
import { Button, Card } from "antd"
import { PlusCircleFilled } from "@ant-design/icons"
import InternalNotesList from "./InternalNotesList"
import ViewNotesCard from "./ViewNotesCard"
import AddAndEditNote from "./AddAndEditNote"
import moment from "moment"
import MyDatePicker from "../../utils/MyDatePicker"

const { RangePicker } = MyDatePicker

const InternalNotes = () => {
  const [showNotesList, setShowNotesList] = useState(true)
  const [showIndividualNote, setShowIndividualNote] = useState(false)
  const [selectedNoteToView, setSelectedNoteToView] = useState([])
  const [openIndivNote, setOpenIndivNote] = useState(false)
  const [isAddNote, setIsAddNote] = useState(false)
  const [editNote, setEditNote] = useState(false) // false or note object
  const [range, setRange] = useState([moment(new Date()).subtract(1, "month"), moment(new Date()).endOf("day")])

  const handleViewNoteFunc = (note, view) => {
    setSelectedNoteToView(note)
    setOpenIndivNote(view)
    setShowNotesList(false)
    setShowIndividualNote(true)
  }

  const handleCloseNoteFunc = view => {
    setSelectedNoteToView([])
    setOpenIndivNote(view)
    setShowIndividualNote(false)
    setShowNotesList(true)
  }

  const handleRangeChange = async (date, dateString) => {
    if (dateString?.[0]?.length && dateString?.[1]?.length)
      setRange(date)
  }

  return (
    <div>
      <Card className="min-h-screen">
        {isAddNote ? (
          <AddAndEditNote setIsAddNote={setIsAddNote} />
        ) : (
          <>
            <div className="mb-7 flex justify-between items-center">
              <h4 className="text-2xl">Internal Notes</h4>
              <div className="flex justify-end gap-3">
                <RangePicker
                  size="large"
                  value={range}
                  allowClear={false}
                  onChange={handleRangeChange}
                  format="MM-DD-YYYY"
                  disabledDate={current => current > moment()}
                />
                <Button
                  onClick={() => setIsAddNote(true)}
                  size="large"
                  type="primary"
                  className="flex justify-center items-center">
                  <PlusCircleFilled />
                  Add Internal Notes
                </Button>
              </div>
            </div>
            <div>
              {editNote ? (
                <AddAndEditNote editNote={editNote} setEditNote={setEditNote} />
              ) : showIndividualNote ? (
                <ViewNotesCard
                  note={selectedNoteToView}
                  openIndivNote={openIndivNote}
                  handleCloseNoteFunc={handleCloseNoteFunc}
                  setEditNote={setEditNote}
                />
              ) : showNotesList ? (
                <InternalNotesList
                  handleViewNoteFunc={handleViewNoteFunc}
                  setEditNote={setEditNote}
                  range={range}
                />
              ) : null}
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

export default InternalNotes
