import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import PlanView from "@fitpeo/fit-plan-viewer"
import { useDispatch, useSelector } from "react-redux"
import {
    getPatientDiagnosticsData,
    getPatientInfo,
    getPatientMonitoringData
} from "../../../redux/actions/patientAction"
import Loading from "../../layouts/Loading/Loading"
import { convertCareplanToV2 } from "../../../utils/careplanVersioning"
import { getDateOfService } from "../../../utils/getDateOfService"

const PcmHistoryViewPlan = () => {
    const [provider, setProvider] = useState([])
    const [practitioner, setPractitioner] = useState([])

    const location = useLocation()
    const patientId = location?.state?.patientId ?? undefined
    const plan = location?.state?.plan ?? {}
    const planDate = location?.state?.planDate

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {orgId, orgName} = useParams()

    const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
    const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
    const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

    useEffect(() => {
        if (patientId) {
            dispatch(getPatientInfo({ patientId }))
        } else {
            navigate("/not-found")
        }
    }, [dispatch, patientId])

    useEffect(() => {
        if (plan) {
            if (!plan?.planIcds) dispatch(getPatientDiagnosticsData({ patientId }))
            if (!plan?.providers) dispatch(getPatientMonitoringData({ patientId }))
        }
    }, [plan])

    useEffect(() => {
        let provider = []
        let practitioner = []

        if (plan?.providers) provider = plan?.providers
        else if (patientMonitoringData?.ccm?.[0]) {
            provider = patientMonitoringData?.ccm?.[0]?.providerId ? [` ${patientMonitoringData?.ccm?.[0]?.providerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.providerMiddleName && patientMonitoringData?.ccm?.[0]?.providerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.providerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.providerLastName}`] : []
        }
        if (plan?.practitioners) practitioner = plan?.practitioners
        else if (patientMonitoringData?.ccm?.[0]) {
            practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId ? [` ${patientMonitoringData?.ccm?.[0]?.practitionerfirstName}
          ${patientMonitoringData?.ccm?.[0]?.practitionerMiddleName && patientMonitoringData?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + patientMonitoringData?.ccm?.[0]?.practitionerMiddleName : ""} 
          ${patientMonitoringData?.ccm?.[0]?.practitionerLastName}`] : []
        }
        if (plan?.provider) {
            provider = [`${plan?.provider?.providerFirstName && plan?.provider?.providerFirstName !== "" ? " " + plan?.provider?.providerFirstName : ""}
          ${plan?.provider?.providerMiddleName && plan?.provider?.providerMiddleName !== "" ? " " + plan?.provider?.providerMiddleName : ""} 
          ${plan?.provider?.providerLastName && plan?.provider?.providerLastName !== "" ? " " + plan?.provider?.providerLastName : ""}`]
        }

        setProvider(provider)
        setPractitioner(practitioner)
    }, [plan, patientMonitoringData])

    return patientLoading ? (
        <Loading />
    ) : (
        <>
            <div className="d-flex justify-content-end">
                <button
                    // className="btn pdf-button btn-sm mx-2"
                    className="btn btn-outline-primary btn-sm mx-2"
                    onClick={() =>
                        navigate(`/${orgId}/${orgName}/pcm-view-care-print`, {
                            state: {
                                patientInfoDetail,
                                provider,
                                practitioner,
                                plan: convertCareplanToV2(plan),
                                planIcds: plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode),
                                addedBy: plan?.addedBy,
                                planDate: planDate
                            }
                        })
                    }>
                    Print
                </button>
            </div>
            <PlanView
                type="pcm"
                patientInfoDetail={patientInfoDetail}
                providers={provider}
                practitioners={practitioner}
                plan={convertCareplanToV2(plan)}
                planIcds={plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)}
                addedBy={plan?.addedBy}
                planDate={planDate}
                serviceDate={getDateOfService(planDate)}
            />
        </>
    )
}

export default PcmHistoryViewPlan
