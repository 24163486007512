import React from "react"

import { Checkbox, Radio, Input } from "antd"

const RPMOnlyFormInput = props => {
  const {
    ques,
    handleNormalCheck,
    handleText,
    handleNormalText,
    handleSingle,
    handleBpmNone,
    handleWeightMachineCheck,
    handleWeightMachineOtherText,
    handleWeightMachineNone,
    handleBool
  } = props

  switch (ques.quesType) {
    case "text":
      return (
        <div>
          {ques.datefield ? (
            <input
              required
              id={ques.id}
              type="date"
              style={{ width: "100%", height: "50px" }}
              max={new Date().toISOString().split("T")[0]}
              className="border-current border-2 px-2 rounded"
              disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}
              value={ques.text}
              // onClick={e=>console.log(ques.id)}//only id
              onChange={e => {
                handleNormalText(ques.id, e)
              }}
            />
          ) : (
            <Input.TextArea
              rows={2}
              required
              id={ques.id}
              disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}
              value={ques.notApplicable ? "" : ques.value ? ques.value : ""}
              onChange={e => {
                handleNormalText(ques.id, e)
              }}
            />
          )}
        </div>
      )
    case "single":
      return (
        <div>
          <div className="flex items-center">
            {ques.questionaryOptions.map((item, i) => (
              <Radio.Group
                key={i}
                id={item.id}
                name="single-select"
                checked={item.checked}
                onChange={e => handleSingle(ques.id, i, e)}
                disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}>
                <Radio value={item.name}>{item.name}</Radio>
              </Radio.Group>
            ))}
          </div>
        </div>
      )
    case "bool":
      return (
        <div>
          <Radio.Group
            checked={ques.questionaryOptions[0]}
            onChange={e => handleBool(ques.id, e)}
            disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}>
            <Radio value="true">Yes</Radio>
            <Radio value="false">No</Radio>
          </Radio.Group>
        </div>
      )
    case "multi":
      return (
        <div>
          {ques.device === undefined ? (
            <div className="flex flex-col">
              {ques.questionaryOptions.map((item, i) => (
                <Checkbox
                  key={i}
                  id={item.id}
                  className="mb-3"
                  checked={item.checked}
                  onChange={e => handleNormalCheck(ques.id, i, e)}
                  disabled={
                    !!(item.disabled || (ques.notApplicable !== undefined && ques.notApplicable))
                  }>
                  {item.name}
                </Checkbox>
              ))}
            </div>
          ) : ques.device === "blood pressure machine" || ques.device === "glucose machine" ? (
            ques.questionaryOptions.map((item, i) =>
              item.name === "Other" ? (
                <>
                  <div className="flex flex-col">
                    <Checkbox
                      id={item.id}
                      checked={item.checked}
                      onChange={e => handleNormalCheck(ques.id, i, e)}
                      disabled={
                        !!(
                          item.disabled ||
                          (ques.notApplicable !== undefined && ques.notApplicable)
                        )
                      }>
                      {item.name}
                    </Checkbox>
                  </div>
                  {item.checked === true && (
                    <div>
                      <Input.TextArea
                        rows={2}
                        className="mt-4"
                        onChange={e => handleText(ques.id, i, e)}
                        disabled={
                          !!(
                            item.disabled ||
                            (ques.notApplicable !== undefined && ques.notApplicable)
                          )
                        }
                        value={item.text}
                      />
                    </div>
                  )}
                </>
              ) : item.name === "None of the above" ? (
                <div className="flex my-3">
                  <Checkbox
                    id={item.id}
                    checked={item.checked}
                    onChange={e => handleBpmNone(ques.id, i, e)}
                    disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}>
                    {item.name}
                  </Checkbox>
                </div>
              ) : (
                <div className="flex mb-3">
                  <Checkbox
                    id={item.id}
                    checked={item.checked}
                    onChange={e => handleNormalCheck(ques.id, i, e)}
                    disabled={
                      !!(item.disabled || (ques.notApplicable !== undefined && ques.notApplicable))
                    }>
                    {item.name}
                  </Checkbox>
                </div>
              )
            )
          ) : ques.device === "weight machine" || ques.device === "oximeter" ? (
            ques.questionaryOptions.map((item, i) => (
              <>
                <div className="flex mb-3">
                  <Checkbox
                    id={item.id}
                    checked={item.checked}
                    onChange={e => handleWeightMachineCheck(ques.id, i, e)}
                    disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}>
                    {item.name}
                  </Checkbox>
                </div>
                {item.checked === true
                  ? item.subQuestion.map((subQues, ii) => (
                      <div key={ii} className="border-2 border-current p-4 my-4 rounded">
                        <h6 className="mb-3 text-xl">{subQues.name}</h6>
                        {subQues.questionaryOptions.map((innerQues, iii) =>
                          innerQues.name === "Other" ? (
                            <>
                              <div className="flex mb-3">
                                <Checkbox
                                  id={innerQues.id}
                                  checked={innerQues.checked}
                                  onChange={e => handleWeightMachineCheck(ques.id, i, e, ii, iii)}
                                  disabled={
                                    !!(
                                      innerQues.disabled ||
                                      (ques.notApplicable !== undefined && ques.notApplicable)
                                    )
                                  }>
                                  {innerQues.name}
                                </Checkbox>
                              </div>
                              {innerQues.checked === true && (
                                <div>
                                  <Input.TextArea
                                    rows={2}
                                    className="mt-4"
                                    onChange={e =>
                                      handleWeightMachineOtherText(ques.id, i, e, ii, iii)
                                    }
                                    //    disabled={inner_ques.disabled && inner_ques.disabled}
                                    disabled={
                                      !!(
                                        innerQues.disabled ||
                                        (ques.notApplicable !== undefined && ques.notApplicable)
                                      )
                                    }
                                    value={innerQues.text}
                                  />
                                </div>
                              )}
                            </>
                          ) : innerQues.name === "None of the above" ? (
                            <div className="flex mb-3">
                              <Checkbox
                                id={innerQues.id}
                                checked={innerQues.checked}
                                onChange={e => handleWeightMachineNone(ques.id, i, e, ii, iii)}
                                disabled={
                                  !!(ques.notApplicable !== undefined && ques.notApplicable)
                                }>
                                {innerQues.name}
                              </Checkbox>
                            </div>
                          ) : (
                            <div className="flex mb-3">
                              <Checkbox
                                id={innerQues.id}
                                checked={innerQues.checked}
                                onChange={e => handleWeightMachineCheck(ques.id, i, e, ii, iii)}
                                // disabled={inner_ques.disabled && inner_ques.disabled}
                                disabled={
                                  !!(
                                    innerQues.disabled ||
                                    (ques.notApplicable !== undefined && ques.notApplicable)
                                  )
                                }>
                                {innerQues.name}
                              </Checkbox>
                            </div>
                          )
                        )}
                      </div>
                    ))
                  : null}
              </>
            ))
          ) : null}
        </div>
      )
    case "multi-field":
      return (
        <div>
          {ques.questionaryOptions.map((item, i) =>
            item.field ? (
              <div key={i} className="my-3">
                <label className="capitalize mb-2">{item.name}</label>
                <br />
                <Input.TextArea
                  rows={2}
                  value={item.text}
                  onChange={e => handleText(ques.id, i, e)}
                  disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}
                />
              </div>
            ) : (
              <div key={i} className="flex mb-3">
                <Checkbox
                  id={item.id}
                  checked={item.checked}
                  onChange={e => handleNormalCheck(ques.id, i, e)}
                  disabled={!!(ques.notApplicable !== undefined && ques.notApplicable)}>
                  {item.name}
                </Checkbox>
              </div>
            )
          )}
        </div>
      )
    default:
      return <div>Invalid question</div>
  }
}

export default RPMOnlyFormInput
