import { Button, Checkbox, Divider, Modal } from "antd"
import React, { useEffect, useState } from "react"
import { CloseOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { postBulkMailMessagePatientAction } from "../../redux/actions/selfOnboardingAction"

const REMINDER_OPTIONS = Object.freeze({
  "Send text message to take reading": "reminder_reading_message",
  "Send E-mail to take reading": "reminder_reading_email"
})

const SendReadingReminderModal = ({ open, onClose, selectedPatientIds }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { loading: postLoading } = useSelector(state => state.postBulkMessagePatient)
  const [selectedReminderOpt, setSelectedReminderOpt] = useState([])

  useEffect(() => {
    if (open) setSelectedReminderOpt([])
  }, [open])

  const handleConfirm = async () => {
    if (!selectedReminderOpt?.length || !selectedPatientIds?.length) return

    for (const msgType of selectedReminderOpt) {
      const formData = {
        patientIds: selectedPatientIds,
        messageType: REMINDER_OPTIONS?.[msgType]
      }

      const apiResPromise = dispatch(postBulkMailMessagePatientAction(formData))
      apiResPromise
        .then(apiRes => {
          if (apiRes?.status === 200) {
            onClose()
            addToast(
              `${msgType?.replace(/send\s+/i, "")} sent to ${
                selectedPatientIds?.length
              } patient(s).`,
              { appearance: "success", autoDismiss: true }
            )
          } else {
            console.error("API Error:", apiRes?.error)
            ;[]
          }
        })
        .catch(error => {
          console.error("API Error:", error)
          addToast(error?.message, { appearance: "error", autoDismiss: true })
        })
    }
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      styles={{
        content: {
          borderRadius: 12,
          padding: 6,
          overflow: "hidden"
        }
      }}
      footer={null}
      centered
      closable={false}>
      <div className="p-3 text-[#fff] flex items-center gap-1 bg-current rounded-t-[12px]">
        <h1 className=" text-2xl flex-1 font-semibold">Send reminder to take reading</h1>
        <CloseOutlined className="cursor-pointer" onClick={onClose} style={{ fontSize: 22 }} />
      </div>
      <div className="p-3">
        <p className="text-lg font-semibold">
          How do you want to send reminder to the selected patient(s)?
        </p>
        <Checkbox.Group
          options={Object.keys(REMINDER_OPTIONS)}
          className="mt-3 inline-flex flex-col gap-3 font-base"
          value={selectedReminderOpt}
          onChange={setSelectedReminderOpt}
        />
      </div>
      <Divider className="mt-3" />
      <div className="px-2.5 pb-2.5 flex justify-end gap-3">
        <Button size="large" type="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          loading={postLoading}
          disabled={!selectedReminderOpt?.length}
          onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default SendReadingReminderModal
