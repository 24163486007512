// groups
export const ADD_GROUP_REQUEST = "ADD_GROUP_REQUEST"
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS"
export const ADD_GROUP_FAIL = "ADD_GROUP_FAIL"

export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL"

export const GET_GROUP_DETAIL_REQUEST = "GET_GROUP_DETAIL_REQUEST"
export const GET_GROUP_DETAIL_SUCCESS = "GET_GROUP_DETAIL_SUCCESS"
export const GET_GROUP_DETAIL_FAIL = "GET_GROUP_DETAIL_FAIL"

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL"

export const GET_ADMIN_CARETAKER_FOR_GROUP_REQUEST = "GET_ADMIN_CARETAKER_FOR_GROUP_REQUEST"
export const GET_ADMIN_CARETAKER_FOR_GROUP_SUCCESS = "GET_ADMIN_CARETAKER_FOR_GROUP_SUCCESS"
export const GET_ADMIN_CARETAKER_FOR_GROUP_FAIL = "GET_ADMIN_CARETAKER_FOR_GROUP_FAIL"

export const MAP_GROUP_TO_PATIENT_REQUEST = "MAP_GROUP_TO_PATIENT_REQUEST"
export const MAP_GROUP_TO_PATIENT_SUCCESS = "MAP_GROUP_TO_PATIENT_SUCCESS"
export const MAP_GROUP_TO_PATIENT_FAIL = "MAP_GROUP_TO_PATIENT_FAIL"

export const VALIDATE_GROUP_NAME_REQUEST = "VALIDATE_GROUP_NAME_REQUEST"
export const VALIDATE_GROUP_NAME_SUCCESS = "VALIDATE_GROUP_NAME_SUCCESS"
export const VALIDATE_GROUP_NAME_FAIL = "VALIDATE_GROUP_NAME_FAIL"

export const GET_PATIENTS_FOR_GROUP_REQUEST = "GET_PATIENTS_FOR_GROUP_REQUEST"
export const GET_PATIENTS_FOR_GROUP_SUCCESS = "GET_PATIENTS_FOR_GROUP_SUCCESS"
export const GET_PATIENTS_FOR_GROUP_FAIL = "GET_PATIENTS_FOR_GROUP_FAIL"

export const SET_GROUP_PATIENT_ADVANCE_FILTER = "SET_GROUP_PATIENT_ADVANCE_FILTER"
export const RESET_GROUP_PATIENT_ADVANCE_FILTER = "RESET_GROUP_PATIENT_ADVANCE_FILTER"

export const SET_GROUP_PATIENT_LIST_ADVANCE_FILTER = "SET_GROUP_PATIENT_LIST_ADVANCE_FILTER"
export const RESET_GROUP_PATIENT_LIST_ADVANCE_FILTER = "RESET_GROUP_PATIENT_LIST_ADVANCE_FILTER"

export const GET_PATIENT_GROUP_NAME_REQUEST = "GET_PATIENT_GROUP_NAME_REQUEST"
export const GET_PATIENT_GROUP_NAME_SUCCESS = "GET_PATIENT_GROUP_NAME_SUCCESS"
export const GET_PATIENT_GROUP_NAME_FAIL = "GET_PATIENT_GROUP_NAME_FAIL"