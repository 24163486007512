import { Button, Modal, Table } from "antd"
import { useEffect, useState } from "react"
import { getManualTimeEditHistoryAction } from "../../../redux/actions/patientAction"
import { useDispatch, useSelector } from "react-redux"
import Loading from "../../layouts/Loading/Loading"
import moment from "moment"
import { LuMoveLeft } from "react-icons/lu"

const ManualTimeEditHistory = props => {
  const { showManualTimeEditHistory, setShowManualTimeEditHistory, selectedLog } = props
  const { manualTimeEditHistory, loading } = useSelector(state => state.manualTimeEditHistory)

  const [sortedHistory, setSortedHistory] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!selectedLog?.id) return
    dispatch(getManualTimeEditHistoryAction(selectedLog?.id))
  }, [selectedLog])

  useEffect(() => {
    // Sort the history data based on userDateNew, newest first
    const sortedHistoryData = manualTimeEditHistory?.sort((a, b) =>
      moment(b.userDateNew).diff(moment(a.userDateNew))
    )
    setSortedHistory(sortedHistoryData)
  }, [loading])

  const handleClose = () => {
    setShowManualTimeEditHistory(false)
  }

  return (
    <Modal
      footer={null}
      open={showManualTimeEditHistory}
      onCancel={handleClose}
      width={880}
      className="px-2"
      style={{
        borderRadius: 12,
        overflow: "hidden"
      }}
      title={<h4 className="text-xl">Nursing Notes (Manual Time) Edit History</h4>}>
      {!loading ? (
        <div className="overflow-x-auto">
          <div className="min-w-full rounded-lg">
            {/* Header */}
            <div className="grid grid-cols-4 bg-gray-100 border-b border-[#dadada]">
              <div className="py-4 px-1 font-semibold text-left">Modified On</div>
              <div className="py-4 px-1 font-semibold text-left">Modified By</div>
              <div className="py-4 px-1 font-semibold text-left">
                Nursing Notes Previously Added
              </div>
              <div className="py-4 px-1 font-semibold text-left">Nursing Notes Changed To</div>
            </div>
            {/* Data Rows */}
            {sortedHistory && sortedHistory.length > 0 ? (
              sortedHistory.map((historyData, index) => (
                <div
                  key={historyData?.id}
                  className={`grid grid-cols-4 ${
                    sortedHistory.length - 1 !== index ? "border-b border-[#dadada]" : ""
                  }`}>
                  <div className="py-4 px-1">
                    {moment(historyData?.userDateNew)?.format("MM/DD/YYYY [at] h:mm A")}
                  </div>
                  <div className="py-4 px-1">
                    <span className="capitalize">
                      {`${historyData?.editedByFirstName} ${
                        historyData?.editedByMiddleName ? `${historyData?.editedByMiddleName} ` : ""
                      }${historyData?.editedByLastName}`}
                    </span>
                  </div>
                  <div className="py-4 px-1">
                    <span>
                      <span className="font-semibold">Care Type:</span>{" "}
                      {historyData?.careOld?.map(care => care).join(", ")}
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold">Time:</span> {historyData?.careTimeOld}{" "}
                      minutes
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold">Care Date:</span>{" "}
                      {moment(historyData?.careDateOld)?.format("MM/DD/YYYY h:mm A")}
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold">Note:</span> {historyData?.noteOld}
                    </span>
                  </div>
                  <div className="py-4 px-1">
                    <span>
                      <span className="font-semibold">Care Type:</span>{" "}
                      {historyData?.careNew?.map(care => care).join(", ")}
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold">Time:</span> {historyData?.careTimeNew}{" "}
                      minutes
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold">Care Date:</span>{" "}
                      {moment(historyData?.careDateNew)?.format("MM/DD/YYYY h:mm A")}
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold">Note:</span> {historyData?.noteNew}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="py-8 text-center text-gray-600">No edit history available.</div>
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <Button
        type="primary"
        size="large"
        className="px-4 flex items-center"
        icon={<LuMoveLeft />}
        onClick={() => setShowManualTimeEditHistory(false)}>
        Back
      </Button>
    </Modal>
  )
}

export default ManualTimeEditHistory
