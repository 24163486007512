import { axiosGet } from "../axios"
import {
  GET_ALL_LASTREADINGTAKEN_REQUEST,
  GET_ALL_LASTREADINGTAKEN_SUCCESS,
  GET_ALL_LASTREADINGTAKEN_FAIL
} from "../types/LastReadingTakenAnalysisConstant"
import { userLogoutAction } from "./userActions"

export const getLastReadingTakenAnalysisReport = id => async dispatch => {
  try {
    dispatch({
      type: GET_ALL_LASTREADINGTAKEN_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/lastReading/orgId/${id}/analysis/fetch`)

    dispatch({
      type: GET_ALL_LASTREADINGTAKEN_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    console.debug(error)
    dispatch({
      type: GET_ALL_LASTREADINGTAKEN_FAIL,
      payload: error.response?.data?.details
    })
  }
}
