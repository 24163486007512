import { Avatar, Card, Skeleton } from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"
import { MdEdit } from "react-icons/md"
import ProfilePictureUpload from "./ProfilePictureUpload"

const PersonalDetails = () => {
  const { userInfo, loading } = useSelector(state => state.userInfo)
  const { imageUploader, loading: postImageLoading } = useSelector(state => state.imageUploader)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [profilePic, setProfilePic] = useState(userInfo?.image ? userInfo?.image : "")
  const [loadingImg, setLoadingImg] = useState(true)
  const onImageLoadHandler = () => {
    setLoadingImg(false)
  }
  return (
    <>
      <Card title="">
        <div className="flex flex-col gap-2 mb-5">
          <div className="relative flex flex-wrap items-center justify-center mb-4">
            {profilePic?.length ? (
              <Avatar
                size={100}
                className=" mb-2 rounded-full"
                src={
                  <>
                    {(loadingImg || postImageLoading) && (
                      <div className="rounded-full">
                        <Skeleton.Image width={200} active={true} />
                      </div>
                    )}
                    <img alt="user profile" src={profilePic} onLoad={onImageLoadHandler} />
                  </>
                }
              />
            ) : (
              <Avatar size={100} className="mr-2 flex items-center bg-[#ebf4ff] text-primary">
                <span className="font-bold text-3xl">
                  {!userInfo?.middleName || userInfo?.middleName === null
                    ? `${userInfo?.firstName?.[0]}${userInfo?.lastName?.[0]}`?.toUpperCase()
                    : `${userInfo?.firstName?.[0]}${userInfo?.middleName?.[0]}${userInfo?.lastName?.[0]}`?.toUpperCase()}
                </span>
              </Avatar>
            )}
            <div className="bg-white mt-auto p-1 drop-shadow-[0_0_10px_rgba(0,0,0,0.3)] text-white rounded-full cursor-pointer ml-[-30px] z-1">
              <MdEdit
                className="text-primary" // Adjust positioning and color
                size={24}
                onClick={() => setShowUploadModal(true)}
              />
            </div>
          </div>
          <p>
            First Name : <span className="capitalize font-bold">{userInfo?.firstName}</span>
          </p>
          {userInfo?.middleName ? (
            <p>
              Middle Name : <span className="capitalize font-bold">{userInfo?.middleName}</span>
            </p>
          ) : (
            <></>
          )}
          <p>
            Last Name : <span className="capitalize font-bold">{userInfo?.lastName}</span>
          </p>
          <p>
            Username : <span className="font-bold">{userInfo?.userName}</span>
          </p>
          <p>
            Gender at time of birth :{" "}
            <span className="capitalize font-bold">{userInfo?.gender}</span>
          </p>
        </div>
      </Card>
      {showUploadModal ? (
        <ProfilePictureUpload
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
          setProfilePic={setProfilePic}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default PersonalDetails