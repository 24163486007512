export const getFtInches = heightInInches => {
  const feet = Math.floor(heightInInches / 12)
  const inches = Math.floor(heightInInches % 12)
  return { feet, inches }
}

export const prettifyHeight = heightInInches => {
  const { feet, inches } = getFtInches(heightInInches)
  return `${feet} ft ${inches} inch`
}
