import { axiosPost } from "../axios"
import {
  POST_UPLOAD_FILES_FAIL,
  POST_UPLOAD_FILES_REQUEST,
  POST_UPLOAD_FILES_SUCCESS
} from "../types/uploadFileConstant"
import { userLogoutAction } from "./userActions"

export const UploadFileAction =
  ({ fileList, userId, fileType }) =>
  async dispatch => {
    try {
      dispatch({
        type: POST_UPLOAD_FILES_REQUEST
      })

      const formData = new FormData()
      if (fileList?.length > 1) {
        fileList.forEach(file => formData.append("files", file))
      } else formData.append("file", fileList[0])

      formData.append("userId", userId)
      formData.append("fileType", fileType)

      const { data } = await axiosPost(
        fileList?.length > 1 ? `/api/cs/upload/files` : `/api/cs/upload/file`,
        formData
      )

      const fileUrls = data?.details?.url

      dispatch({
        type: POST_UPLOAD_FILES_SUCCESS,
        payload: Object.values(data?.details)
      })
      return { isUploaded: true, fileUrls: Object.values(data?.details) }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())

      const errMsg = error?.response?.data?.details
        ? Object.values(error.response.data.details)[0]
        : "Something went wrong!"
      dispatch({
        type: POST_UPLOAD_FILES_FAIL,
        payload: errMsg
      })
      return { isUploaded: false, error: errMsg }
    }
  }
