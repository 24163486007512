import {
  EMAIL_VERIFICATION_FAIL,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  FORGET_PASSWORD_CHANGE_FAIL,
  FORGET_PASSWORD_CHANGE_REQUEST,
  FORGET_PASSWORD_CHANGE_SUCCESS,
  UPDATE_EMAIL_FAIL,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_NUMBER_FAIL,
  UPDATE_NUMBER_REQUEST,
  UPDATE_NUMBER_SUCCESS,
  SHOW_PUSH_NOTIFICATION,
  HIDE_PUSH_NOTIFICATION,
  SET_TRIAGE_SESSION,
  SET_CLEAR_SHORT_SESSION,
  SET_FILTER,
  CLEAR_FILTER
} from "../types/utilConstant"

export const emailVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_VERIFICATION_REQUEST:
      return { loading: true }
    case EMAIL_VERIFICATION_SUCCESS:
      return { loading: false, emailVerification: action.payload }
    case EMAIL_VERIFICATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const passwordChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_CHANGE_REQUEST:
      return { loading: true }
    case FORGET_PASSWORD_CHANGE_SUCCESS:
      return { loading: false, forgetPasswordChange: action.payload }
    case FORGET_PASSWORD_CHANGE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const emailChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EMAIL_REQUEST:
      return { loading: true }
    case UPDATE_EMAIL_SUCCESS:
      return { loading: false, emailUpdate: action.payload }
    case UPDATE_EMAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const numberChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NUMBER_REQUEST:
      return { loading: true }
    case UPDATE_NUMBER_SUCCESS:
      return { loading: false, numberUpdate: action.payload }
    case UPDATE_NUMBER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pushNotificationReducer = (state = { showPush: false }, action) => {
  switch (action.type) {
    case SHOW_PUSH_NOTIFICATION:
      return { showPush: true }
    case HIDE_PUSH_NOTIFICATION:
      return { showPush: false }
    default:
      return state
  }
}

const prevSession = sessionStorage.getItem("triageTimer")
// console.log('pv',prevSession)
const initialState =
  prevSession !== undefined && prevSession !== null && prevSession !== "undefined"
    ? JSON.parse(prevSession)
    : {}
// const initialState = sessionStorage.getItem('triageTimer') !== undefined && sessionStorage.getItem('triageTimer') !== null  ? JSON.parse(sessionStorage.getItem('triageTimer')) : {}
export const setTriageSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRIAGE_SESSION:
      return { sessionTriageData: action.payload }
    default:
      return state
  }
}

const prevShortSession = sessionStorage.getItem("shortClearTimer")
const shortInitialState =
  prevShortSession !== undefined && prevShortSession !== null && prevShortSession !== "undefined"
    ? JSON.parse(prevShortSession)
    : {}
export const setShortClearReducer = (state = shortInitialState, action) => {
  switch (action.type) {
    case SET_CLEAR_SHORT_SESSION:
      return { shortClearData: action.payload }
    default:
      return state
  }
}

export const filterModalReducer = (state = null, action) => {
  switch (action.type) {
    case SET_FILTER:
      return { value: action.payload }
    case CLEAR_FILTER:
      return { value: null }
    default:
      return state
  }
}
