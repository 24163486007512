import React from "react"

const TriagePrintInfoCard = () => {
  return (
    <div className="rounded shadow m-3 p-3">
      <h6 className="">
        On <span className="fw-bold">(Date of Service)</span>{" "}
        <span className="fw-bold">(RN Admin Name)</span> spoke with{" "}
        <span className="fw-bold">(Patient First and Last Name)</span>.<br /> A triage assessment
        was performed based on the following critical alert:
      </h6>
      <div>
        <div className="d-flex flex-wrap">
          <p className="fw-bold">BP : 179/100</p>
          <p className="fw-bold mx-5">Date : 10/10/2022 </p>
          <p className="fw-bold">Time : 8:04am</p>
        </div>
      </div>
    </div>
  )
}

export default TriagePrintInfoCard
