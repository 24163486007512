import React from "react"

import { useSelector } from "react-redux"

import { Tabs } from "antd"
import { UserOutlined, SafetyOutlined } from "@ant-design/icons"
import { MdOutlineFeedback } from "react-icons/md"
import packageJson from "../../../package.json"

import Profile from "./Profile"
import Security from "./Security"
import Feedback from "./Feedback"

const items = [
  {
    key: "1",
    label: (
      <div className="flex gap-2.5 items-center">
        <UserOutlined />
        Profile
      </div>
    ),
    children: <Profile />
  },
  {
    key: "2",
    label: (
      <div className="flex gap-2.5 items-center">
        <SafetyOutlined />
        Security
      </div>
    ),
    children: <Security />
  },
  {
    key: "3",
    label: (
      <div className="flex gap-2.5 items-center fs-6">
        <MdOutlineFeedback />
        Feedback
      </div>
    ),
    children: <Feedback />
  }
]

const AccountComponent = () => {
  const { userInfo } = useSelector(state => state.userInfo)

  return userInfo ? (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={`Version: ${packageJson?.version}`}
      items={items}
    />
  ) : null
}

export default AccountComponent
