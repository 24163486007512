import {
    GET_PCM_FAIL,
    GET_PCM_SUCCESS,
    GET_PCM_REQUEST,
    GET_LATEST_PCM_CAREPLAN_REQUEST,
    GET_LATEST_PCM_CAREPLAN_SUCCESS,
    GET_LATEST_PCM_CAREPLAN_FAIL,
    POST_PCM_USER_REQUEST,
    POST_PCM_USER_SUCCESS,
    POST_PCM_USER_FAIL,
    GET_PCM_ARCHIVE_PLANS_REQUEST,
    GET_PCM_ARCHIVE_PLANS_SUCCESS,
    GET_PCM_ARCHIVE_PLANS_FAIL,
    POST_PCM_FOLLOW_UP_REQUEST,
    POST_PCM_FOLLOW_UP_SUCCESS,
    POST_PCM_FOLLOW_UP_FAIL,
    UPDATE_PCM_PLAN_REQUEST,
    UPDATE_PCM_PLAN_SUCCESS,
    UPDATE_PCM_PLAN_FAIL,
    GET_PCM_RESET,
    GET_PCM_CAREPLAN_REQUEST,
    GET_PCM_CAREPLAN_SUCCESS,
    GET_PCM_CAREPLAN_FAIL
} from "../types/pcmConstant";


export const getPcmReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PCM_REQUEST:
            return { loading: true }
        case GET_PCM_SUCCESS:
            return { loading: false, getPcm: action.payload }
        case GET_PCM_FAIL:
            return { loading: false, error: action.payload }
        case GET_PCM_RESET:
            return { loading: false, error: null }
        default:
            return state
    }
}

export const getPcmArchiveReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PCM_ARCHIVE_PLANS_REQUEST:
            return { loading: true }
        case GET_PCM_ARCHIVE_PLANS_SUCCESS:
            return { loading: false, pcmArchivePlans: action.payload }
        case GET_PCM_ARCHIVE_PLANS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getLatestPcmPlanReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_LATEST_PCM_CAREPLAN_REQUEST:
            return { loading: true }
        case GET_LATEST_PCM_CAREPLAN_SUCCESS:
            return { loading: false, latestPcmCareplan: action.payload }
        case GET_LATEST_PCM_CAREPLAN_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPcmCarePlanReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PCM_CAREPLAN_REQUEST:
            return { loading: true }
        case GET_PCM_CAREPLAN_SUCCESS:
            return { loading: false, getPcmCarePlan: action.payload }
        case GET_PCM_CAREPLAN_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postPCMReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_PCM_USER_REQUEST:
            return { loading: true }
        case POST_PCM_USER_SUCCESS:
            return { loading: false, postPCMUserData: action.payload }
        case POST_PCM_USER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const postPcmFollowUpReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_PCM_FOLLOW_UP_REQUEST:
            return { loading: true }
        case POST_PCM_FOLLOW_UP_SUCCESS:
            return { loading: false, postPcmFollowUp: action.payload }
        case POST_PCM_FOLLOW_UP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updatePcmPlanReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PCM_PLAN_REQUEST:
            return { loading: true }
        case UPDATE_PCM_PLAN_SUCCESS:
            return { loading: false, updatePcmPlan: action.payload }
        case UPDATE_PCM_PLAN_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}