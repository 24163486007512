export const GET_INTERNAL_NOTE_REQUEST = "GET_INTERNAL_NOTE_REQUEST"
export const GET_INTERNAL_NOTE_SUCCESS = "GET_INTERNAL_NOTE_SUCCESS"
export const GET_INTERNAL_NOTE_FAIL = "GET_INTERNAL_NOTE_FAIL"

export const ADD_INTERNAL_NOTE_REQUEST = "ADD_INTERNAL_NOTE_REQUEST"
export const ADD_INTERNAL_NOTE_SUCCESS = "ADD_INTERNAL_NOTE_SUCCESS"
export const ADD_INTERNAL_NOTE_FAIL = "ADD_INTERNAL_NOTE_FAIL"

export const EDIT_INTERNAL_NOTE_REQUEST = "EDIT_INTERNAL_NOTE_REQUEST"
export const EDIT_INTERNAL_NOTE_SUCCESS = "EDIT_INTERNAL_NOTE_SUCCESS"
export const EDIT_INTERNAL_NOTE_FAIL = "EDIT_INTERNAL_NOTE_FAIL"
