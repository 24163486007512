import { useNavigate } from "react-router-dom"
import { Button, Card } from "antd"
const PageNotFoundMultiOrg = () => {
  const navigate = useNavigate()

  return (
    <div className="flex items-center justify-center h-screen">
      <Card
        style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
        className="flex p-8 items-center justify-center">
        <h1 className="mb-4 text-2xl text-current">
          We've made some updates to improve your experience.
        </h1>
        <p className="text-lg "> Enhancements and new features are now available.</p>
        <p className="text-lg">
          Please click on the button below to navigate to your desired page:
        </p>
        <Button type="primary" className="px-3 shadow mt-12" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      </Card>
    </div>
  )
}

export default PageNotFoundMultiOrg
