import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Modal, Row, Select } from "antd";
import * as yup from "yup"
import { object } from "yup";
// import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetPatientGroupName, MapGroupToPatient } from "../../redux/actions/groupsActions";
import { useToasts } from "react-toast-notifications";

const defaultValues = {
    group: ''
}

const schema = object().shape({
    group: yup.string().min(1, "Group is required").required("Group is required")
});

const MAP_GROUP_SUCCESS_MSG = "Group assigned to patient successfully"
const MAP_GROUP_ERR_MSG = "Failed to assign group to patient"

const AssignGroupToPatient = ({ patientId, assignableGroups, showAssignGroupModal, setShowAssignGroupModal }) => {

    const dispatch = useDispatch()
    const { addToast } = useToasts()

    // const { userInfo: adminInfo } = useSelector(state => state.userInfo)
    // const { groups, loading } = useSelector((state) => state.getGroups)
    const { loading: mapLoading } = useSelector((state) => state.mapGroupToPatient)

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        try {
            const result = await dispatch(MapGroupToPatient(data?.group, patientId))
            if (result?.status === 200) {
                addToast(MAP_GROUP_SUCCESS_MSG, { appearance: "success", autoDismiss: true })
                dispatch(GetPatientGroupName(patientId))
                handleCancel();
            }
        } catch (error) {
            if (error?.status === 400) {
                addToast(error?.details?.message, { appearance: "error", autoDismiss: true })
            } else {
                addToast(MAP_GROUP_ERR_MSG, { appearance: "error", autoDismiss: true })
            }
        }
    }

    const handleCancel = () => {
        reset(defaultValues);
        setShowAssignGroupModal(false);
    };

    const filterOption = (input, option) =>
        (option?.name ?? '')?.toLowerCase()?.includes(input?.trim()?.toLowerCase());

    return (
        <Modal
            open={showAssignGroupModal}
            footer={null}
            onOk={handleCancel}
            onCancel={handleCancel}
            title={<h4 className="text-2xl">Groups</h4>}
        >
            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <Row gutter={16} className="flex flex-col justify-center items-start">
                    <Col className="mb-3 w-full" style={{ minHeight: "90px" }}>
                        <div className="">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="group">
                                Group <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="group"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        placeholder="Select Group"
                                        style={{ width: '100%' }}
                                        size="large"
                                        id="group"
                                        filterOption={filterOption}
                                        status={errors.group ? "error" : undefined}
                                        options={assignableGroups?.map((group, index) => ({
                                            label: <span key={index} className="capitalize">{group?.name}</span>,
                                            name: `${group?.name}`,
                                            value: group?.id
                                        })
                                        )}
                                        onChange={(value) => field.onChange(value)}
                                    />
                                )}
                            />
                            {errors.group ? (
                                <small className="text-danger capitalize">{errors.group.message}</small>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} className="flex justify-end items-center">
                    <Col className="mt-3">
                        <Button
                            type="default"
                            size="large"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col className="mt-3">
                        <Button
                            type="primary"
                            size="large"
                            loading={mapLoading}
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    )
}

export default AssignGroupToPatient