import { LeftOutlined } from "@ant-design/icons"
import { Typography } from "antd"
import { useNavigate } from "react-router-dom"

// component for back to org list
const OrgBackNavigation = () => {
  const navigate = useNavigate()

  return (
    <Typography.Text
      strong
      className="cursor-pointer"
      onClick={() => {
        navigate("/")
      }}>
      <LeftOutlined />
      &nbsp; Organization
    </Typography.Text>
  )
}
export default OrgBackNavigation
