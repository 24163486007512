import React, { useEffect, useState } from "react"

import Loading from "../layouts/Loading/Loading"

import { useDispatch, useSelector } from "react-redux"
import { getUsersForCcmFollowUpAction } from "../../redux/actions/ccmActions"

import { LinkOutlined } from "@ant-design/icons"
import { Button, Table, Input, Tooltip, Card } from "antd"

const { Search } = Input

const CCMPatientFollowUpList = () => {
  const dispatch = useDispatch()
  const { loading, ccmFollowUpUsers } = useSelector(state => state.ccmFollowUpUsers)
  const userInfo = useSelector(state => state.userInfo)
  const [data, setData] = useState([])
  const [searchedData, setSearchedData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    const currentMonth = new Date().getMonth()
    if (userInfo?.userInfo?.orgId !== undefined) {
      currentMonth === 0
        ? dispatch(
            getUsersForCcmFollowUpAction(
              12,
              new Date().getFullYear() - 1,
              userInfo?.userInfo?.orgId
            )
          )
        : dispatch(
            getUsersForCcmFollowUpAction(
              new Date().getMonth(),
              new Date().getFullYear(),
              userInfo?.userInfo?.orgId
            )
          )
    }
  }, [dispatch, userInfo?.userInfo?.orgId])
  // console.log(ccmFollowUpUsers)

  // column for table
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      render: name => <p className="text-capitalize wrap-text mb-0">{name}</p>
    },
    {
      title: "Action",
      render: ({ id }) => (
        <Tooltip title="Follow Up">
          <Button
            shape="circle"
            type="primary"
            icon={<LinkOutlined />}
            onClick={() => (window.location.href = `/ccm-follow-up/${id}`)}
          />
        </Tooltip>
      )
    }
  ]

  useEffect(() => {
    const newData = []
    let sno = 1
    ccmFollowUpUsers?.forEach(({ firstName, middleName, lastName, userId }) => {
      middleName !== null
        ? newData.push({
            key: sno,
            sNo: sno,
            id: userId,
            name: `${firstName} ${middleName} ${lastName}`
          })
        : newData.push({ key: sno, sNo: sno, name: `${firstName} ${lastName}`, id: userId })
      sno += 1
    })
    setData(newData)
  }, [ccmFollowUpUsers])

  // console.log('data',data)

  const handleSearch = term => {
    const prevData = data
    setSearchTerm(term?.toLowerCase())
    const newData = prevData.filter(item => item?.name?.toLowerCase().includes(term?.toLowerCase()))
    if (newData?.length) {
      setSearchedData(newData)
    } else {
      setSearchedData([])
    }
  }

  return loading ? (
    <div className="loaderLoading">
      <Loading />
    </div>
  ) : (
    <>
      <Card className="rounded-b-none">
        <div className="flex justify-between flex-wrap gap-4">
          <h3 className="text-xl">Patients Careplan Follow Up</h3>
          <Search
            allowClear
            size="large"
            placeholder="Search"
            className="max-w-[350px]"
            onSearch={value => handleSearch(value)}
            onChange={e => handleSearch(e.target.value)}
          />
        </div>
      </Card>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={searchTerm ? searchedData : data}
      />
    </>
  )
}

export default CCMPatientFollowUpList
