import React from "react"

const TriageViewPrintBelowCard = () => {
  return (
    <div className="rounded shadow m-3 p-3">
      <p className="fw-bold">
        We will continue to monitor this patients transmissions closely and escalate to the
        provider, as needed.{" "}
      </p>

      <p>Start Time : </p>
      <p>Stop Time : </p>

      <p className="fw-bold">Thank you for allowing FitPeo to participate in your healthcare! </p>
    </div>
  )
}

export default TriageViewPrintBelowCard
