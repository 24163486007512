import {
  GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL,
  GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST,
  GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS,
  GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL,
  GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST,
  GET_SELF_ONBOARDING_PENDING_PATIENT_RESET,
  GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS,
  POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
  POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST,
  POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST,
  UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST,
  UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS
} from "../types/selfOnboardingConstant"

const initialState = {
  selfOnboardingPendingPatient: {
    selfOnboardingPatient: {},
    loading: false
  },
  onboardpatientStatistics: { onboardpatientStatistics: {}, loading: false },
  updateBulkCaretakerActivePatients: { loading: false, error: null },
  updateBulkCaretaker: { loading: false, error: null },
  postBulkMessage: { loading: false, error: null }
}

export const getSelfOnboardingPendingPatientReducer = (
  state = initialState.selfOnboardingPendingPatient,
  action
) => {
  switch (action.type) {
    case GET_SELF_ONBOARDING_PENDING_PATIENT_REQUEST:
      return { loading: true }
    case GET_SELF_ONBOARDING_PENDING_PATIENT_SUCCESS:
      return { loading: false, selfOnboardingPendingPatient: action.payload }
    case GET_SELF_ONBOARDING_PENDING_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    case GET_SELF_ONBOARDING_PENDING_PATIENT_RESET:
      return { loading: false, error: null }
    default:
      return state
  }
}

export const getOrgOnboardPatientStatisticsReducer = (
  state = initialState.onboardpatientStatistics,
  action
) => {
  switch (action.type) {
    case GET_ALL_ONBOARD_PATIENT_STATISTICS_REQUEST:
      return { loading: true }
    case GET_ALL_ONBOARD_PATIENT_STATISTICS_SUCCESS:
      return { loading: false, onboardpatientStatistics: action.payload }
    case GET_ALL_ONBOARD_PATIENT_STATISTICS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateBulkCaretakerActivePatientReducer = (
  state = initialState.updateBulkCaretakerActivePatients,
  action
) => {
  switch (action.type) {
    case UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_REQUEST:
      return { loading: true }
    case UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_SUCCESS:
      return { loading: false, error: null }
    case UPDATE_BULK_CARETAKER_ACTIVE_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateSelfOnboardingPatientCaretakerReducer = (
  state = initialState.updateBulkCaretaker,
  action
) => {
  switch (action.type) {
    case UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_REQUEST:
      return { loading: true }
    case UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_SUCCESS:
      return { loading: false, error: null }
    case UPDATE_SELF_ONBOARDING_PENDING_PATIENT_CARETAKER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postBulkMailMessagePatientReducer = (state = initialState.postBulkMessage, action) => {
  switch (action.type) {
    case POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST:
      return { loading: true }
    case POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS:
      return { loading: false, error: null }
    case POST_BULK_MAIL_MESSAGE_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
