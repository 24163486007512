import React from "react"
import { Modal, Button, Input } from "antd"

const ThirdProgYesAlertModal = ({ showModal, setShowModal, ques, setQues }) => {
  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      title={<h4 className="text-xl">Note</h4>}>
      <div className="pt-4">
        <p className="font-medium">{ques?.name}</p>

        <Input.TextArea
          className="my-4"
          value={ques?.text}
          onChange={e => setQues({ ...ques, text: e.target.value })}
        />

        <Button type="primary" onClick={() => setShowModal(false)}>
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default ThirdProgYesAlertModal
