import {
  GET_ALL_LASTREADINGTAKEN_REQUEST,
  GET_ALL_LASTREADINGTAKEN_SUCCESS,
  GET_ALL_LASTREADINGTAKEN_FAIL
} from "../types/LastReadingTakenAnalysisConstant"

const initialState = {
  loading: false,
  readingAnalysis: []
}

export const getLastReadingDateAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LASTREADINGTAKEN_REQUEST:
      return { loading: true }
    case GET_ALL_LASTREADINGTAKEN_SUCCESS:
      return { loading: false, readingAnalysis: action.payload }
    case GET_ALL_LASTREADINGTAKEN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
