import React, { useState } from "react"

import Spinner from "react-bootstrap/Spinner"
import { useToasts } from "react-toast-notifications"
import { Modal, Button, Row, Col, Select } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { postCriticalReadingSnoozeAction, postRpmOximeterClearTriageAction } from "../../../../redux/actions/rpmAction"
import { pushShortClearAction } from "../../../../redux/actions/utilsActions"
import { mapDeviceType } from "../../../../utils/mapDeviceType"

const ClearModal = ({ showClearModal, setShowClearModal, selectedIds, id, triageStartedAt, setTriageStartedAt, checkedDeviceType }) => {
  const [duration, setDuration] = useState("")
  const [reason, setReason] = useState("")
  const dispatch = useDispatch()
  const { loading } = useSelector(store => store.rpmClearTriage)
  const { addToast } = useToasts()

  let prevShortSession = sessionStorage.getItem("shortClearTimer")
  let shortClearData =
    prevShortSession !== undefined && prevShortSession !== null && prevShortSession !== "undefined"
      ? JSON.parse(prevShortSession)
      : []

  const handleClose = () => {
    setDuration("")
    setReason("")
    setTriageStartedAt(null)
    setShowClearModal(false)
  }

  const handleShortClear = async (duration) => {
    const dt = new Date()
    if (duration === "15 min") {
      dt.setMinutes(dt.getMinutes() + 15)
    }
    if (duration === "30 min") {
      dt.setMinutes(dt.getMinutes() + 30)
    }
    if (duration === "1 hour") {
      dt.setMinutes(dt.getMinutes() + 60)
    }
    const currentTime = dt

    let existingItem = []
    if (shortClearData !== undefined && shortClearData !== null && shortClearData !== "undefined") {
      existingItem = shortClearData
    }
    // add a map function for multiple ids
    selectedIds.map((readingId) => {
      let newItem = { readingId, duration, currentTime }
      existingItem.push(newItem)
    })
    await dispatch(pushShortClearAction(existingItem))
    handleClose()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const handleSubmit = async () => {
    if (duration === "Indefinitely") {
      if (reason === "")
        return addToast("Please select a reason", { appearance: "warning", autoDismiss: true })
      let res = await dispatch(postRpmOximeterClearTriageAction({userId: id, selectedIds, message: reason, triageStartedAt, deviceType: mapDeviceType(checkedDeviceType)}))
      if (res?.message === "Success") {
        addToast("Saved Successfully", { appearance: "success", autoDismiss: true })
        handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        addToast("Unable to save!!", { appearance: "error", autoDismiss: true })
      }
    } else {
      const result = await dispatch(postCriticalReadingSnoozeAction(id, selectedIds))
      if (result?.status !== 200) {
        addToast("Failed to snooze", { appearance: "error", autoDismiss: true })
        return
      }
      await handleShortClear(duration)
    }
  }

  return (
    <Modal
      open={showClearModal}
      onCancel={() => handleClose()}
      footer={null}
      title={<h4 className="text-xl">Snooze/Clear Triage. </h4>}>
      <div className="pt-4">
        <p className="font-medium text-lg mb-2">Select Time Period</p>
        <Row>
          <Col xs={12} md={8} lg={6} className="mb-3 text-center">
            <Button
              type={duration === "15 min" ? "primary" : "default"}
              onClick={() => setDuration("15 min")}>
              15 minutes
            </Button>
          </Col>
          <Col xs={12} md={8} lg={6} className="mb-3 text-center">
            <Button
              type={duration === "30 min" ? "primary" : "default"}
              onClick={() => setDuration("30 min")}>
              30 minutes
            </Button>
          </Col>
          <Col xs={12} md={8} lg={6} className="mb-3 text-center">
            <Button
              type={duration === "1 hour" ? "primary" : "default"}
              onClick={() => setDuration("1 hour")}>
              1 hour
            </Button>
          </Col>
          <Col xs={12} md={8} lg={6} className="mb-3 text-center">
            <Button
              type={duration === "Indefinitely" ? "primary" : "default"}
              onClick={() => setDuration("Indefinitely")}>
              Clear Triage
            </Button>
          </Col>
        </Row>
        {duration === "Indefinitely" ? (
          <div className="my-3 w-full">
            <p className="font-medium text-lg mb-2">Select reason for clear triage</p>
            <Select
              value={reason}
              className="w-full"
              onChange={val => setReason(val)}
              options={[
                {
                  value: "Will Continue To Monitor and Trend",
                  label: "Will Continue To Monitor and Trend"
                },
                // { value: "False Critical Alert", label: "False Critical Alert" },
                { value: "Improper use of Equipment", label: "Improper use of Equipment" },
                {
                  value: "Transmission Not Concerning At This Time",
                  label: "Transmission Not Concerning At This Time"
                },
                {
                  value: "Trends Are Not Concerning At This Time",
                  label: "Trends Are Not Concerning At This Time"
                },
                { value: "Reviewed with Provider", label: "Reviewed with Provider" },
                { value: "Reviewed with RN", label: "Reviewed with RN" },
                { value: "No Further Actions Required", label: "No Further Actions Required" },
                { value: "Invalid Reading", label: "Invalid Reading" }
              ]}
            />
          </div>
        ) : null}
        <div className="mt-4 flex flex-wrap gap-3">
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button type="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? <Spinner animation="border" size="sm" /> : "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ClearModal
