export const GET_SPECIAL_INSTRUCTIONS_REQUEST = "GET_SPECIAL_INSTRUCTIONS_REQUEST"
export const GET_SPECIAL_INSTRUCTIONS_SUCCESS = "GET_SPECIAL_INSTRUCTIONS_SUCCESS"
export const GET_SPECIAL_INSTRUCTIONS_FAIL = "GET_SPECIAL_INSTRUCTIONS_FAIL"

export const ADD_SPECIAL_INSTRUCTIONS_REQUEST = "ADD_SPECIAL_INSTRUCTIONS_REQUEST"
export const ADD_SPECIAL_INSTRUCTIONS_SUCCESS = "ADD_SPECIAL_INSTRUCTIONS_SUCCESS"
export const ADD_SPECIAL_INSTRUCTIONS_FAIL = "ADD_SPECIAL_INSTRUCTIONS_FAIL"

export const EDIT_SPECIAL_INSTRUCTIONS_REQUEST = "EDIT_SPECIAL_INSTRUCTIONS_REQUEST"
export const EDIT_SPECIAL_INSTRUCTIONS_SUCCESS = "EDIT_SPECIAL_INSTRUCTIONS_SUCCESS"
export const EDIT_SPECIAL_INSTRUCTIONS_FAIL = "EDIT_SPECIAL_INSTRUCTIONS_FAIL"

export const DELETE_SPECIAL_INSTRUCTIONS_REQUEST = "DELETE_SPECIAL_INSTRUCTIONS_REQUEST"
export const DELETE_SPECIAL_INSTRUCTIONS_SUCCESS = "DELETE_SPECIAL_INSTRUCTIONS_SUCCESS"
export const DELETE_SPECIAL_INSTRUCTIONS_FAIL = "DELETE_SPECIAL_INSTRUCTIONS_FAIL"

export const GET_SPECIAL_INSTRUCTIONS_HISTORY_REQUEST = "GET_SPECIAL_INSTRUCTIONS_HISTORY_REQUEST"
export const GET_SPECIAL_INSTRUCTIONS_HISTORY_SUCCESS = "GET_SPECIAL_INSTRUCTIONS_HISTORY_SUCCESS"
export const GET_SPECIAL_INSTRUCTIONS_HISTORY_FAIL = "GET_SPECIAL_INSTRUCTIONS_HISTORY_FAIL"