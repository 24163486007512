import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Modal, Button } from "antd"

import { useSelector } from "react-redux"

const AfterSubmitModal = ({ showModal, setShowModal, id }) => {
  const navigate = useNavigate()
  const {orgId, orgName} = useParams()
  // const dispatch = useDispatch()

  // useEffect(()=>{
  //     dispatch(getPatientInfo({patientId: id}));
  // },[])
  const { loading, patientInfoDetail } = useSelector(state => state.patientInfo)
  // console.log('pi-------',patientInfoDetail)

  const handleClose = () => {
    navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
      state: {
        name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
      }
    })
    setShowModal(false)
  }

  const handleSubmit = async () => {
    navigate(`/${orgId}/${orgName}/patient-more-details/${id}`, {
      state: { patientInfo: patientInfoDetail, addMThighlight: true, programType: 'RPM' }
    })
  }

  return (
    <Modal
      open={showModal}
      onCancel={handleClose}
      footer={null}
      title={<h4 className="text-xl">Alert</h4>}>
      <div className="pt-4">
        <p className="font-medium">
          You have now completed your triage assessment. would you like to enter nursing note for
          this?
        </p>

        <div className="flex flex-wrap gap-3 mt-4">
          <Button danger onClick={handleClose}>
            No
          </Button>
          <Button type="primary" onClick={handleSubmit} disabled={loading}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AfterSubmitModal
