import {
    GET_GROUPS_REQUEST,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAIL,
    GET_GROUP_DETAIL_REQUEST,
    GET_GROUP_DETAIL_SUCCESS,
    GET_GROUP_DETAIL_FAIL,
    ADD_GROUP_REQUEST,
    ADD_GROUP_SUCCESS,
    ADD_GROUP_FAIL,
    UPDATE_GROUP_REQUEST,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAIL,
    GET_ADMIN_CARETAKER_FOR_GROUP_REQUEST,
    GET_ADMIN_CARETAKER_FOR_GROUP_SUCCESS,
    GET_ADMIN_CARETAKER_FOR_GROUP_FAIL,
    MAP_GROUP_TO_PATIENT_REQUEST,
    MAP_GROUP_TO_PATIENT_SUCCESS,
    MAP_GROUP_TO_PATIENT_FAIL,
    VALIDATE_GROUP_NAME_REQUEST,
    VALIDATE_GROUP_NAME_SUCCESS,
    VALIDATE_GROUP_NAME_FAIL,
    GET_PATIENTS_FOR_GROUP_REQUEST,
    GET_PATIENTS_FOR_GROUP_SUCCESS,
    GET_PATIENTS_FOR_GROUP_FAIL,
    SET_GROUP_PATIENT_ADVANCE_FILTER,
    RESET_GROUP_PATIENT_ADVANCE_FILTER,
    SET_GROUP_PATIENT_LIST_ADVANCE_FILTER,
    RESET_GROUP_PATIENT_LIST_ADVANCE_FILTER,
    GET_PATIENT_GROUP_NAME_REQUEST,
    GET_PATIENT_GROUP_NAME_SUCCESS,
    GET_PATIENT_GROUP_NAME_FAIL
} from "../types/groupsConstants"

// get the all groups
export const getGroupsReducer = (
    state = { loading: false, groups: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case GET_GROUPS_REQUEST:
            return { loading: true }
        case GET_GROUPS_SUCCESS:
            return { loading: false, groups: action.payload }
        case GET_GROUPS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// get the group detail
export const getGroupDetailReducer = (
    state = { loading: false, groupDetail: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case GET_GROUP_DETAIL_REQUEST:
            return { loading: true }
        case GET_GROUP_DETAIL_SUCCESS:
            return { loading: false, groupDetail: action.payload }
        case GET_GROUP_DETAIL_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// add a group
export const addGroupReducer = (
    state = { loading: false, addGroup: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case ADD_GROUP_REQUEST:
            return { loading: true }
        case ADD_GROUP_SUCCESS:
            return { loading: false, addGroup: action.payload }
        case ADD_GROUP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// update a group
export const updateGroupReducer = (
    state = { loading: false, updateGroup: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case UPDATE_GROUP_REQUEST:
            return { loading: true }
        case UPDATE_GROUP_SUCCESS:
            return { loading: false, updateGroup: action.payload }
        case UPDATE_GROUP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// get the admin caretaker for group
export const getAdminCaretakerForGroupReducer = (
    state = { loading: false, adminCaretakerForGroup: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case GET_ADMIN_CARETAKER_FOR_GROUP_REQUEST:
            return { loading: true }
        case GET_ADMIN_CARETAKER_FOR_GROUP_SUCCESS:
            return { loading: false, adminCaretakerForGroup: action.payload }
        case GET_ADMIN_CARETAKER_FOR_GROUP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// map group to patient
export const mapGroupToPatientReducer = (
    state = { loading: false, mapGroupToPatient: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case MAP_GROUP_TO_PATIENT_REQUEST:
            return { loading: true }
        case MAP_GROUP_TO_PATIENT_SUCCESS:
            return { loading: false, mapGroupToPatient: action.payload }
        case MAP_GROUP_TO_PATIENT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// validate group name
export const validateGroupNameReducer = (
    state = { loading: false, isValidateGroupName: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case VALIDATE_GROUP_NAME_REQUEST:
            return { loading: true }
        case VALIDATE_GROUP_NAME_SUCCESS:
            return { loading: false, isvalidateGroupName: action.payload }
        case VALIDATE_GROUP_NAME_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const getPatientsForGroupReducer = (
    state = { loading: false, patientsForGroup: undefined, error: undefined },
    action
) => {
    switch (action.type) {
        case GET_PATIENTS_FOR_GROUP_REQUEST:
            return { loading: true }
        case GET_PATIENTS_FOR_GROUP_SUCCESS:
            return { loading: false, patientsForGroup: action.payload }
        case GET_PATIENTS_FOR_GROUP_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const groupPatientAdvFilterReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_GROUP_PATIENT_ADVANCE_FILTER:
            return { ...state, groupPatientAdvFilter: action.payload }
        case RESET_GROUP_PATIENT_ADVANCE_FILTER:
            return { ...state, groupPatientAdvFilter: null }
        default:
            return state
    }
}

export const groupPatientsListAdvFilterReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_GROUP_PATIENT_LIST_ADVANCE_FILTER:
            return { ...state, groupPatientsListAdvFilter: action.payload }
        case RESET_GROUP_PATIENT_LIST_ADVANCE_FILTER:
            return { ...state, groupPatientsListAdvFilter: null }
        default:
            return state
    }
}

export const getPatientGroupNameReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PATIENT_GROUP_NAME_REQUEST:
            return { loading: true }
        case GET_PATIENT_GROUP_NAME_SUCCESS:
            return { loading: false, patientGroupName: action.payload }
        case GET_PATIENT_GROUP_NAME_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}