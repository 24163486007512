import {
  GET_ENROLLMENT_STATS_REQUEST,
  GET_ENROLLMENT_STATS_SUCCESS,
  GET_ENROLLMENT_STATS_FAIL,
  GET_ENROLLMENT_PATIENT_COUNT_REQUEST,
  GET_ENROLLMENT_PATIENT_COUNT_SUCCESS,
  GET_ENROLLMENT_PATIENT_COUNT_FAIL,
  CRITICAL_PATIENT_FAIL,
  CRITICAL_PATIENT_REQUEST,
  CRITICAL_PATIENT_SUCCESS,
  CRITICAL_PATIENT_RESET,
  DASHBOARD_KPI_FAIL,
  DASHBOARD_KPI_REQUEST,
  DASHBOARD_KPI_SUCCESS,
  FILTER_DASHBOARD_FAIL,
  FILTER_DASHBOARD_REQUEST,
  FILTER_DASHBOARD_SUCCESS,
  GET_USER_COUNT_STATS_REQUEST,
  GET_USER_COUNT_STATS_SUCCESS,
  GET_USER_COUNT_STATS_FAIL,
  GET_TRIAGE_STATS_ANALYSIS_REQUEST,
  GET_TRIAGE_STATS_ANALYSIS_SUCCESS,
  GET_TRIAGE_STATS_ANALYSIS_FAIL,
  GET_TRIAGE_STATS_COUNT_REQUEST,
  GET_TRIAGE_STATS_COUNT_SUCCESS,
  GET_TRIAGE_STATS_COUNT_FAIL,
} from "../types/dashboardConstant"

const initialState = {
  dashboardKPI: {},
  criticalPatient: {},
  userCount: {}
}

export const getUserCountStatsReducer = (state = initialState.userCount, action) => {
  switch (action.type) {
    case GET_USER_COUNT_STATS_REQUEST:
      return { loading: true }
    case GET_USER_COUNT_STATS_SUCCESS:
      return { loading: false, userCountStats: action.payload }
    case GET_USER_COUNT_STATS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const dashboardKPIReducer = (state = initialState.dashboardKPI, action) => {
  switch (action.type) {
    case DASHBOARD_KPI_REQUEST:
      return { loading: true }
    case DASHBOARD_KPI_SUCCESS:
      return { loading: false, dashboardKPI: action.payload }
    case DASHBOARD_KPI_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const criticalPatientReducer = (state = initialState.criticalPatient, action) => {
  switch (action.type) {
    case CRITICAL_PATIENT_REQUEST:
      return { loading: true }
    case CRITICAL_PATIENT_SUCCESS:
      return { loading: false, criticalPatient: action.payload }
    case CRITICAL_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    case CRITICAL_PATIENT_RESET:
      return { loading: false, criticalPatient: undefined }
    default:
      return state
  }
}

export const filterDashboardReducer = (state = initialState.dashboardKPI, action) => {
  switch (action.type) {
    case FILTER_DASHBOARD_REQUEST:
      return { loading: true }
    case FILTER_DASHBOARD_SUCCESS:
      return { loading: false, dashboardKPI: action.payload }
    case FILTER_DASHBOARD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const enrollmentStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ENROLLMENT_STATS_REQUEST:
      return { loading: true }
    case GET_ENROLLMENT_STATS_SUCCESS:
      return { loading: false, enrollmentStats: action.payload }
    case GET_ENROLLMENT_STATS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const enrollmentPatientCountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ENROLLMENT_PATIENT_COUNT_REQUEST:
      return { loading: true }
    case GET_ENROLLMENT_PATIENT_COUNT_SUCCESS:
      return { loading: false, enrollmentPatientCount: action.payload }
    case GET_ENROLLMENT_PATIENT_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const triageStatsAnalysisReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TRIAGE_STATS_ANALYSIS_REQUEST:
      return { loading: true }
    case GET_TRIAGE_STATS_ANALYSIS_SUCCESS:
      return { loading: false, triageStatsAnalysis: action.payload }
    case GET_TRIAGE_STATS_ANALYSIS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const triageStatsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TRIAGE_STATS_COUNT_REQUEST:
      return { loading: true }
    case GET_TRIAGE_STATS_COUNT_SUCCESS:
      return { loading: false, triageStatsCount: action.payload }
    case GET_TRIAGE_STATS_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}