import React from 'react'
import {PlusOutlined} from "@ant-design/icons"
import { Button } from "antd";


function PCMEditFormInput({
    ques,
    handleChange,
    handleInput,
    handleAddInputField,
    otherInputs,
    setOtherInputs,
    currentDisabled,
    conclusionDisabled,
    chronicDisabled,
    medicalDisabled,
    medicationDisabled,
    QIndex,
}) {
    switch (ques.quesType) {
        case "text":
            return (
                <div style={{ marginTop: "10px" }}>
                    <textarea
                        required
                        id={ques.id}
                        type="text"
                        disabled={currentDisabled ? true : medicalDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                        style={{ width: "100%", height: "50px", padding: 3 }}
                        value={ques.notApplicable ? "" : ques.value ? ques.value : ""}
                        onChange={(e) => {
                            handleChange(ques.id, e, ques);
                        }}
                    ></textarea>
                </div>
            );
            
        case "multi":
            if (ques["value"] !== undefined) {
                if(ques?.value?.other !== undefined && ques?.value?.other === 'on') {
                    ques["value"]['other'] = [""];
                }
            }
            return ques.questionaryOptions?.map((opt, index) => (
                <div key={index} style={{ marginTop: "10px" }}>
                    <input
                        style={{ marginRight: "5px" }}
                        type="checkbox"
                        disabled={conclusionDisabled ? true : chronicDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                        id={`custom-checkbox-${index}`}
                        name="dsa"
                        checked={ques.notApplicable ? "" : ques.value ? ques.value[index] : false}
                        // onChange={(e) => handleChange(ques.id, e, index)}
                        onChange={(e) => {
                            handleChange(ques.id, e, ques, index);
                        }}
                        
                    />
                    <label style={{ fontSize: "12px" }} className="text-capitalize">{opt.name}</label>
                    <br />
                    {opt.name.trim() === "other" && ques.value && ques.value[index] === true && (
                        <textarea
                            type="text"
                            style={{ width: "100%", height: "30px", marginTop: "10px", padding: 3 }}
                            disabled={chronicDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                            //value={ques.notApplicable ? "" : otherInputs[ques.id] ? otherInputs[ques.id].value : ""}
                            value={ques.notApplicable === false ?
                                    ques.value[ques.questionaryOptions.length+1]?.other &&
                                    ques.value[ques.questionaryOptions.length+1].other : 
                                    ''
                                }
                            // onChange={(e) => {
                            //     setOtherInputs({
                            //         ...otherInputs,
                            //         [ques.id]: { value: e.target.value },
                            //     });
                            // }}
                            // onChange={(e) => {
                            //     handleChange(ques.id, e, 'other')           
                            // }}
                            onChange={(e) => {
                                handleChange(ques.id, e, ques,'other');
                            }}
                        ></textarea>
                    )}
                </div>
            ));
        case "bool-text":
            return (
                <div style={{ marginTop: "10px" }}>
                    <div style={{ display: "flex" }}>
                        <div
                            className="form-input"
                            style={{
                                display: "flex",
                                alignItem: "center",
                                marginRight: "20px",
                                marginBottom: 0,
                            }}>
                            <input
                                type="radio"
                                id={ques.id}
                                value="true"
                                checked={ques?.value?.toString() === "true"}
                                disabled={medicationDisabled ? true : currentDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                name={ques.id}
                                size="100"
                                style={{ marginRight: "5px" }}
                                // onChange={(e) => handleChange(ques.id, e)}
                                onChange={(e) => {
                                    handleChange(ques.id, e, ques);
                                }}
                            />
                            <label style={{ fontSize: "14px" }}>YES</label>
                        </div>
                        <div
                            className="form-input"
                            style={{
                                display: "flex",
                                alignItem: "center",
                                marginRight: "20px",
                            }}>
                            <input
                                type="radio"
                                disabled={medicationDisabled ? true : currentDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                id={ques.id}
                                value="false"
                                checked={ques?.value?.toString() === "false"}
                                name={ques.id}
                                size="100"
                                style={{ marginRight: "5px" }}
                                // onChange={(e) => handleChange(ques.id, e)}
                                onChange={(e) => {
                                    handleChange(ques.id, e, ques);
                                }}
                            />
                            <label style={{ fontSize: "14px" }}>NO</label>
                        </div>
                    </div>
                    {((ques.onCondition && ques.value) || (!ques.onCondition && !ques.value)) &&
                        ques.value !== undefined &&
                        ques.questionaryOptions?.map((que, index) => (
                            <div key={index} className="d2">
                                <label
                                    style={{
                                        marginTop: "20px",
                                        fontSize: "14px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                    }}
                                    className="text-capitalize"
                                    >
                                    {que.name}
                                </label>
                                <textarea
                                    id={que.id}
                                    type="text"
                                    disabled={medicationDisabled ? true : currentDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                    style={{ width: "100%", height: "40px", padding: 3 }}
                                    value={ques.notApplicable ? "" : que.value ? que.value : ""}
                                    // onChange={(e) => {
                                    //     handleInput(ques.id, que.id, e);
                                    // }}
                                    onChange={(e) => {
                                        handleInput(ques.id,e,ques,que.id,que,index);
                                    }}
                                ></textarea>
                            </div>
                        ))}
                </div>
            );
        case "bool":
            return (
                <div style={{ display: "flex", marginBottom: "30px" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItem: "center",
                            marginRight: "20px",
                            marginBottom: 0,
                        }}>
                        <input
                            type="radio"
                            id={ques.id}
                            value="true"
                            checked={ques?.value?.toString() === "true"}
                            disabled={conclusionDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                            name={ques.id}
                            style={{ marginRight: "5px" }}
                            size="100"
                            // onChange={(e) => handleChange(ques.id, e)}
                            onChange={(e) => {
                                handleChange(ques.id, e, ques);
                            }}
                        />
                        <label style={{ fontSize: "14px", marginRight: "16px" }}>YES</label>
                        <input
                            type="radio"
                            id={ques.id}
                            disabled={conclusionDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                            value="false"
                            checked={ques?.value?.toString() === "false"}
                            name={ques.id}
                            size="100"
                            style={{ marginRight: "5px" }}
                            // onChange={(e) => handleChange(ques.id, e)}
                            onChange={(e) => {
                                handleChange(ques.id, e, ques);
                            }}
                        />
                        <label style={{ fontSize: "14px" }}>NO</label>
                    </div>
                </div>
            );
        case "text-multi":
            if (ques["value"] === undefined) {
                ques["value"] = [""];
            }
            return (
                <div style={{ marginTop: "10px" }}>
                    {ques["value"]?.map((val, index) => (
                        <textarea
                            key={index}
                            style={{ width: "100%", height: "30px", marginBottom: "8px", padding: 3 }}
                            id={ques.id}
                            type="text"
                            disabled={medicalDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                            value={ques.notApplicable ? "" : ques.value ? ques.value[index] : ""}
                            onChange={(e) => handleChange(ques.id, e,ques, index)}
                        ></textarea>
                    ))}
                    <Button
                        id={ques.id}
                        disabled={medicalDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                        onClick={(e) => handleAddInputField(ques.id,ques)}
                        variant="contained"
                        style={{
                            backgroundColor: "#D8D7D7",
                            marginTop: "10px",
                            color: "#838181",
                            textTransform: "capitalize",
                            width: "140px",
                            marginBottom: "-20px",
                        }}
                        icon={ <PlusOutlined /> }>
                        Add Another
                    </Button>
                </div>
            );
        case "bool-text-multi":
            ques.questionaryOptions.forEach((que, index) => {
                if (que["value"] === undefined) {
                    que["value"] = [""];
                }
            });
            return (
                <div style={{ marginBottom: "20px" }}>
                    <div style={{ display: "flex" }}>
                        <div
                            className="form-input"
                            style={{
                                display: "flex",
                                alignItem: "center",
                                marginRight: "20px",
                            }}>
                            <input
                                type="radio"
                                id={ques.id}
                                disabled={medicationDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                value="true"
                                name={ques.id}
                                size="100"
                                checked={ques?.value?.toString() === "true"}
                                style={{ marginRight: "5px" }}
                                onChange={(e) => handleChange(ques.id, e,ques)}
                            />
                            <label style={{ fontSize: "14px" }}>YES</label>
                        </div>
                        <div
                            className="form-input"
                            style={{
                                display: "flex",
                                alignItem: "center",
                                marginRight: "20px",
                            }}>
                            <input
                                type="radio"
                                disabled={medicationDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                id={ques.id}
                                value="false"
                                checked={ques?.value?.toString() === "false"}
                                name={ques.id}
                                size="100"
                                style={{ marginRight: "5px" }}
                                onChange={(e) => handleChange(ques.id, e, ques)}
                            />
                            <label style={{ fontSize: "14px" }}>NO</label>
                        </div>
                    </div>
                    {((ques.onCondition && ques.value) || (!ques.onCondition && !ques.value)) &&
                        ques.value !== undefined &&
                        ques.questionaryOptions?.map((que, index) => {
                            return (
                                <div key={index} className="d2">
                                    <h5 style={{ marginBottom: "10px" }} className="text-capitalize">{que.name}</h5>
                                    {que["value"]?.map((val, ind) => (
                                        <textarea
                                            key={index}
                                            id={que.id}
                                            type="text"
                                            disabled={medicationDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                            style={{
                                                width: "100%",
                                                height: "30px",
                                                marginBottom: "10px",
                                                padding: 3
                                            }}
                                            value={ques.notApplicable ? "" : que["value"] ? que["value"][ind] : ""}
                                            onChange={(e) => {
                                                handleInput(ques.id,e,ques,que.id,que,ind);
                                            }}
                                        ></textarea>
                                    ))}
                                    <Button
                                        id={ques.id}
                                        onClick={(e) => handleAddInputField(ques.id,ques, true, index)}
                                        variant="contained"
                                        disabled={medicationDisabled ? true : ques.notApplicable !== undefined && ques.notApplicable ? true : false}
                                        style={{
                                            backgroundColor: "#D8D7D7",
                                            marginTop: "10px",
                                            color: "#838181",
                                            marginBottom: "-40px",
                                            textTransform: "capitalize",
                                        }}
                                        icon={ <PlusOutlined /> }>
                                        Add Another
                                    </Button>
                                </div>
                            );
                        })}
                </div>
            );

        default:
            return null;
    }
}

export default PCMEditFormInput
