import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import ReactToPrint from "react-to-print"
import { Card } from "antd"
import PCMdisabledFormInput from "../../../PCMAdminQuestions/PCMdisabledFormInput"
import moment from "moment"
import { getDateOfService } from "../../../../../utils/getDateOfService"

const PCMViewPrintV2 = () => {
    const [otherInputs, setOtherInputs] = useState({})

    let navigate = useNavigate()
    const location = useLocation()
    // const title = location.state && location.state.title
    const componentRef = useRef()

    const patientInfoDetail = location.state && location.state.patientInfoDetail

    const plan = location.state && location.state?.plan
    const planIcds = location.state && location.state?.planIcds
    const planDate = location.state && location.state?.planDate
    const provider = location.state && location.state?.provider
    const practitioner = location.state && location.state?.practitioner
    const addedBy = location.state && location.state?.addedBy

    useEffect(() => {
        if (!patientInfoDetail || !plan) {
            navigate("/pcm/patients")
        }
    }, [location.state])

    const generatePageNum = () => {
        //let totalPages = Math.ceil(document.body.scrollHeight / 1123); //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
        let totalPages = Math.ceil(plan?.length / 3)
        for (let i = 1; i <= totalPages; i++) {
            let pageNumberDiv = document.createElement("div")
            let pageNumber = document.createTextNode("Page " + i + " of " + totalPages)
            pageNumberDiv.classList.add("page-num-div")
            pageNumberDiv.style.position = "absolute"
            pageNumberDiv.style.top = "calc((" + i + " * (297mm - 0.5px)) - 40px)" //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
            pageNumberDiv.style.height = "16px"
            pageNumberDiv.appendChild(pageNumber)
            let parentDiv = document.getElementById("view-container")
            let originalDiv = document.getElementById("content")
            parentDiv.insertBefore(pageNumberDiv, originalDiv.nextSibling)
            pageNumberDiv.style.left = "calc(100% - (" + pageNumberDiv.offsetWidth + "px + 110px))"
            pageNumberDiv.style.backgroundColor = "#DBDBDB"
            pageNumberDiv.style.paddingBottom = "24px"
            pageNumberDiv.style.borderRadius = "6px"
        }
    }
    useEffect(() => {
        generatePageNum()
    }, [plan])

    return (
        <>
            <div className="view-container">
                <div id="content" className="no-shadow">
                    <div
                        className="view-container"
                        id="view-container"
                        ref={componentRef}
                        style={{
                            padding: "20px",
                            overflowY: "auto"
                        }}>
                        <div className="d-flex justify-content-between w-100 py-2">
                            <h4 className="Ish px-3">Answers</h4>
                            <div className="d-flex noprint">
                                <div>
                                    <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={() => window.history.back()}>
                                        Back
                                    </button>
                                </div>
                                <div>
                                    <ReactToPrint
                                        trigger={() => (
                                            <button className="btn btn-primary btn-sm mx-2">Print as PDF</button>
                                        )}
                                        content={() => componentRef.current}
                                    />
                                </div>
                                {/* <div>
                            <button className="btn btn-dark btn-sm mx-2" onClick={exportPdf}>Test Print</button>
                        </div> */}
                            </div>
                        </div>
                        {/* <div id="pageFooter">Page </div> */}

                        <div className="CCMAddChronic no-shadow">
                            <Card>
                                <h4 className="text-secondary text-2xl">PCM Careplan</h4>
                                <h3 className="text-secondary mt-1 mb-2">Patient Details</h3>
                                <div className="row justify-content-between">
                                    <div className="col-md-6 capitalize">
                                        <p className="mb-1">
                                            Date Of Creation : {moment(planDate).format("MM/DD/YYYY")}
                                        </p>
                                        <p className="mb-1">
                                            Patient Name :{" "}
                                            {`${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName
                                                }`}
                                        </p>
                                        <p className="mb-1">
                                            Patient DOB :{" "}
                                            {patientInfoDetail?.dob &&
                                                moment(patientInfoDetail?.dob).format("MM/DD/YYYY")}
                                        </p>
                                        {addedBy && (
                                            <p className="mb-1">Added By : {`${addedBy?.name} (${addedBy?.userType})`}</p>
                                        )}
                                    </div>

                                    <div className="col-md-6 capitalize">
                                        <p className="mb-1">Monitor Type : PCM</p>
                                        {/* <p className="mb-1">
                      Date&nbsp;of&nbsp;Plan&nbsp;Modified :&nbsp;
                      {patientInfoDetail?.careplanStats
                        ? moment
                            .unix(patientInfoDetail?.careplanStats?.updatedAt)
                            .format("MM/DD/YYYY")
                        : ""}
                    </p> */}
                                        {provider && (provider?.length !== 0) && (
                                            <p className="mb-1">
                                                Provider :{" "}{provider?.join(", ")}
                                            </p>
                                        )}
                                        {practitioner && (practitioner?.length !== 0) && (
                                            <p className="mb-1">
                                                Practitioner :{" "}{practitioner?.join(", ")}
                                            </p>
                                        )}

                                        <p className="mb-1">Diagnostic(ICD) Codes : {planIcds?.join(", ")}</p>

                                        {/* <p>
                Chronic Conditions :{" "}
                {Array.from(chronicConditions ?? ["test","check"].keys()).join(", ")}
              </p> */}
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="plan-print-div" style={{ counterIncrement: "page" }}>
                            {plan?.map((ques, index) => (
                                <div
                                    className="card w-100 mb-3 shadow p-4 no-shadow mt-4"
                                    key={index}
                                    style={{ pageBreakAfter: index % 3 == 0 ? "always" : "avoid", color: "black" }}>
                                    {" "}
                                    {ques?.quesCategory ? (
                                        <div
                                            className="fw-bold bg-light p-1 shadow capitalize mb-4"
                                            style={{ borderRadius: "inherit" }}>
                                            <p className="text-info py-1">{ques?.quesCategory}</p>
                                            <p className="text-secondary opacity-75 py-1">{ques?.title}</p>
                                        </div>
                                    ) : null}
                                    <h4 className="mb-2">{ques?.name}</h4>
                                    {/* <h5 className="subHeading">{ques?.title}</h5> */}
                                    <PCMdisabledFormInput
                                        ques={ques}
                                        otherInputs={otherInputs}
                                        setOtherInputs={setOtherInputs}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PCMViewPrintV2
