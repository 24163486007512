import DashboardView from "../views/DashboardView"

import Page404 from "../views/404"
import LoginView from "../views/LoginView"
import ForgetPasswordView from "../views/ForgetPasswordView"

import MobileCCm from "../views/MobileCCM"
import MobileRPM from "../views/MobileRPM"
import MobileAddCareplan from "../views/MobileAddCareplan"
import MobileAddTreatementPlan from "../views/MobileAddTreatementPlan"

import PatientView from "../views/PatientView"
import AccountView from "../views/AccountView"
import PatientDetailView from "../views/PatientDetailView"
import PatientMoreDetailView from "../views/PatientMoreDetailView"
import PatientPlans from "../components/PatientDetails/PatientPlans"

import CCMEditCare from "../views/CCMEditCare"
import CCMAdminView from "../views/CCMAdminView"
import CCMFollowUp from "../views/CCMFollowUp"
import CCMViewCare from "../components/CCMAdmin/CCMViewCare/CCMViewCare"
import CCMIsNewPatient from "../components/CCMInitialScreen/CCMIsNewPatient"
import CCMIsPatientDetails from "../components/CCMInitialScreen/CCMIsPatientDetails"
import CCMPatientFollowUpList from "../components/CCMInitialScreen/CCMPatientFollwUpList"
import CCMViewPrint from "../components/CCMAdmin/CCMViewCare/Component/CCMViewQuestion/CCMViewPrint"
import CCMViewPrintV2 from "../components/CCMAdmin/CCMViewCare/Component/CCMViewQuestion/CCMViewPrintV2"
import CcmPlanHistoryList from "../components/CCMAdmin/CCMPlanHistory/CcmPlanHistoryList"
import CcmHistoryViewPlan from "../components/CCMAdmin/CCMPlanHistory/CcmHistoryViewPlan"

import RPMFollowUp from "../views/RPMFollowUp"
import RPMAdminView from "../views/RPMAdminView"
import RPMEditTreatementScreen from "../views/RPMEditTreatementScreen"
import RPMViewCare from "../components/RPMAdmin/RPMViewCare/RPMViewCare"
import RPMViewTriageInitialScreen from "../views/RPMViewTriageInitialScreen"
import RPMIsNewPatient from "../components/RPMInitialScreen/RPMIsNewPatient"
import RPMIsPatientDetails from "../components/RPMInitialScreen/RPMIsPatientDetails"
import RPMCriticalaPatientInitialScreen from "../views/RPMCriticalaPatientInitialScreen"
import RPMViewToPrintTriageInitialScreen from "../views/RPMViewToPrintTriageInitialScreen"
import RPMPatientFollowUpList from "../components/RPMInitialScreen/RPMPatientFollowUpList"
import RpmCriticalTimePassedInitialScreen from "../views/RpmCriticalTimePassedInitialScreen"
import RPMViewPrint from "../components/RPMAdmin/RPMViewCare/Component/RPMViewQuestion/RPMViewPrint"

import TriagePrintView from "../views/TriagePrintView"
import TriagePatient from "../components/RPMCriticalInitialScreen/Triage/TriagePatient"
import OrganizationList from "../components/Organization/Index"

import MainLayout from "../layout/MainLayout"
import AuthGuard from "../components/AuthGuard"
import MobilePainMgtEdit from "../views/MobilePainMgtEdit"
import MobilePainMgtView from "../views/MobilePainMgtView"
import MobileAddictionMedicineEdit from "../views/MobileAddictionMedicineEdit"
import MobileAddictionMedicineView from "../views/MobileAddictionMedicineView"
import MobileMentalHealthEdit from "../views/MobileMentalHealthEdit"
import MobileMentalHealthView from "../views/MobileMentalHealthView"
import RPMViewPrintV2 from "../components/RPMAdmin/component/RPMViewQuestion/RPMViewPrintV2"
import RPMCriticalPatientInitialScreen from "../views/RPMCriticalaPatientInitialScreen"
import RPMPlanHistory from "../components/RPMAdmin/RPMPlanHistory"
import CriticalPatientDetails from "../components/RPMCriticalInitialScreen/CriticalPatientDetails"
import InternalNotes from "../components/InternalNotes/InternalNotes"
import PractitionerList from "../components/Practitioner/PractitionerList"
import PractitionerProfile from "../components/Practitioner/PractitionerProfile"
import PCMIsPatientDetails from "../components/PCMInitialScreen/PCMIsPatientDetails"
import PCMAdminView from "../views/PCMAdminView"
import PCMViewCare from "../components/PCMAdmin/PCMViewCare/PCMViewCare"
import PcmPlanHistoryList from "../components/PCMAdmin/PCMPlanHistory/PcmPlanHistoryList"
import PcmHistoryViewPlan from "../components/PCMAdmin/PCMPlanHistory/PcmHistoryViewPlan"
import PCMViewPrintV2 from "../components/PCMAdmin/PCMViewCare/Component/PCMViewQuestion/PCMViewPrintV2"
import PCMFollowUp from "../views/PCMFollowUp"
import PCMEditCare from "../views/PCMEditCare"
import MobilePCM from "../views/MobilePCM"
import GroupsListView from "../views/GroupsListView"
import GroupDetails from "../components/Groups/GroupDetails"
import PageNotFoundMultiOrg from "../views/404WithMessageMultiOrg"
import TriageInitialScreen from "../views/triageinitialScreen"
import ViewTriageFalseReport from "../components/RPMCriticalInitialScreen/ViewToPrintTriage/ViewTriageFalseReport"
import LastReadingAnalysis from "../components/LastReadingAnalysis/LastReadingAnalysis"

const Routes = [
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <MainLayout from="org">
          <DashboardView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/last-reading-analysis",
    element: (
      <AuthGuard>
        <MainLayout from="org">
          <LastReadingAnalysis />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/",
    element: (
      <AuthGuard >
        <MainLayout from="org">
          <OrganizationList />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/login",
    element: <LoginView />
  },
  {
    path: "/forgot-password",
    element: <ForgetPasswordView />
  },
  {
    path: "/settings",
    element: (
      <AuthGuard>
        <MainLayout from="org">
          <AccountView />
        </MainLayout>
      </AuthGuard>
    )
  },
  // {
  //   path: "/mobile-pain-management-edit",
  //   element: <MobilePainMgtEdit />
  // },
  // {
  //   path: "/mobile-pain-management-view",
  //   element: <MobilePainMgtView />
  // },
  // {
  //   path: "/mobile-addiction-medicine-edit",
  //   element: <MobileAddictionMedicineEdit />
  // },
  // {
  //   path: "/mobile-addiction-medicine-view",
  //   element: <MobileAddictionMedicineView />
  // },
  // {
  //   path: "/mobile-mental-health-edit",
  //   element: <MobileMentalHealthEdit />
  // },
  // {
  //   path: "/mobile-mental-health-view",
  //   element: <MobileMentalHealthView />
  // },
  {
    path: "/mobile-ccm",
    element: <MobileCCm />
  },
  {
    path: "/mobile-pcm",
    element: <MobilePCM />
  },
  {
    path: "/mobile-add-careplan",
    element: <MobileAddCareplan />
  },
  {
    path: "/mobile-add-treatement",
    element: <MobileAddTreatementPlan />
  },
  {
    path: "/mobile-rpm",
    element: <MobileRPM />
  },
  {
    path: "/:orgId/:orgName/practitioners",
    element: (
      <AuthGuard>
        <MainLayout>
          <PractitionerList />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/practitioner/:practitionerId",
    element: (
      <AuthGuard>
        <MainLayout>
          <PractitionerProfile />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/patient",
    element: (
      <AuthGuard>
        <MainLayout>
          <PatientView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/patient/:id",
    element: (
      <AuthGuard>
        <MainLayout>
          <PatientDetailView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/patient-more-details/:id",
    element: (
      <AuthGuard>
        <MainLayout>
          <PatientMoreDetailView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/internal-notes/:patientId",
    element: (
      <AuthGuard>
        <MainLayout>
          <InternalNotes />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/patient-plans/:id",
    element: (
      <AuthGuard>
        <MainLayout>
          <PatientPlans />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/ccm-questions",
    element: (
      <MainLayout>
        <AuthGuard>
          <CCMAdminView />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/ccm-plan-history/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <CcmPlanHistoryList />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/history-view-plan",
    element: (
      <MainLayout>
        <AuthGuard>
          <CcmHistoryViewPlan />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/ccm-edit-care/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <CCMEditCare />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/ccm-view-care",
    element: (
      <MainLayout>
        <AuthGuard>
          <CCMViewCare />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/ccm-follow-up/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <CCMFollowUp />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/ccm-follow-up-m-y/:id/:month/:year",
    element: (
      <MainLayout>
        <AuthGuard>
          <CCMFollowUp />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/ccm-view-care-print",
    element: (
      <MainLayout>
        <AuthGuard>
          {/* <CCMViewPrint /> */}
          <CCMViewPrintV2 />
        </AuthGuard>
      </MainLayout>
    )
  },
  // {
  //   path: "/ccm/new-patients",
  //   element: (
  //     <Layout>
  //       <AuthGuard>
  //         <CCMIsNewPatient />
  //       </AuthGuard>
  //     </Layout>
  //   )
  // },
  {
    path: "/:orgId/:orgName/ccm/patients",
    element: (
      <MainLayout>
        <AuthGuard>
          <CCMIsPatientDetails />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm-questions",
    element: (
      <MainLayout>
        <AuthGuard>
          <PCMAdminView />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm-plan-history/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <PcmPlanHistoryList />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm-history-view-plan",
    element: (
      <MainLayout>
        <AuthGuard>
          <PcmHistoryViewPlan />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm-edit-care/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <PCMEditCare />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm-view-care",
    element: (
      <MainLayout>
        <AuthGuard>
          <PCMViewCare />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm-follow-up/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <PCMFollowUp />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm-view-care-print",
    element: (
      <MainLayout>
        <AuthGuard>
          <PCMViewPrintV2 />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/pcm/patients",
    element: (
      <MainLayout>
        <AuthGuard>
          <PCMIsPatientDetails />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm/patients",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMIsNewPatient />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm/new-patient/added-plan",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMIsPatientDetails />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm/new-patient/follow-up",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMPatientFollowUpList />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-questions/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMAdminView />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-view-treatement/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMViewCare />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-edit-treatement/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMEditTreatementScreen />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-plan-history/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMPlanHistory />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-view-treatement-print",
    element: (
      <MainLayout>
        <AuthGuard>
          {/* <RPMViewPrint /> */}
          <RPMViewPrintV2 />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-follow-up/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMFollowUp />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/triage",
    element: (
      <MainLayout>
        <AuthGuard>
          <TriageInitialScreen />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-critical-patient",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMCriticalPatientInitialScreen />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-critical-time-passed",
    element: (
      <MainLayout>
        <AuthGuard>
          <RpmCriticalTimePassedInitialScreen />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-triage-patient/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <TriagePatient />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-triage-patient/:id/:type/:readingId",
    element: (
      <MainLayout>
        <AuthGuard>
          <TriagePatient />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-triage-view/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMViewTriageInitialScreen />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/triage-false-report",
    element: (
      <MainLayout>
        <AuthGuard>
          <ViewTriageFalseReport />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/critical-patient-readings/:id",
    element: (
      <MainLayout>
        <AuthGuard>
          <CriticalPatientDetails />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/rpm-view-triage/:id/:type",
    element: (
      <MainLayout>
        <AuthGuard>
          <RPMViewToPrintTriageInitialScreen />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/triage-view",
    element: (
      <MainLayout>
        <AuthGuard>
          <TriagePrintView />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/groups",
    element: (
      <MainLayout>
        <AuthGuard>
          <GroupsListView />
        </AuthGuard>
      </MainLayout>
    )
  },
  {
    path: "/:orgId/:orgName/groups-details",
    element: (
      <MainLayout>
        <AuthGuard>
          <GroupDetails />
        </AuthGuard>
      </MainLayout>
    )
  },
  // multi org start from here the old routes
  {
    path: "/patient",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/patient/:id",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/rpm/patients",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/practitioners",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/ccm/patients",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/pcm/patients",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/rpm/patients",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/rpm-critical-patient",
    element: <PageNotFoundMultiOrg />
  },
  {
    path: "/rpm-critical-time-passed",
    element: <PageNotFoundMultiOrg />
  },
  //the above code added for the multi org purpose after few months we can remove this [26 june 2024]
  {
    path: "*",
    element: <Page404 />
  }
]
export default Routes
