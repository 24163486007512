import { Button, Card, Select, Spin } from "antd"
import MyDatePicker from "../../utils/MyDatePicker"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import moment from "moment"
import { triageStatsAnalysisAction } from "../../redux/actions/dashboardAction"
import { useNavigate } from "react-router-dom"
import Plot from "react-plotly.js"

const { RangePicker } = MyDatePicker

const activeTimelineStyle = "bg-primary min-w-[74px] text-[#fff] px-2 rounded-[4px] font-semibold"
const defaultTimelineStyle = "bg-[#D8DAE5] min-w-[74px] px-2 rounded-[4px] font-semibold"

const TIMELINES = Object.freeze({
    ten_min: "10 min",
    thirty_min: "30 min",
    one_hr: "1 Hr",
    one_day: "1 Day",
    one_week: "1 week",
    one_month: "1 Month",
    one_year: "1 Year"
})

const TriageDashboardChart = ({ selectedOrg }) => {
    const [graphType, setGraphType] = useState("line")
    const [queryType, setQueryType] = useState(["received"])
    const [timeline, setTimeline] = useState("1 Month")
    const [graphSeries, setGraphSeries] = useState([])
    const [graphCategories, setGraphCategories] = useState([])
    const [range, setRange] = useState()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userInfo } = useSelector(state => state.userInfo)
    const { loading, triageStatsAnalysis } = useSelector(state => state.triageStatsAnalysis)

    const disabledDate = current => {
        if (!range || range.length === 0) {
            // If no range is selected yet, allow all dates
            return false
        }
        const tooLate = range[0] && current.diff(range[0], "days") > 32
        // const tooEarly = range[1] && range[1].diff(current, "days") >= 31
        return !!tooLate || current > moment()
    }
    const handleRangeChange = async (date, dateString) => {
        setRange(date)
        if (!date) {
            setTimeline("1 Month")
        } else if (date && date?.length === 2) {
            setTimeline(null)
        }
    }

    useEffect(() => {
        switch (timeline) {
            case TIMELINES.ten_min:
                setRange([moment(new Date()).subtract(10, "minutes"), moment(new Date())])
                break
            case TIMELINES.thirty_min:
                setRange([moment(new Date()).subtract(30, "minutes"), moment(new Date())])
                break
            case TIMELINES.one_hr:
                setRange([moment(new Date()).subtract(1, "hours"), moment(new Date())])
                break
            case TIMELINES.one_day:
                setRange([moment(new Date()).subtract(1, "days"), moment(new Date())])
                break
            case TIMELINES.one_week:
                setRange([moment(new Date()).subtract(1, "weeks"), moment(new Date())])
                break
            case TIMELINES.one_month:
                setRange([moment(new Date()).subtract(1, "months"), moment(new Date())])
                break
            case TIMELINES.one_year:
                setRange([moment(new Date()).subtract(1, "years"), moment(new Date())])
                break
            default:
                break
        }

    }, [timeline])

    useEffect(() => {
        if (range && range.length === 2 && selectedOrg?.id) {
            dispatch(
                triageStatsAnalysisAction(userInfo?.id, {
                    startDate: moment.utc(range[0]).format("YYYY-MM-DDTHH:mm:ss.SSS+00:00"),
                    endDate: moment.utc(range[1]).format("YYYY-MM-DDTHH:mm:ss.SSS+00:00")
                }, selectedOrg.id)
            )
        }
    }, [range, selectedOrg])

    const processData = (dates, timeline) => {
        const now = moment();
        const data = [];
        const categories = [];

        switch (timeline) {
            case TIMELINES.ten_min:
                for (let i = 0; i < 10; i++) {
                    const time = now.clone().subtract(10 - i, 'minutes');
                    categories.push(time.format('HH:mm'));
                    data.push(dates?.filter(date => moment(date).isSame(time, 'minute')).length || 0);
                }
                break;
            case TIMELINES.thirty_min:
                for (let i = 0; i < 30; i++) {
                    const time = now.clone().subtract(30 - i, 'minutes');
                    categories.push(time.format('HH:mm'));
                    data.push(dates?.filter(date => moment(date).isSame(time, 'minute')).length || 0);
                }
                break;
            case TIMELINES.one_hr:
                for (let i = 0; i < 60; i++) {
                    const time = now.clone().subtract(60 - i, 'minutes');
                    categories.push(time.format('HH:mm'));
                    data.push(dates?.filter(date => moment(date).isSame(time, 'minute')).length || 0);
                }
                break;
            case TIMELINES.one_day:
                for (let i = 0; i < 24; i++) {
                    const time = now.clone().subtract(24 - i, 'hours');
                    categories.push(time.format('HH:mm'));
                    data.push(dates?.filter(date => moment(date).isSame(time, 'hour')).length || 0);
                }
                break;
            case TIMELINES.one_week:
                for (let i = 0; i < 7; i++) {
                    const time = now.clone().subtract(7 - i, 'days');
                    categories.push(time.format('D MMM'));
                    data.push(dates?.filter(date => moment(date).isSame(time, 'day')).length || 0);
                }
                break;
            case TIMELINES.one_month:
                for (let i = 0; i < 30; i++) {
                    const time = now.clone().subtract(30 - i, 'days');
                    categories.push(time.format('D MMM'));
                    data.push(dates?.filter(date => moment(date).isSame(time, 'day')).length || 0);
                }
                break;
            case TIMELINES.one_year:
                for (let i = 0; i < 12; i++) {
                    const time = now.clone().subtract(12 - i, 'months');
                    categories.push(time.format('MMM'));
                    data.push(dates?.filter(date => moment(date).isSame(time, 'month')).length || 0);
                }
                break;
            default:
                const groupedDates = dates?.reduce((acc, date) => {
                    const formattedDate = moment(date).format('YYYY-MM-DD');
                    if (!acc[formattedDate]) {
                        acc[formattedDate] = [];
                    }
                    acc[formattedDate].push(date);
                    return acc;
                }, {});

                groupedDates && Object.keys(groupedDates)?.forEach(date => {
                    categories.push(moment(date).format('MM/DD/YYYY'));
                    data.push(groupedDates[date].length);
                });
                break;
        }

        return { data, categories };
    };

    useEffect(() => {
        let series = [];
        let categories = [];

        const mergeCategories = (resolved, received) => {
            const allDatesSet = new Set([...resolved, ...received]);
            const allDates = Array.from(allDatesSet).sort((a, b) => new Date(a) - new Date(b));
            return allDates;
        };

        const alignData = (dates, allDates, data) => {
            const dataMap = dates?.reduce((acc, date, index) => {
                acc[date] = data[index];
                return acc;
            }, {});
            return allDates?.map(date => dataMap[date] || 0);
        };
        if (queryType.length === 1 && queryType.includes("received")) {
            const processedData = processData(triageStatsAnalysis?.receivedDates, timeline);
            series = [
                {
                    name: "Received",
                    data: processedData.data
                }
            ];
            categories = processedData.categories;
        } else if (queryType.length === 1 && queryType.includes("resolved")) {
            const processedData = processData(triageStatsAnalysis?.resolvedDates, timeline);
            series = [
                {
                    name: "Resolved",
                    data: processedData.data
                }
            ];
            categories = processedData.categories;
        } else {
            const receivedData = processData(triageStatsAnalysis?.receivedDates, timeline);
            const resolvedData = processData(triageStatsAnalysis?.resolvedDates, timeline);

            if (timeline === null || timeline === undefined) {
                const allCategories = mergeCategories(resolvedData.categories, receivedData.categories);

                const alignedResolvedData = alignData(resolvedData.categories, allCategories, resolvedData.data);
                const alignedReceivedData = alignData(receivedData.categories, allCategories, receivedData.data);
                series = [
                    {
                        name: "Received",
                        data: alignedReceivedData
                    },
                    {
                        name: "Resolved",
                        data: alignedResolvedData
                    }
                ];
                categories = allCategories;
            } else {
                series = [
                    {
                        name: "Received",
                        data: receivedData.data
                    },
                    {
                        name: "Resolved",
                        data: resolvedData.data
                    }
                ];
                categories = receivedData.categories;
            }
        }

        setGraphSeries(series);
        setGraphCategories(categories);
    }, [triageStatsAnalysis, queryType]);

    const lineChartOptions = (series, categories) => {
        const data = series?.map((item, index) => ({
            x: categories,
            y: item.data,
            type: "line",
            name: item.name,
            showlegend: true,
            line: {
                shape: "spline",
                width: 4,
            },
            marker: { color: (queryType.length === 1 && queryType.includes("received")) ? "#007AFF" : (queryType.length === 1 && queryType.includes("resolved")) ? "#FF3B30" : index === 0 ? "#007AFF" : "#FF3B30" },
        }))
        return { data }
    }

    const barChartOptions = (series, categories) => {
        const data = series?.map((item, index) => ({
            x: categories,
            y: item.data,
            type: "bar",
            name: item.name,
            width: 0.8 - index * 0.2,
            showlegend: true,
            marker: { color: (queryType.length === 1 && queryType.includes("received")) ? "#007AFF" : (queryType.length === 1 && queryType.includes("resolved")) ? "#FF3B30" : index === 0 ? "#007AFF" : "#FF3B30" },
        }))
        return { data }
    }

    return (
        <Card
            title={<h4> Triage Statistics</h4>}
            extra={
                < div className="flex flex-col my-2 gap-2 sm:flex-row sm:gap-3 sm:justify-between sm:items-center" >
                    <RangePicker
                        value={range}
                        onChange={handleRangeChange}
                        format="MM/DD/YYYY"
                        disabledDate={current => current > moment()}
                        allowClear={true}
                    />
                    <div className="w-full flex justify-end items-center gap-3 sm:w-auto">
                        <div className="flex justify-start items-center gap-1">
                            Graphs: {" "}
                            <Select
                                placeholder="Select graph type"
                                variant="borderless"
                                size="small"
                                style={{ border: "0px", width: "125px" }}
                                defaultValue={graphType}
                                onChange={(value) => setGraphType(value)}
                                options={[
                                    { label: "Line Graphs", value: "line" },
                                    { label: "Bar Graphs", value: "bar" },
                                ]}
                            />
                        </div>
                        <Button
                            className="bg-primary text-[#fff]"
                            onClick={() => {
                                navigate(`/${selectedOrg?.id}/${selectedOrg?.name}/triage`)
                            }}
                        >
                            View Triage
                        </Button>
                    </div>
                </div >
            }
        >
            <div className="w-full flex flex-col justify-between items-end gap-3 md:flex-row md:justify-between md:items-center md:gap-3">
                <div className="flex justify-start items-center gap-3">
                    Timeline: {" "}
                    <div className="flex flex-wrap gap-2">
                        <Button
                            className={timeline === "10 min" ? activeTimelineStyle : defaultTimelineStyle}
                            value="10 min"
                            onClick={() => setTimeline("10 min")}
                        >
                            10 min
                        </Button>
                        <Button
                            className={timeline === "30 min" ? activeTimelineStyle : defaultTimelineStyle}
                            value="30 min"
                            onClick={() => setTimeline("30 min")}
                        >
                            30 min
                        </Button>
                        <Button
                            className={timeline === "1 Hr" ? activeTimelineStyle : defaultTimelineStyle}
                            value="1 Hr"
                            onClick={() => setTimeline("1 Hr")}
                        >
                            1 Hr
                        </Button>
                        <Button
                            className={timeline === "1 Day" ? activeTimelineStyle : defaultTimelineStyle}
                            value="1 Day"
                            onClick={() => setTimeline("1 Day")}
                        >
                            1 Day
                        </Button>
                        <Button
                            className={timeline === "1 week" ? activeTimelineStyle : defaultTimelineStyle}
                            value="1 week"
                            onClick={() => setTimeline("1 week")}
                        >
                            1 week
                        </Button>
                        <Button
                            className={timeline === "1 Month" ? activeTimelineStyle : defaultTimelineStyle}
                            value="1 Month"
                            onClick={() => setTimeline("1 Month")}
                        >
                            1 Month
                        </Button>
                        <Button
                            className={timeline === "1 Year" ? activeTimelineStyle : defaultTimelineStyle}
                            value="1 Year"
                            onClick={() => setTimeline("1 Year")}
                        >
                            1 Year
                        </Button>
                    </div>
                </div>
                <div className="flex gap-2 justify-end items-center">
                    Queries: {" "}
                    <Select
                        showSearch={false}
                        mode="multiple"
                        placeholder="Select Query"
                        variant="borderless"
                        size="small"
                        style={{ border: "0px", width: "auto", minWidth: "120px" }}
                        value={queryType}
                        onChange={(value) => value?.length === 0 ? setQueryType(["received"]) : setQueryType(value)}
                        options={[
                            { label: "Resolved", value: "resolved" },
                            { label: "Received", value: "received" },
                        ]}
                    />
                </div>
            </div>
            <div className="h-fit">
                {graphType === "line" ?
                    <Spin spinning={loading === true}>
                        <Plot
                            {...lineChartOptions(graphSeries, graphCategories)}
                            style={{ width: "100%", height: "400px" }}
                            layout={{
                                xaxis: {
                                    tickmode: "linear",
                                    dtick: timeline === "10 min" ? 0 : timeline === "30 min" ? 2 : timeline === "1 Hr" ? 5 : timeline === "1 Day" ? 0 : timeline === "1 week" ? 0 : timeline === "1 Month" ? 0 : timeline === "1 Year" ? 0 : 3
                                },
                                yaxis: {
                                    rangemode: "tozero"
                                },
                                legend: {
                                    x: queryType?.length === 2 ? 0.88 : 0.9,
                                    y: -0.1,
                                    traceorder: "normal",
                                    showForSingleSeries: true,
                                    orientation: "h",
                                    font: {
                                        family: "sans-serif",
                                        size: 12,
                                        color: "#000"
                                    },
                                },
                                barmode: "overlay",
                                shapes: {
                                    type: "circle",
                                },
                                margin: {
                                    l: 40,
                                    r: 10,
                                    t: 60,
                                    b: 40
                                },
                                autosize: true
                            }}
                            config={{
                                responsive: true,
                                aspectRatio: false,
                                displayModeBar: true,
                                displaylogo: false,
                                modeBarButtonsToRemove: ["toImage", "select2d", "lasso2d", "hoverClosestCartesian", "hoverCompareCartesian"],
                            }}
                        />
                    </Spin>
                    :
                    <Spin spinning={loading === true}>
                        <Plot
                            {...barChartOptions(graphSeries, graphCategories)}
                            style={{ width: "100%", height: "400px" }}
                            layout={{
                                xaxis: {
                                    tickmode: "linear",
                                    dtick: timeline === "10 min" ? 0 : timeline === "30 min" ? 2 : timeline === "1 Hr" ? 5 : timeline === "1 Day" ? 0 : timeline === "1 week" ? 0 : timeline === "1 Month" ? 0 : timeline === "1 Year" ? 0 : 3
                                },
                                yaxis: {
                                    rangemode: "tozero"
                                },
                                legend: {
                                    x: queryType?.length === 2 ? 0.88 : 0.9,
                                    y: -0.1,
                                    traceorder: "normal",
                                    showForSingleSeries: true,
                                    orientation: "h",
                                    font: {
                                        family: "sans-serif",
                                        size: 12,
                                        color: "#000"
                                    },
                                },
                                shapes: {
                                    type: "path",
                                },
                                barmode: "overlay",
                                margin: {
                                    l: 40,
                                    r: 10,
                                    t: 60,
                                    b: 40
                                },
                                autosize: true
                            }}
                            config={{
                                responsive: true,
                                aspectRatio: false,
                                displayModeBar: true,
                                displaylogo: false,
                                modeBarButtonsToRemove: ["toImage", "select2d", "lasso2d", "hoverClosestCartesian", "hoverCompareCartesian"],
                            }}
                        />
                    </Spin>
                }
            </div>
        </Card >
    )
}

export default TriageDashboardChart