import { Button, Checkbox, Divider, Modal } from "antd"
import React, { useEffect, useState } from "react"
import { CloseOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { postBulkMailMessagePatientAction } from "../../../redux/actions/selfOnboardingAction"

const INVITE_OPTIONS = Object.freeze({
  "Send Welcome Email": "welcome_mail",
  "Send Welcome Text Message": "welcome_message",
  "Send Introduction Email": "introduction_mail",
  "Send Introduction Text Message": "introduction_message"
})

const SendInviteModal = ({
  open,
  onClose,
  selectedPatientIds
}) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { loading: postLoading } = useSelector((state) => state.postBulkMessagePatient)

  const [selectedInviteOpt, setSelectedInviteOpt] = useState([])

  useEffect(() => {
    if (open) setSelectedInviteOpt([])
  }, [open])

  const handleConfirm = async () => {
    if (!selectedInviteOpt?.length || !selectedPatientIds?.length) return

    for (const msgType of selectedInviteOpt) {
      const formData = {
        patientIds: selectedPatientIds,
        messageType: INVITE_OPTIONS?.[msgType]
      }
      // @ts-expect-error
      const apiResPromise = dispatch(postBulkMailMessagePatientAction(formData))
      apiResPromise
        .then((apiRes) => {
          onClose()
          addToast(
            `${msgType?.replace(/send\s+/i, "")} sent to ${selectedPatientIds?.length} patient(s).`,
            {
              appearance: "success",
              autoDismiss: true
            }
          )
        })
        .catch((error) => {
          console.error("API Error:", error)
          addToast(res?.error, { appearance: "error", autoDismiss: true })
        })
    }
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      styles={{
        content: {
          borderRadius: 12,
          padding: 6,
          overflow: "hidden"
        }
      }}
      footer={null}
      centered
      closable={false}>
      <div className="p-3 text-[#fff] flex items-center gap-1 bg-current rounded-t-[12px]">
        <h1 className=" text-2xl flex-1 font-semibold">Send Invite</h1>
        <CloseOutlined className="cursor-pointer" onClick={onClose} style={{ fontSize: 22 }} />
      </div>
      <div className="p-3">
        <p className="text-lg font-semibold">
          How do you want to send invite to the selected patient?
        </p>
        <Checkbox.Group
          options={Object.keys(INVITE_OPTIONS)}
          className="mt-3 inline-flex flex-col gap-3 font-medium"
          value={selectedInviteOpt}
          onChange={setSelectedInviteOpt}
        />
      </div>
      <Divider className="mt-3" />
      <div className="px-2.5 pb-2.5 flex justify-end gap-3">
        <Button size="large" type="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          loading={postLoading}
          disabled={!selectedInviteOpt?.length}
          onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default SendInviteModal