import React from "react"
import { Modal, Button, Input } from "antd"

const HighRateYesAlertModal = props => {
  const {
    showHighRateYesAlert,
    setShowHighRateYesAlert,
    heartRateSubYesAlertQus,
    setHeartRateSubYesAlertQus
  } = props

  return (
    <Modal
      open={showHighRateYesAlert}
      onCancel={() => setShowHighRateYesAlert(false)}
      footer={null}
      title={<h4 className="text-xl">Note</h4>}>
      <div className="pt-4">
        <p className="font-medium">{heartRateSubYesAlertQus?.name}</p>
        <Input.TextArea
          value={heartRateSubYesAlertQus?.text}
          onChange={e =>
            setHeartRateSubYesAlertQus({ ...heartRateSubYesAlertQus, text: e.target.value })
          }
        />

        <div className="mt-4">
          <Button type="primary" onClick={() => setShowHighRateYesAlert(false)}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default HighRateYesAlertModal
