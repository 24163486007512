import React, {useState} from "react"

import { Input, Button, Typography } from "antd"
import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useDispatch, useSelector } from "react-redux"
import { forgetPasswordProviderAction, validateLoginDetailsAction } from "../../redux/actions/utilsActions"
import { useNavigate } from "react-router-dom"
import { Mixpanel } from "../../utils/mixpanel"

const schema = yup
  .object({
    credential: yup.string().required("Enter a Valid Email or Phone Number")
    .test('value-test', 'Enter a Valid Email or Phone Number', 
      function(value) {
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone ){
          return false;
        }
        return true;
      })

  })
  .required()

const defaultValues = {
  credential: ""
}

const CODE_SENT_SUCCESS_MSG = "Code Sent Successfully"
const CODE_SENT_FAIL_MSG = "Code Sent Failed"

const OTPForm = ({ setShowNext, setCredential, setUserName }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [error, setError] = useState(null)
  const [codeErrors, setCodeErrors] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async ({ credential }) => {
    setError(null)
    setLoading(true)
    const result = await dispatch(forgetPasswordProviderAction(credential))
    if (result?.message === "Success") {
      setShowNext(true)
      setUserName(credential)
      setCredential(credential)
      addToast(CODE_SENT_SUCCESS_MSG, {
        appearance: "success",
        autoDismiss: true
      })
    } else {
      setCodeErrors(result)
      addToast(CODE_SENT_FAIL_MSG, {
        appearance: "error",
        autoDismiss: true
      })
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!loading &&
        codeErrors?.message && codeErrors?.message !== "" && (
          <div
            style={{
              backgroundColor: "rgba(255, 59, 48, 0.06)",
              borderLeft: "2px solid rgba(255, 59, 48, .6)"
            }}
            className="px-3 py-2 my-4">
            <p>
              <strong>Note: </strong>
              {codeErrors?.message}
            </p>
          </div>
        )}
      {/* {!loading &&
        <Typography.Title level={5} className="mt-3 mb-4">
          {codeErrors && codeErrors?.message ? EXCEEDED_ATTEMPTS_MSG : null}
        </Typography.Title>
      } */}
      <div className="mb-4">
        <label className="mb-2" htmlFor="input-credential">
          Email or Phone Number
        </label>
        <Controller
          name="credential"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              size="large"
              id="input-credential"
              status={errors.credential ? "error" : undefined}
              {...field}
            />
          )}
        />
        {errors.credential ? (
          <small className="text-danger">{errors.credential.message}</small>
        ) : null}
      </div>
      <Button block size="large" type="primary" htmlType="submit" disabled={isSubmitting || codeErrors?.message?.includes("You have exceeded")} onClick={() => Mixpanel.track("Request OTP")}>
        Send Code
      </Button>
      <div className="mt-4">
      <small className="text-center cursor-pointer text-secondary" onClick={()=>navigate('/login')}>Back to Login</small>
      </div>
      
      {error !== null && typeof error === "string" && (
              <p className="text-danger text-center font-medium mt-4">{error}</p>
            )}

          {error !== null &&
            typeof error === "object" &&
            Object.values(error)?.map((value, index) => (
              <p key={index} className="font-bold text-danger text-center mt-4">
                <small>{value}</small>
              </p>
            ))}

    </form>
  )
}

export default OTPForm
