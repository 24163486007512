export const customUnitRoomSort = (a, b) => {
    // Check if both values are numeric
    const isANumeric = /^\d+$/.test(a);
    const isBNumeric = /^\d+$/.test(b);

    if (isANumeric && isBNumeric) {
      // If both are numeric, compare as numbers
      return parseInt(a) - parseInt(b);
    } else if (isANumeric) {
      // If only A is numeric, A comes first
      return -1;
    } else if (isBNumeric) {
      // If only B is numeric, B comes first
      return 1;
    } else {
      // If neither is numeric, compare as strings
      return a.localeCompare(b);
    }
  }