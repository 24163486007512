import { useEffect, useState } from "react"
import { PlusOutlined, StarOutlined, CloseCircleFilled, FileOutlined } from "@ant-design/icons"
import { Button, Card, Input, Avatar, Badge, Image } from "antd"
import { IoBookmarkOutline } from "react-icons/io5"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import {
  AddInternalNoteAction,
  EditInternalNoteAction
} from "../../redux/actions/internalNotesAction"
import { UploadFileAction } from "../../redux/actions/uploadFileAction"

const { TextArea } = Input

const getFormat = fileName => {
  const dotIdx = fileName?.lastIndexOf(".")
  if (dotIdx) {
    return fileName?.slice(dotIdx + 1)
  }
}

const isImage = fileName => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"]
  const extension = fileName?.split(".")?.pop()?.toLowerCase()
  return imageExtensions?.includes(extension)
}

const AddAndEditNote = ({ setIsAddNote, editNote, setEditNote }) => {
  const { patientId } = useParams()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { loading: addNoteLoading } = useSelector(state => state.addInternalNote)

  const { loading: editNoteLoading } = useSelector(state => state.editInternalNote)

  const { loading: uploadLoading } = useSelector(state => state.uploadFiles)

  const [fileList, setFileList] = useState([])

  const [data, setData] = useState({
    title: "",
    concern: "",
    bookMark: false,
    important: false,
    files: []
  })

  useEffect(() => {
    if (editNote) {
      setData(editNote)
    }
  }, [editNote])

  const displayError = errorMsg => {
    addToast(errorMsg, {
      appearance: "error",
      autoDismiss: true
    })
  }

  const displaySuccess = successMsg => {
    addToast(successMsg, {
      appearance: "success",
      autoDismiss: true
    })
  }

  const handleInput = e => {
    const name = e?.target?.name
    const value = e?.target?.value
    setData({ ...data, [name]: value })
  }

  const appendFile = e => {
    if (!e?.target?.files?.length) return

    const maxAllowedSize = 5 * 1024 * 1024
    const approved = [...e.target.files].filter(({ size }) => size <= maxAllowedSize)

    if (e.target.files.length !== approved.length)
      displayError("File size should be less than 5 mb!")

    setFileList([...fileList, ...approved])
  }

  const popFile = index => {
    const filteredFiles = [...fileList]
    filteredFiles.splice(index, 1)
    setFileList(filteredFiles)
  }

  const popFileUrl = index => {
    const filteredFiles = [...data?.files]
    filteredFiles.splice(index, 1)
    setData({ ...data, files: filteredFiles })
  }

  const onSave = async () => {
    if (data?.title === "") return displayError("Please type a Heading")
    else if (data?.concern === "") return displayError("Please type a note")

    let uploadedFileUrls = []

    if (fileList?.length) {
      const { isUploaded, error, fileUrls } = await dispatch(
        UploadFileAction({ userId: patientId, fileList, fileType: "concerns" })
      )
      if (isUploaded) {
        setData({ ...data, files: [...(data?.files || []), ...fileUrls] })
        setFileList([])
        uploadedFileUrls = fileUrls
      } else return displayError(error)
    }

    if (editNote) {
      const { isDone, error } = await dispatch(
        EditInternalNoteAction({
          title: data?.title,
          concern: data?.concern,
          bookMark: data?.bookMark,
          important: data?.important,
          files: [...(data?.files || []), ...uploadedFileUrls],
          date: data?.date,
          concernId: data?.concernId,
          patientId
        })
      )
      if (isDone) {
        displaySuccess("Successfully Edited!")
        setData({})
        setEditNote(false)
      } else displayError(error)
    } else {
      const { isDone, error } = await dispatch(
        AddInternalNoteAction({
          title: data?.title,
          concern: data?.concern,
          bookMark: data?.bookMark,
          important: data?.important,
          files: [...(data?.files || []), ...uploadedFileUrls],
          patientId
        })
      )
      if (isDone) {
        displaySuccess("Successfully Added!")
        setData({})
        setIsAddNote(false)
      } else displayError(error)
    }
  }

  return (
    <>
      {!editNote && (
        <div className="mb-7 flex justify-between items-center">
          <h4 className="text-2xl">Add Internal Notes</h4>
          <Button onClick={() => setIsAddNote(false)}>Go Back</Button>
        </div>
      )}
      <form className="add-edit-note-form">
        <Card
          bodyStyle={{ padding: 10 }}
          className={`shadow-sm ${editNote ? "border-current" : "border"}`}>
          <div className="flex justify-between gap-2 mb-2">
            <Input
              value={data?.title}
              name="title"
              onChange={handleInput}
              placeholder="Heading"
              className="font-semibold"
            />
            <div className="flex justify-center items-center gap-2">
              <Button
                size="small"
                type={data?.bookMark ? "primary" : "default"}
                shape="circle"
                className={`flex justify-center items-center border-[#d7a66d] ${!data?.bookMark && "text-current"
                  }`}
                onClick={() => setData({ ...data, bookMark: !data?.bookMark })}>
                <IoBookmarkOutline />
              </Button>
              <Button
                size="small"
                type={data?.important ? "primary" : "default"}
                shape="circle"
                className={`flex justify-center items-center border-[#d7a66d] ${!data?.important && "text-current"
                  }`}
                onClick={() => setData({ ...data, important: !data?.important })}>
                <StarOutlined />
              </Button>
            </div>
          </div>

          <TextArea
            name="concern"
            value={data?.concern}
            onChange={handleInput}
            placeholder="Add notes here..."
            className="text-secondary"
          />

          <hr className="my-3 bg-[#DFDFDF]" />

          {(fileList?.length > 0 || data?.files?.length > 0) && (
            <div className="mx-3 mb-3 flex flex-wrap gap-3">
              {data?.files?.map((file, idx) => (
                <Badge
                  key={idx}
                  count={
                    <CloseCircleFilled
                      onClick={() => popFileUrl(idx)}
                      style={{ color: "#7E7E7E" }}
                    />
                  }>
                  <Card bodyStyle={{ padding: "4px 10px", width: "200px" }}>
                    <div className="flex gap-2 items-center">
                      {isImage(file?.split("$")?.[1]) ? (
                        <Image width={32} src={file} />
                      ) : (
                        <Avatar shape="square" className="bg-current" icon={<FileOutlined />} />
                      )}
                      <div>
                        <p className="font-semibold mb-[-5px] truncate w-[140px]">
                          {file?.split("$")?.[1]}
                        </p>
                        <small className="uppercase text-secondary">
                          {getFormat(file?.split("$")?.[1])}
                        </small>
                      </div>
                    </div>
                  </Card>
                </Badge>
              ))}

              {fileList?.map((file, idx) => (
                <Badge
                  key={idx}
                  count={
                    <CloseCircleFilled onClick={() => popFile(idx)} style={{ color: "#7E7E7E" }} />
                  }>
                  <Card bodyStyle={{ padding: "4px 10px", width: "200px" }}>
                    <div className="flex gap-2 items-center">
                      {file?.type?.split("/")?.[0] === "image" ? (
                        <Image width={32} src={URL.createObjectURL(file)} />
                      ) : (
                        <Avatar shape="square" className="bg-current" icon={<FileOutlined />} />
                      )}

                      <div>
                        <p className="font-semibold mb-[-5px] truncate w-[140px]">{file?.name}</p>
                        <small className="uppercase text-secondary">{getFormat(file?.name)}</small>
                      </div>
                    </div>
                  </Card>
                </Badge>
              ))}
            </div>
          )}

          <div className="my-2 flex gap-2 justify-end items-end">
            <div className="flex-1">
              <Button size="large" type="ghost">
                <label
                  htmlFor="upload-file"
                  className="cursor-pointer flex justify-center gap-2 items-center">
                  <Avatar size={23} className="bg-[#EFEFEF]">
                    <PlusOutlined style={{ color: "#373737" }} />
                  </Avatar>
                  Add Attachments
                </label>
              </Button>
              <input
                type="file"
                id="upload-file"
                multiple
                onChange={appendFile}
                style={{ display: "none" }}
              />
            </div>

            {editNote && <Button onClick={() => setEditNote(false)}>Back</Button>}

            <Button
              type="primary"
              className="w-[100px]"
              onClick={onSave}
              loading={uploadLoading || addNoteLoading || editNoteLoading}>
              Save
            </Button>
          </div>
        </Card>
      </form>
    </>
  )
}

export default AddAndEditNote
