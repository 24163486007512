import React from "react"

const TriageHeader = () => {
  return (
    <div className="rounded shadow m-3">
      <h4 className="fw-light my-2 mx-3">Patient Details</h4>
      <div className="row justify-content-end mx-0 p-3">
        <div className="col-md-6">
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Date of Service : </p>
            <p className="fw-bold mb-0">10 jan 2023</p>
          </div>
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Patient Name : </p>
            <p className="fw-bold mb-0">Test Patient Name</p>
          </div>
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Patient DOB : </p>
            <p className="fw-bold mb-0">10 jan 2023</p>
          </div>
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Caretaker : </p>
            <p className="fw-bold mb-0">Test Patient Caretaker</p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Insurance Company : </p>
            <p className="fw-bold mb-0">Test Company</p>
          </div>
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Insurance Number : </p>
            <p className="fw-bold mb-0">Test Number</p>
          </div>
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Diagnostic (ICD) codes : </p>
            <p className="fw-bold mb-0">a123, b123, c123</p>
          </div>
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Enrollment/Education Date : </p>
            <p className="fw-bold mb-0">T10 jan 2023</p>
          </div>
          <div className="d-flex flex-wrap">
            <p className="fw-light mb-0">Provider : </p>
            <p className="fw-bold mb-0">Test Patient Provider</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TriageHeader
