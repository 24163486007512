import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import moment from "moment"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { Modal, Button, Input } from "antd"
import makeAnimated from "react-select/animated"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { getCareTypesAction } from "../../../redux/actions/caretakerActions"
import { updateManualTimeAction } from "../../../redux/actions/patientAction"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ADD_MANUAL_TIME_RESET } from "../../../redux/types/PatientConstant"

const schema = yup
  .object({
    care: yup.array().required(),
    date: yup.string().required(),
    program: yup.object().required(),
    notes: yup.string().required("Note is a required field"),
    duration: yup.string().required("Duration is a required field")
  })
  .required()

const UpdateManualTime = props => {
  const { selectedLog, setSelectedLog, showUpdateManualTime, setShowUpdateManualTime } = props

  const patientID = useParams()
  const animatedComponents = makeAnimated()

  const dispatch = useDispatch()
  const { careTypes } = useSelector(state => state.careTypes)
  const { loading, error: postError } = useSelector(state => state.addManualTime)
  const { addToast } = useToasts()
  const [isModified, setIsModified] = useState(false); // Track if the form is modified
  const [careOptions, setCareOptions] = useState([])

  const {
    reset,
    control,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitted, touchedFields }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const formValues = watch(); // Get current form values

  useEffect(() => {
    if (selectedLog) {
      dispatch(getCareTypesAction(patientID.id))
      setValue(
        "care",
        selectedLog?.care?.map(val => ({ value: val, label: val }))
      )
      setValue("program", {
        value: selectedLog?.program[0],
        label: selectedLog?.program[0].toUpperCase()
      })
      setValue("notes", selectedLog?.note)
      setValue("duration", selectedLog?.careTime)
      setValue("date", new Date(selectedLog?.careDate))
      if (careTypes && careTypes.length) {
        const ct = careTypes?.find(
          i => i.monitorType.toLowerCase() === selectedLog?.program[0]?.toLowerCase()
        )
        setCareOptions(
          ct?.caretypes?.map((opt, index) => ({
            value: opt,
            label: opt
          }))
        )
      }
    }
  }, [showUpdateManualTime])

  useEffect(() => {
    const data = getValues()
    if (isSubmitted) {
      if (touchedFields) {
        if (data.care.length === 0 || Object.keys(data.program).length === 0) {
          if (Object.keys(data.program).length === 0)
            setError("care", { type: "custom", message: "Please select a Care" })
          if (data.care.length === 0)
            setError("program", { type: "custom", message: "Please select a Program" })
        }
      }
    }
  }, [isSubmitted, touchedFields])

  useEffect(() => {
    const data = getValues()
    // Prepare the values for comparison
    const currentProgram = data?.program?.label?.toLowerCase()
    const currentCare = data?.care?.map(c => c?.label?.toLowerCase())
    const selectedProgram = selectedLog?.program?.[0]?.toLowerCase()
    const selectedCare = selectedLog?.care?.map(c => c?.toLowerCase())

    // Compare values
    const isDataAltered =
      currentProgram !== selectedProgram ||
      !arraysEqual(currentCare, selectedCare) ||
      Number(data.duration ?? 0) !== Number(selectedLog.careTime ?? 0) ||
      data.notes !== selectedLog.note ||
      !moment(data.date).isSame(moment(selectedLog.careDate))

    setIsModified(isDataAltered)
  }, [formValues])

  // Utility function to compare arrays
  const arraysEqual = (a, b) => {
    if (a?.length !== b?.length) return false
    return a?.every((value, index) => value === b?.[index])
  }

  const updateCareOptions = programValue => {
    setCareOptions([])
    setValue("care", null)
    const ct = careTypes?.find(
      i => i.monitorType.toLowerCase() === programValue?.label.toLowerCase()
    )
    setCareOptions(
      ct?.caretypes?.map((opt, index) => ({
        value: opt,
        label: opt
      }))
    )
  }

  const handleClose = () => {
    dispatch({type: ADD_MANUAL_TIME_RESET})
    reset()
    clearErrors()
    setShowUpdateManualTime(false)
    setSelectedLog(false)
  }

  const onSubmit = async data => {
    if (data.care.length === 0 || Object.keys(data.program).length === 0) {
      if (Object.keys(data.program).length === 0)
        setError("program", { type: "custom", message: "Please select a Care" })
      if (data.care.length === 0)
        setError("care", { type: "custom", message: "Please select a Program" })
    } else {
      const logDate = new Date(data.date)
      logDate.setTime(logDate.getTime() - logDate.getTimezoneOffset() * 60000)
      const dateAsString = logDate.toISOString().substr(0, 19)
      dispatch(
        updateManualTimeAction(
          patientID?.id,
          selectedLog?.id,
          data.program?.label.toLowerCase(),
          data.care.map(value => value?.label),
          dateAsString,
          parseFloat(data.duration),
          data.notes
        )
      ).then(res => {
        if (res && res.message.toLowerCase() === "success") {
          handleClose()
          addToast("Nursing Note Updated", {
            appearance: "success",
            autoDismiss: true
          })
        }
      })
    }
  }

  return (
    <Modal
      footer={null}
      onCancel={handleClose}
      open={showUpdateManualTime}
      title={<h4 className="text-xl">Update Nursing Note</h4>}>
      <div className="pt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label htmlFor="select-program" className="mb-2">
              Select Program <span className="text-danger">*</span>
            </label>
            <Controller
              name="program"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  id="select-program"
                  placeholder="Select Program"
                  components={animatedComponents}
                  value={field.value}
                  onChange={val => {
                    field.onChange(val)
                    updateCareOptions(val)
                  }}
                  options={careTypes?.map((opt, index) => ({
                    value: index,
                    label: opt?.monitorType?.toUpperCase()
                  }))}
                />
              )}
            />
            {errors.program ? (
              <small className="text-danger">{errors.program.message}</small>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="select-care" className="mb-2">
              Select Care <span className="text-danger">*</span>
            </label>
            <Controller
              name="care"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  isMulti
                  id="select-care"
                  value={field.value}
                  options={careOptions}
                  components={animatedComponents}
                  placeholder="Select Care Type"
                  onChange={val => field.onChange(val)}
                />
              )}
            />
            {errors.care ? <small className="text-danger">{errors.care.message}</small> : null}
          </div>

          <div className="mb-3">
            <label htmlFor="date-and-time" className="mb-2">
              Date and Time <span className="text-danger">*</span>
            </label>
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  id="date-and-time"
                  selected={field.value}
                  onChange={date => {
                    if (date) {
                      field.onChange(date)
                    } else {
                      field.onChange(new Date())
                    }
                  }}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  maxDate={
                    moment(selectedLog?.userDate).add(30, "days").isBefore(moment())
                      ? moment(selectedLog?.userDate).add(30, "days").toDate()
                      : moment().toDate()
                  }
                  showPopperArrow={false}
                  placeholderText="Select Date and Time"
                  minDate={moment(selectedLog?.userDate).subtract(30, "days")._d} //eslint-disable-line
                  className="border-[1px] px-3 min-h-[50px] rounded w-full border-[#d9d9d9]"
                  withPortal
                />
              )}
            />
            {errors.date ? <small className="text-danger">{errors.date.message}</small> : null}
          </div>

          <div className="mb-3">
            <label htmlFor="duration" className="mb-2">
              Duration (in Minutes) <span className="text-danger">*</span>
            </label>
            <Controller
              name="duration"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input size="large" type="number" min={1} id="duration" {...field} />
              )}
            />
            {errors.duration ? (
              <small className="text-danger">{errors.duration.message}</small>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="notes" className="mb-2">
              Notes <span className="text-danger">*</span>
            </label>
            <Controller
              name="notes"
              control={control}
              rules={{ required: true }}
              placeholder="Enter Notes"
              render={({ field }) => <Input.TextArea rows={3} id="notes" {...field} />}
            />
            {errors.notes ? <small className="text-danger">{errors.notes.message}</small> : null}
          </div>
          {postError ? (
              <div className="my-2">
                <p className="text-danger font-semibold">{postError}</p>
              </div>
            ) : null}
          <div>
            <Button type="primary" htmlType="submit" disabled={loading || !isModified}>
              {loading ? "Updating..." : "Update"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default UpdateManualTime
