import {
  GET_ALL_ORG_FAIL,
  GET_ALL_ORG_REQUEST,
  GET_ALL_ORG_SUCCESS
} from "../types/orgDetailsConstant"

const initialState = {
    orgData: [],
    loading: false
  }
export const getAllOrgReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORG_REQUEST:
      return { loading: true }
    case GET_ALL_ORG_SUCCESS:
      return { loading: false, orgData: action.payload }
    case GET_ALL_ORG_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
