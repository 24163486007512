import React from "react"
import { Modal, Button } from "antd"

const ThirdProgNoAlertModal = ({ showModal, setShowModal }) => {
  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      title={<h4 className="text-xl">Note</h4>}>
      <div className="pt-4">
        <p className="font-medium">
          The answers to these questions will help you and the patient better understand why they
          may be experiencing low oxygen saturation levels. Along the way, educate the patient to
          make healthy lifestyle changes and incorporate any appropriate nursing
          interventions/recommendations related to improving oxygen levels. Some lifestyle
          modifications for improving oxygen levels include: healthy diet, daily exercise, weight
          loss, avoid smoking, monitor oxygen levels closely, take pulmonary medication as
          prescribed.
        </p>

        <Button className="mt-4" type="primary" onClick={() => setShowModal(false)}>
          Ok
        </Button>
      </div>
    </Modal>
  )
}

export default ThirdProgNoAlertModal
