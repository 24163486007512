// ccm
export const GET_PCM_REQUEST = "GET_PCM_REQUEST"
export const GET_PCM_SUCCESS = "GET_PCM_SUCCESS"
export const GET_PCM_FAIL = "GET_PCM_FAIL"
export const GET_PCM_RESET = "GET_PCM_RESET"

export const GET_PCM_CAREPLAN_REQUEST = "GET_PCM_CAREPLAN_REQUEST"
export const GET_PCM_CAREPLAN_SUCCESS = "GET_PCM_CAREPLAN_SUCCESS"
export const GET_PCM_CAREPLAN_FAIL = "GET_PCM_CAREPLAN_FAIL"

export const GET_LATEST_PCM_CAREPLAN_REQUEST = "GET_LATEST_PCM_CAREPLAN_REQUEST"
export const GET_LATEST_PCM_CAREPLAN_SUCCESS = "GET_LATEST_PCM_CAREPLAN_SUCCESS"
export const GET_LATEST_PCM_CAREPLAN_FAIL = "GET_LATEST_PCM_CAREPLAN_FAIL"

export const POST_PCM_USER_REQUEST = "POST_PCM_USER_REQUEST"
export const POST_PCM_USER_SUCCESS = "POST_PCM_USER_SUCCESS"
export const POST_PCM_USER_FAIL = "POST_PCM_USER_FAIL"

export const GET_PCM_ARCHIVE_PLANS_REQUEST = "GET_PCM_ARCHIVE_PLANS_REQUEST"
export const GET_PCM_ARCHIVE_PLANS_SUCCESS = "GET_PCM_ARCHIVE_PLANS_SUCCESS"
export const GET_PCM_ARCHIVE_PLANS_FAIL = "GET_PCM_ARCHIVE_PLANS_FAIL"

export const POST_PCM_FOLLOW_UP_REQUEST = "POST_PCM_FOLLOW_UP_REQUEST"
export const POST_PCM_FOLLOW_UP_SUCCESS = "POST_PCM_FOLLOW_UP_SUCCESS"
export const POST_PCM_FOLLOW_UP_FAIL = "POST_PCM_FOLLOW_UP_FAIL"

export const UPDATE_PCM_PLAN_REQUEST = "UPDATE_PCM_PLAN_REQUEST"
export const UPDATE_PCM_PLAN_SUCCESS = "UPDATE_PCM_PLAN_SUCCESS"
export const UPDATE_PCM_PLAN_FAIL = "UPDATE_PCM_PLAN_FAIL"