import {
  POST_UPLOAD_FILES_REQUEST,
  POST_UPLOAD_FILES_SUCCESS,
  POST_UPLOAD_FILES_FAIL
} from "../types/uploadFileConstant"

export const UploadFilesReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_UPLOAD_FILES_REQUEST:
      return { loading: true }
    case POST_UPLOAD_FILES_SUCCESS:
      return { loading: false, uploadedFiles: action.payload }
    case POST_UPLOAD_FILES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
