import {
    GET_PCM_FAIL,
    GET_PCM_SUCCESS,
    GET_PCM_REQUEST,
    GET_LATEST_PCM_CAREPLAN_REQUEST,
    GET_LATEST_PCM_CAREPLAN_SUCCESS,
    GET_LATEST_PCM_CAREPLAN_FAIL,
    POST_PCM_USER_REQUEST,
    POST_PCM_USER_SUCCESS,
    POST_PCM_USER_FAIL,
    GET_PCM_ARCHIVE_PLANS_REQUEST,
    GET_PCM_ARCHIVE_PLANS_SUCCESS,
    GET_PCM_ARCHIVE_PLANS_FAIL,
    POST_PCM_FOLLOW_UP_REQUEST,
    POST_PCM_FOLLOW_UP_SUCCESS,
    POST_PCM_FOLLOW_UP_FAIL,
    UPDATE_PCM_PLAN_REQUEST,
    UPDATE_PCM_PLAN_SUCCESS,
    UPDATE_PCM_PLAN_FAIL,
    GET_PCM_CAREPLAN_REQUEST,
    GET_PCM_CAREPLAN_SUCCESS,
    GET_PCM_CAREPLAN_FAIL
} from "../types/pcmConstant";
import { axiosGet, axiosPost } from "../axios"
import { userLogoutAction } from "./userActions"
import moment from "moment"


export const getpcmDataActions = ({ caretakerId, pcmMonitorId, orgId }) => async dispatch => {
    try {
        dispatch({
            type: GET_PCM_REQUEST
        })

        const { data } = await axiosGet(`/api/cs/patients/principalCareplan/caretaker/${caretakerId}/monitoring/${pcmMonitorId}/org/${orgId}/fetch`)

        dispatch({
            type: GET_PCM_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PCM_FAIL,
            payload: error.response && error?.response?.data?.detail
        })
    }
}

export const postPcmPlan = ({ patientId, pcmMonitorId, formInputs }) => async dispatch => {
    try {
        dispatch({
            type: POST_PCM_USER_REQUEST
        })

        const { data } = await axiosPost(`/api/pcm/careplan/principalCare/${pcmMonitorId}/patient/${patientId}/create`, {
            planDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
            plan: formInputs
        })

        dispatch({
            type: POST_PCM_USER_SUCCESS,
            payload: data
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: POST_PCM_USER_FAIL,
            payload:
                error.response && error.response.data.detail ? error.response.data.detail : error.detail
        })
    }
}

export const updatePcmPlanActions = ({ patientId, planId, formInputs }) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_PCM_PLAN_REQUEST
        })

        const { data } = await axiosPost(`/api/pcm/careplan/principalCare/${patientId}/update/${planId}`, {
            planDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
            plan: formInputs
        })

        dispatch({
            type: UPDATE_PCM_PLAN_SUCCESS,
            payload: data
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: UPDATE_PCM_PLAN_FAIL,
            payload:
                error.response && error.response.data.detail ? error.response.data.detail : error.detail
        })
    }
}

export const getLatestPcmCareplanActions = ({ patientId }) => async dispatch => {
    try {
        dispatch({
            type: GET_LATEST_PCM_CAREPLAN_REQUEST
        })
        const { data } = await axiosGet(`/api/pcm/careplan/principalCare/latest/${patientId}/fetch`)

        dispatch({
            type: GET_LATEST_PCM_CAREPLAN_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_LATEST_PCM_CAREPLAN_FAIL,
            payload: error.response && error?.response?.data?.detail
        })
    }
}

export const getpcmCareplanDataActions = (patientId) => async dispatch => {
    try {
        dispatch({
            type: GET_PCM_CAREPLAN_REQUEST
        })
        const { data } = await axiosGet(`/api/pcm/careplan/principalCare/${patientId}/fetch`)

        dispatch({
            type: GET_PCM_CAREPLAN_SUCCESS,
            payload: data.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PCM_CAREPLAN_FAIL,
            payload: error.response && error?.response?.data?.details
        })
    }
}

export const getPcmArchivePlansActions = ({ patientId, startDate, endDate }) => async dispatch => {
    try {
        dispatch({
            type: GET_PCM_ARCHIVE_PLANS_REQUEST
        })
        const { data } = await axiosPost(`/api/pcm/careplan/principalCare/${patientId}/archive`, { startDate, endDate })

        dispatch({
            type: GET_PCM_ARCHIVE_PLANS_SUCCESS,
            payload: data.details
        })
        return data.details
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PCM_ARCHIVE_PLANS_FAIL,
            payload: error.response && error?.response?.data?.details
        })
    }
}

export const postPcmFollowUpPlanActions = ({ previousPlanId, patientId, ccmProgramId, plan }) => async dispatch => {
    try {
        dispatch({
            type: POST_PCM_FOLLOW_UP_REQUEST
        })

        const { data } = await axiosPost(`/api/pcm/careplan/principalCare/${ccmProgramId}/followup/${patientId}/create`, {
            previousPlanId,
            planDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
            plan
        })

        dispatch({
            type: POST_PCM_FOLLOW_UP_SUCCESS,
            payload: data
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: POST_PCM_FOLLOW_UP_FAIL,
            payload:
                error.response && error.response.data.details ? error.response.data.details : error.detail
        })
    }
}