import React, { useState } from "react"
import { Avatar, Skeleton } from "antd"

const ProfileAvatar = ({ user, size = 100 }) => {
  const [loadingImg, setLoadingImg] = useState(true)

  const onImageLoadHandler = () => {
    setLoadingImg(false)
  }

  const renderInitials = () => {
    if (!user) return null

    const firstInitial = user?.firstName?.[0] || ""
    const middleInitial = user?.middleName ? user?.middleName[0] : ""
    const lastInitial = user?.lastName?.[0] || ""

    return `${firstInitial}${middleInitial}${lastInitial}`.toUpperCase()
  }

  return (
    <>
      {user?.image ? (
        <Avatar
          size={size}
          src={
            <>
              {loadingImg && (
                <div className="rounded-full">
                  <Skeleton.Image width={200} active={true} />
                </div>
              )}
              <img alt="user profile" src={user?.image} onLoad={onImageLoadHandler} />
            </>
          }
          className=""
        />
      ) : (
        <Avatar size={size} className="mr-2 flex items-center bg-[#ebf4ff] text-primary">
          <span className="font-bold text-3xl">
            {renderInitials()}
          </span>
        </Avatar>
      )}
    </>
  )
}

export default ProfileAvatar