import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons"
import DotImg from "../assets/dot.svg"

export const isMin12Chars = password => password?.length >= 12 ?? false
export const isMinOneSplChar = password => /[^\w\s]/.test(password) ?? false
export const isMinOneNum = password => /\d/.test(password) ?? false

const PasswordValidation = ({ password }) => {
  return (
    <div style={{ color: "#666B7B", marginTop: 5 }}>
      <p style={{ fontSize: 14 }}>Password must contain minimum</p>
      <div style={{ fontSize: 12, marginTop: 4, display: "flex", flexDirection: "column", gap: 2 }}>
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          {!password?.length ? (
            <img src={DotImg} alt="dot" style={{ width: 8, height: 8 }} />
          ) : isMin12Chars(password) ? (
            <CheckCircleFilled style={{ color: "#52c41a" }} />
          ) : (
            <ExclamationCircleFilled style={{ color: "#FF3B30" }} />
          )}
          <span>12 characters.</span>
        </div>
        <div>
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            {!password?.length ? (
              <img src={DotImg} alt="dot" style={{ width: 8, height: 8 }} />
            ) : isMinOneSplChar(password) ? (
              <CheckCircleFilled style={{ color: "#52c41a" }} />
            ) : (
              <ExclamationCircleFilled style={{ color: "#FF3B30" }} />
            )}
            <span>1 special character.</span>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            {!password?.length ? (
              <img src={DotImg} alt="dot" style={{ width: 8, height: 8 }} />
            ) : isMinOneNum(password) ? (
              <CheckCircleFilled style={{ color: "#52c41a" }} />
            ) : (
              <ExclamationCircleFilled style={{ color: "#FF3B30" }} />
            )}
            <span>1 numeric character.</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordValidation
