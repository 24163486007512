import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import { Button, Collapse, Checkbox } from "antd"
import { useToasts } from "react-toast-notifications"
// import { UpOutlined, DownOutlined } from "@ant-design/icons"

import { useDispatch, useSelector } from "react-redux"
import {
  updateCcmPlanActions,
  getLatestCareplanActions
} from "../../../../../redux/actions/ccmActions"

import CCMEditFormInput from "./CCMEditFormInput"
import Loading from "../../../../layouts/Loading/Loading"
import HandleApiFail from "../../../../layouts/APIFail/ApiFail"
import { useNavigate } from "react-router-dom"
import { convertCareplanToV2 } from "../../../../../utils/careplanVersioning"

const { Panel } = Collapse

const CCMEditMain = () => {
  const navigate = useNavigate()
  const [medicalHistory, setMedicalHistory] = useState([])
  const [medication, setMedication] = useState([])
  const [currentStatus, setCurrentStatus] = useState([])
  const [chronicCondition, setChronicCondition] = useState([])
  const [conclusion, setConclusion] = useState([])
  const [showUpIconUpdate, setShowUpIconUpdate] = useState(true)
  const [showDownIconUpdate, setShowDownIconUpdate] = useState(true)

  const [medicalDisabled, setMedicalDisabled] = useState(true)
  const [medicationDisabled, setMedicationDisabled] = useState(true)
  const [currentDisabled, setCurrentDisabled] = useState(true)
  const [chronicDisabled, setChronicDisabled] = useState(true)
  const [conclusionDisabled, setConclusionDisabled] = useState(true)

  const [enableChronic, setEnableChronic] = useState(false)
  const [visibleUpdate, setVisibleUpdate] = useState(false)

  const [carePlanData, setCarePlanData] = useState()
  const [planId, setPlanId] = useState("")

  const { id: patientId, orgId, orgName } = useParams()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { loading: ccmLoading, latestCareplan } = useSelector(state => state.getLatestCareplan)
  const { loading: updatePlanLoading } = useSelector(state => state.updateCcmPlan)

  useEffect(() => {
    if (patientId !== undefined) {
      dispatch(getLatestCareplanActions({ patientId }))
    }
  }, [dispatch, patientId])

  useEffect(() => {
    if (latestCareplan) {
      setPlanId(latestCareplan?.id)
      if (latestCareplan?.plan?.body) {
        setCarePlanData({
          ...latestCareplan?.plan,
          body: convertCareplanToV2(latestCareplan?.plan)
        })
      } else {
        setCarePlanData({ body: convertCareplanToV2(latestCareplan?.plan) })
      }
    }
  }, [latestCareplan])

  useEffect(() => {
    if (carePlanData !== undefined && carePlanData?.body) {
      const medicalHistoryData = carePlanData?.body.filter(
        value => value?.ccmType === "MEDICAL HISTORY"
      )
      const medicationData = carePlanData?.body.filter(value => value?.ccmType === "MEDICATIONS")
      const currentStatusData = carePlanData?.body.filter(
        value => value?.ccmType === "CURRENT STATUS"
      )
      const chronicConditionData = carePlanData?.body.filter(
        value => value?.ccmType === "CHRONIC CONDITIONS"
      )
      const conclusionData = carePlanData?.body.filter(value => value?.ccmType === "CONCLUSION")
      setMedicalHistory(medicalHistoryData)
      setMedication(medicationData)
      setCurrentStatus(currentStatusData)
      setChronicCondition(chronicConditionData)
      setConclusion(conclusionData)
    }
  }, [carePlanData])

  // let navigate = useNavigate();
  // const { addToast } = useToasts();

  const [formInputs] = useState([])
  const [otherInputs, setOtherInputs] = useState({})
  // const [other, setOther] = useState()
  // const [ccmTypeProgress, setCCMTypeProgress] = useState([]);
  // const [conclusionPage, setConclusionPage] = useState(1);

  const handleChange = (id, e, Ques, index = 0) => {
    // console.log("handleChange", id, e.target.value, Ques, index)
    const newState = [...carePlanData?.body]

    const stateIndex = newState.findIndex(
      x => x?.ccmType === Ques?.ccmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    if (newState[stateIndex].value === undefined) {
      newState[stateIndex].value = []
    }

    switch (Ques?.quesType) {
      case "multi":
        if (index === "other") {
          // console.log('index is other')
          newState[stateIndex].value[newState[stateIndex].questionaryOptions?.length + 1] !==
          undefined
            ? (newState[stateIndex].value[
                newState[stateIndex].questionaryOptions?.length + 1
              ].other = e.target.value)
            : (newState[stateIndex].value[newState[stateIndex].questionaryOptions?.length + 1] = {
                other: e.target.value
              })
        } else {
          // console.log('not other')
          newState[stateIndex].value[index] = e.target.checked
        }
        break
      case "text-multi":
        if (newState[stateIndex].value !== undefined) {
          newState[stateIndex].value[index] = e.target.value
        }
        break
      case "bool":
        if (newState[stateIndex].value === undefined) {
          newState[stateIndex].value = true
        } else {
          newState[stateIndex].value = e.target.value === "true"
        }
        break
      case "bool-text":
      case "bool-text-multi":
        if (newState[stateIndex].value === undefined) {
          newState[stateIndex].value = true
        } else {
          newState[stateIndex].value = e.target.value === "true"
        }
        break
      default:
        newState[stateIndex].value = e.target.value
        break
    }

    const updateContent = { ...carePlanData, body: newState }
    setCarePlanData(updateContent)
  }

  const handleInput = (id, e, Ques, innerId, innerQues, index, position = 0) => {
    // console.log('handleInput',Ques)
    const newState = [...carePlanData?.body]
    // console.log(newState)
    const stateIndex = newState.findIndex(
      x => x?.ccmType === Ques?.ccmType && x?.name === Ques?.name
    )

    if (newState[stateIndex].quesType === "bool-text") {
      if (newState[stateIndex].questionaryOptions[0].value === undefined) {
        newState[stateIndex].questionaryOptions[0].value = ""
      } else {
        newState[stateIndex].questionaryOptions[index].value = e.target.value
      }
    }
    if (newState[stateIndex].quesType === "bool-text-multi") {
      if (newState[stateIndex].questionaryOptions[0].value === undefined) {
        newState[stateIndex].questionaryOptions[0].value = []
      } else {
        newState[stateIndex].questionaryOptions[0].value[index] = e.target.value
      }
    }

    const updateContent = { ...carePlanData, body: newState }
    setCarePlanData(updateContent)
  }

  const handleAddInputField = (id, Ques, inner = false, index = 0) => {
    const newState = [...carePlanData?.body]

    const stateIndex = newState.findIndex(
      x => x?.ccmType === Ques?.ccmType && x?.name === Ques?.name
    )

    if (newState[stateIndex].quesType === "text-multi") {
      if (newState[stateIndex].value === undefined) {
        newState[stateIndex].value = [""]
      } else {
        newState[stateIndex].value.push("")
      }
    }
    if (newState[stateIndex].quesType === "bool-text-multi") {
      if (newState[stateIndex].questionaryOptions[0].value === undefined) {
        newState[stateIndex].questionaryOptions[0].value = [""]
      } else {
        newState[stateIndex].questionaryOptions[0].value.push("")
      }
    }

    const updateContent = { ...carePlanData, body: newState }
    setCarePlanData(updateContent)
  }

  const handleApplicableChange = (id, e, Ques) => {
    const newState = [...carePlanData?.body]

    const findIndex = newState.findIndex(
      x => x?.ccmType === Ques?.ccmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].notApplicable = e.target.checked

    const updateContent = { ...carePlanData, body: newState }
    setCarePlanData(updateContent)
  }

  const submitForm = async () => {
    const confirm = window.confirm("Are you sure to confirm the plan ?")
    if (!confirm) return

    const updateCarePlan = await dispatch(
      updateCcmPlanActions({ patientId, planId, formInputs: carePlanData })
    )
    if (updateCarePlan?.status === 200) {
      // setFormInputs(questions);
      addToast("Care Plan Updated Successfully", { appearance: "success", autoDismiss: true })
      navigate(`/${orgId}/${orgName}/ccm/patients`)
    } else {
      addToast("Something went wrong, Try again!", { appearance: "error", autoDismiss: true })
    }
  }

  const handleMedical = () => {
    if (medicalDisabled) {
      setMedicalDisabled(false)
    } else {
      setMedicalDisabled(true)
    }
  }
  const handleMedication = () => {
    if (medicationDisabled) {
      setMedicationDisabled(false)
    } else {
      setMedicationDisabled(true)
    }
  }
  const handleCurrent = () => {
    if (currentDisabled) {
      setCurrentDisabled(false)
    } else {
      setCurrentDisabled(true)
    }
  }
  const handleChronic = () => {
    if (chronicDisabled) {
      setChronicDisabled(false)
      setEnableChronic(true)
    } else {
      setChronicDisabled(true)
      setEnableChronic(false)
    }
  }
  const handleConclusion = () => {
    if (conclusionDisabled) {
      setConclusionDisabled(false)
    } else {
      setConclusionDisabled(true)
    }
  }

  return ccmLoading ? (
    <Loading />
  ) : !ccmLoading && !carePlanData?.body ? (
    <HandleApiFail route={`/${orgId}/${orgName}/ccm/patients`} />
  ) : (
    <div>
      <Collapse accordion>
        <Panel header="Medical History" key="0">
          <div>
            {medicalHistory?.map((ques, index) => {
              return (
                <div key={index} className="p-3 mb-2">
                  <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                  <h4 className="text-lg mt-3 mb-2">{ques?.name}</h4>
                  <h5 className="text-lg mb-4">{ques?.title}</h5>
                  <CCMEditFormInput
                    ques={ques}
                    formInputs={formInputs}
                    handleChange={handleChange}
                    handleInput={handleInput}
                    handleAddInputField={handleAddInputField}
                    otherInputs={otherInputs}
                    setOtherInputs={setOtherInputs}
                    medicalDisabled={medicalDisabled}
                    QIndex={index}
                  />
                  <div className="not_applicable mt-4">
                    {ques?.notApplicable !== undefined && (
                      <Checkbox
                        disabled={medicalDisabled}
                        checked={ques?.notApplicable}
                        onChange={e => handleApplicableChange(ques?.id, e, ques)}>
                        Not Applicable
                      </Checkbox>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex justify-end">
            <Button type="primary" className="my-2" onClick={handleMedical}>
              {medicalDisabled ? "Any changes to current question?" : "Save"}
            </Button>
          </div>
        </Panel>

        <Panel header="Medications" key="1">
          <div>
            <h6>
              {medication?.map((ques, index) => {
                return (
                  <div key={index} className="p-3 mb-2">
                    <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                    <h4 className="text-lg mt-3 mb-2">{ques?.name}</h4>
                    <h5 className="text-lg mb-4">{ques?.title}</h5>

                    <CCMEditFormInput
                      ques={ques}
                      formInputs={formInputs}
                      handleChange={handleChange}
                      handleInput={handleInput}
                      handleAddInputField={handleAddInputField}
                      otherInputs={otherInputs}
                      setOtherInputs={setOtherInputs}
                      medicationDisabled={medicationDisabled}
                    />
                    <div className="not_applicable mt-4">
                      {ques?.notApplicable !== undefined && (
                        <Checkbox
                          disabled={medicationDisabled}
                          checked={ques?.notApplicable}
                          onChange={e => handleApplicableChange(ques?.id, e, ques)}>
                          Not Applicable
                        </Checkbox>
                      )}
                    </div>
                  </div>
                )
              })}
            </h6>
          </div>
          <div className="flex justify-end">
            <Button type="primary" className="my-2" onClick={handleMedication}>
              {medicationDisabled ? "Any changes to current question?" : "Save"}
            </Button>
          </div>
        </Panel>

        <Panel header="Current Status" key="2">
          <div>
            <h6>
              {currentStatus?.map((ques, index) => {
                return (
                  <div key={index} className="p-3 mb-2">
                    <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                    <h4 className="text-lg mt-3 mb-2">{ques?.name}</h4>
                    <h5 className="text-lg mb-4">{ques?.title}</h5>
                    <CCMEditFormInput
                      ques={ques}
                      formInputs={formInputs}
                      handleChange={handleChange}
                      handleInput={handleInput}
                      handleAddInputField={handleAddInputField}
                      otherInputs={otherInputs}
                      setOtherInputs={setOtherInputs}
                      currentDisabled={currentDisabled}
                    />
                    <div className="not_applicable mt-4">
                      {ques?.notApplicable !== undefined && (
                        <Checkbox
                          disabled={currentDisabled}
                          checked={ques?.notApplicable}
                          onChange={e => handleApplicableChange(ques?.id, e, ques)}>
                          Not Applicable
                        </Checkbox>
                      )}
                    </div>
                  </div>
                )
              })}
            </h6>
          </div>
          <div className="flex justify-end">
            <Button type="primary" className="my-2" onClick={handleCurrent}>
              {currentDisabled ? "Any changes to current question?" : "Save"}
            </Button>
          </div>
        </Panel>

        <Panel header="Chronic Condition" key="3">
          <div>
            <h6>
              {chronicCondition?.map((ques, index) => {
                return (
                  <div key={index} className="p-3 mb-2">
                    <div>
                      <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                      <h4 className="text-lg mt-3 text-info">{ques?.quesCategory}</h4>
                      <h4 className="text-lg mb-2 text-secondary opacity-75">{ques?.title}</h4>
                      <h5 className="text-lg mb-4">{ques?.name}</h5>
                      <CCMEditFormInput
                        ques={ques}
                        formInputs={formInputs}
                        handleChange={handleChange}
                        handleInput={handleInput}
                        handleAddInputField={handleAddInputField}
                        otherInputs={otherInputs}
                        setOtherInputs={setOtherInputs}
                        chronicDisabled={chronicDisabled}
                      />
                      <div className="not_applicable mt-4">
                        {ques?.notApplicable !== undefined && (
                          <Checkbox
                            disabled={chronicDisabled}
                            checked={ques?.notApplicable}
                            onChange={e => handleApplicableChange(ques?.id, e, ques)}>
                            Not Applicable
                          </Checkbox>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </h6>
          </div>
          <div className="flex justify-end">
            <Button type="primary" className="my-2" onClick={handleChronic}>
              {chronicDisabled ? "Any changes to current question?" : "Save"}
            </Button>
          </div>
          {/* {enableChronic && (
            <div>
              <div>
                <h6 className="mb-0 pb-0">Update Notes</h6>
                {showDownIconUpdate && (
                  <DownOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setVisibleUpdate(true)
                      setShowDownIconUpdate(false)
                    }}
                  />
                )}
                {visibleUpdate && showUpIconUpdate && (
                  <UpOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setVisibleUpdate(false)
                      setShowUpIconUpdate(true)
                      setShowDownIconUpdate(true)
                    }}
                  />
                )}
              </div>
              {visibleUpdate && enableChronic && (
                <div className="note">
                  <textarea
                    className="w-100 shadow"
                    style={{ minHeight: "120px" }}
                    placeholder="Enter Notes Here..."
                  />
                </div>
              )}
            </div>
          )} */}
        </Panel>

        <Panel header="Conclusion" key="4">
          <div>
            <h6>
              {conclusion?.map((ques, index) => {
                // if (conclusionPage === index + 1)
                return (
                  <div key={index} className="p-3 mb-2">
                    <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                    <h4 className="text-lg mt-3 mb-2">{ques?.name}</h4>
                    <h5 className="text-lg mb-4">{ques?.title}</h5>
                    <CCMEditFormInput
                      ques={ques}
                      formInputs={formInputs}
                      handleChange={handleChange}
                      handleInput={handleInput}
                      handleAddInputField={handleAddInputField}
                      otherInputs={otherInputs}
                      setOtherInputs={setOtherInputs}
                      conclusionDisabled={conclusionDisabled}
                    />
                    <div className="not_applicable mt-4">
                      {ques?.notApplicable !== undefined && (
                        <Checkbox
                          disabled={conclusionDisabled}
                          checked={ques?.notApplicable}
                          onChange={e => handleApplicableChange(ques?.id, e, ques)}>
                          Not Applicable
                        </Checkbox>
                      )}
                    </div>
                  </div>
                )
              })}
            </h6>
          </div>
          <div className="flex justify-end">
            <Button type="primary" className="my-2" onClick={handleConclusion}>
              {conclusionDisabled ? "Any changes to current question?" : "Save"}
            </Button>
          </div>
        </Panel>
      </Collapse>

      <div className="flex justify-end mt-5">
        <Button size="large" type="primary" onClick={submitForm} loading={updatePlanLoading}>
          Submit
        </Button>
      </div>
    </div>
  )
}

export default CCMEditMain
