import React, { useEffect, useState } from "react"
import { Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getAllOrgAction } from "../redux/actions/orgDetailsAction"
import moment from "moment"

const SelectOrgDropdown = props => {
  const { className, size, selectedOrg, setSelectedOrg } = props

  const dispatch = useDispatch()
  const { orgData, loading } = useSelector(state => state.orgDetails)
  const { userInfo } = useSelector(state => state.userInfo)
  const [dropdownOptions, setDropdownOptions] = useState([])

  useEffect(() => {
    dispatch(
      getAllOrgAction(
        moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
        moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss")
      )
    )
  }, [])

  useEffect(() => {
    if (userInfo?.orgId && dropdownOptions?.length)
      setSelectedOrg({ id: userInfo?.orgId, name: userInfo?.orgName })
  }, [userInfo, dropdownOptions])

  useEffect(() => {
    if (orgData?.length)
      setDropdownOptions(
        orgData?.map(({ id, organizationName }) => ({
          value: id,
          label: organizationName
        }))
      )
  }, [orgData])

  const onChange = option => {
    setSelectedOrg({ id: option?.value, name: option?.label })
  }

  return (
    <Select
      className={className}
      size={size}
      showSearch
      disabled={loading}
      loading={loading}
      style={{ width: 180 }}
      placeholder="Organization"
      labelInValue
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      value={selectedOrg?.id}
      onChange={onChange}
      rootClassName="capitalize"
      options={dropdownOptions}
    />
  )
}

export default SelectOrgDropdown
