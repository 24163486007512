import { useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"

import { Card } from "antd"

// import PatientFilters from "./PatientFilters"

import { useDispatch, useSelector } from "react-redux"
import {
  enrollmentPatientCountAction,
  getUserCountStatsAction,
  triageStatsCountAction
} from "../../redux/actions/dashboardAction"
import { getrpmDeviceActions } from "../../redux/actions/rpmAction"
import TriageDashboardChart from "./TriageDashboardChart"
import moment from "moment"
import EnrolledPatientsChart from "./EnrolledPatientsChart"
import SelectOrgDropdown from "../../utils/SelectOrgDropdown"
import "./dashboard.css"

const cardClassName = "w-[210px] h-[184px]"
const cardBodyStyle = { padding: 12 }

const DashboardComponent = () => {
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [showDashboardChart, setShowDashboardChart] = useState(false)
  const [showEnrolledPatientsChart, setShowEnrolledPatientsChart] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userInfo } = useSelector(state => state.userInfo)
  const { userCountStats, loading: userCountStatsLoading } = useSelector(
    state => state.getUserCountStats
  )
  const { loading: triageCntLoading, triageStatsCount } = useSelector(
    state => state.triageStatsCount
  )
  const { loading: enrollmentCntLoading, enrollmentPatientCount } = useSelector(
    state => state.enrollmentPatientCount
  )

  const rpmDevices = JSON.parse(localStorage.getItem("monitorTypes"))

  useEffect(() => {
    if (!selectedOrg?.id) return
    dispatch(getUserCountStatsAction(selectedOrg.id))
    if (!rpmDevices?.length) dispatch(getrpmDeviceActions())
    dispatch(
      triageStatsCountAction(
        userInfo?.id,
        {
          startDate: moment.utc(new Date()).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS+00:00"),
          endDate: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS+00:00")
        },
        selectedOrg.id
      )
    )
    dispatch(
      enrollmentPatientCountAction(
        {
          startDate: moment(new Date()).startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
          endDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
        },
        selectedOrg.id
      )
    )
  }, [selectedOrg]) //eslint-disable-line

  return (
    <div>
      {/* <PatientFilters
        userInfo={userInfo}
        handleReset={handleReset}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        setSearchQuery={setSearchQuery}
        setDashboardCard={setDashboardCard}
      /> */}
      <SelectOrgDropdown
        size="large"
        className="borderless-dropdown"
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
      />
      <div className="flex flex-wrap gap-3 mt-4 justify-around md:justify-normal">
        <Card
          hoverable
          loading={userCountStatsLoading || !selectedOrg}
          className={cardClassName}
          bodyStyle={cardBodyStyle}
          onClick={() => navigate(`/${selectedOrg?.id}/${selectedOrg?.name}/patient`)}>
          <div className="flex items-center flex-col">
            <img alt="icon-patient-group" src="\assets\icon-group.png" width="85px" />
            <p className="mt-2 text-[#666B7B]">Total Patients</p>
            <p className="text-3xl font-semibold text-current">{userCountStats?.patientCount}</p>
          </div>
        </Card>

        <Card
          hoverable
          loading={userCountStatsLoading || !selectedOrg}
          className={cardClassName}
          bodyStyle={cardBodyStyle}
          onClick={() => setShowDashboardChart(!showDashboardChart)}>
          <div className="flex items-center flex-col">
            <img alt="icon-triage" src="/assets/triage-icon.png" width="85px" />
            <p className="mt-2 text-[#666B7B]">Critical Patients</p>
            <p className="text-3xl font-semibold text-current">{triageStatsCount?.receivedCount}</p>
          </div>
        </Card>

        <Card
          hoverable
          loading={userCountStatsLoading || !selectedOrg}
          className={cardClassName}
          bodyStyle={cardBodyStyle}
          onClick={() => setShowEnrolledPatientsChart(!showEnrolledPatientsChart)}>
          <div className="flex items-center flex-col">
            <img
              alt="icon-enrolled-patients"
              src="/assets/enrolled-patients-icon.png"
              width="85px"
            />
            <p className="mt-2 text-[#666B7B]">Enrolled Patients</p>
            <p className="text-3xl font-semibold text-current">
              {enrollmentPatientCount?.enrollmentTrack}
            </p>
          </div>
        </Card>
      </div>

      {showDashboardChart && (
        <div className="mt-4">
          <TriageDashboardChart selectedOrg={selectedOrg} />
        </div>
      )}

      {showEnrolledPatientsChart && (
        <div className="mt-4">
          <EnrolledPatientsChart
            showEnrolledPatientsChart={showEnrolledPatientsChart}
            selectedOrg={selectedOrg}
          />
        </div>
      )}

      {/* <Card
          hoverable
          loading={userCountStatsLoading}
          className="min-w-[300px] cursor-pointer"
          onClick={() => navigate("/provider")}>
          <div className="flex items-center flex-col">
            <img
              alt="icon-doctor"
              src="/assets/doctor.png"
              width="85px"
              style={{ marginBottom: "15px" }}
            />
            <p className="my-3">Total Providers</p>
            <p className="text-3xl font-semibold text-current">{userCountStats?.providerCount}</p>
          </div>
        </Card>

        <Card
          hoverable
          loading={userCountStatsLoading}
          className="min-w-[300px] cursor-pointer"
          onClick={() => navigate("/caretaker")}>
          <div className="flex items-center flex-col">
            <img alt="icon-onboarding" src="/assets/icon-onboarding.png" width="100px" />
            <p className="my-3">Total Caretaker</p>
            <p className="text-3xl font-semibold text-current">{userCountStats?.caretakerCount}</p>
          </div>
        </Card> */}
    </div>
  )
}

export default DashboardComponent
