import { useEffect, useState } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Table, Button, Card, Tooltip } from "antd"
import Loading from "../../layouts/Loading/Loading"
import { getTriageFalseReport } from "../../../redux/actions/patientAction"
import moment from "moment/moment"

const ViewTriageFalseReport = () => {
    const [falseReadingData, setFalseReadingData] = useState([]);
    const { orgId, orgName } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const patientInfo = location?.state?.patientInfo
    const detail = location?.state?.detail
    const { triageFalseReport, loading } = useSelector(state => state.getTriageFalseReport);

    useEffect(() => {
        if (detail?.readingIds?.length > 0) {
            dispatch(getTriageFalseReport(patientInfo?.id, { readingIds: detail?.readingIds }));
        }
    }, [patientInfo, detail])

    useEffect(() => {
        let newData = [];
        let sno = 1
        triageFalseReport?.sort((a, b) => moment(b.receivedAt) - moment(a.receivedAt))?.map(({ deviceType, readingDetails, readingId, receivedAt }) => {
            newData.push({
                key: sno,
                sNo: sno,
                id: patientInfo?.id,
                deviceType: deviceType,
                readingDetails: readingDetails,
                readingId: readingId,
                receivedAt,
                resolvedBy: detail?.resolvedBy,
                comment: detail?.comment
            })
            sno += 1
        })
        setFalseReadingData(newData);
    }, [triageFalseReport])

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sNo",
            key: "sNo"
        },
        {
            title: "Device",
            dataIndex: "deviceType",
            key: "deviceType",
            render: (deviceType, { id }) => (
                <p className="capitalize">
                    {deviceType}
                </p >
            ),
            sorter: (a, b) => a?.deviceType.localeCompare(b?.deviceType)
        },
        {
            title: "Reading",
            dataIndex: "readingDetails",
            key: "readingDetails",
            render: (readingDetails, { deviceType }) => (
                <div>
                    {deviceType === "oximeter" &&
                        <div>
                            <p>SpO2 : {readingDetails?.spo ?? "-"}</p>
                            <p>Pulse rate : {readingDetails?.pulseRate ?? "-"} {readingDetails?.pulseUnit ?? ""}</p>
                        </div>
                    }
                    {deviceType === "blood pressure machine" &&
                        <div>
                            <p>Heart rate : {readingDetails?.heartRate ?? "-"} {readingDetails?.pulseUnit ?? ""}</p>
                            <p>Systolic pressure : {readingDetails?.systolicPressure ?? "-"} {readingDetails?.bloodPressureUnit ?? ""}</p>
                            <p>Diastolic pressure : {readingDetails?.diastolicPressure ?? "-"} {readingDetails?.bloodPressureUnit ?? ""}</p>
                        </div>
                    }
                    {deviceType === "glucose machine" &&
                        <div>
                            <p>Glucose level : {readingDetails?.glucoseLevel ?? "-"} {readingDetails?.glucoseUnit ?? ""}</p>
                        </div>
                    }
                </div>
            )
        },
        {
            title: "Reading Date",
            dataIndex: "receivedAt",
            key: "receivedAt",
            render: (receivedAt) => (
                <div className="w-full flex gap-2">
                    <span>{!receivedAt ? "-" : moment(receivedAt).format("MM/DD/YYYY [at] hh:mm A")}</span>
                </div>
            ),
            sorter: (a, b) => moment(a?.receivedAt) - moment(b?.receivedAt)
        },
        {
            title: "Resolved By",
            dataIndex: "resolvedBy",
            key: "resolvedBy",
            render: (resolvedBy) => (
                <div className="w-full flex gap-2">
                    <span>{!resolvedBy ? "-" : resolvedBy}</span>
                </div>
            ),
            sorter: (a, b) => a?.resolvedBy.localeCompare(b?.resolvedBy)
        },
        {
            title: "Comments",
            dataIndex: "comment",
            key: "comment",
            render: (comment) => (
                <div className="w-full flex gap-2">
                    <span>{!comment ? "-" : comment}</span>
                </div>
            ),
        }
    ]

    return loading ? (
        <div className="loaderLoading">
            <Loading />
        </div>
    ) : (
        <>
            <Card className="rounded-b-none">
                <div className="d-flex justify-content-between">
                    <h3 className="text-xl capitalize hover:text-primary cursor-pointer"
                        onClick={() => {
                            navigate(`/${orgId}/${orgName}/patient/${id}`)
                        }}>
                        {patientInfo?.firstName} {patientInfo?.middleName} {patientInfo?.lastName}
                    </h3>
                </div>
            </Card>
            <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={falseReadingData ? falseReadingData : []}
            />
        </>
    )
}

export default ViewTriageFalseReport