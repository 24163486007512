import React, { useEffect, useState } from "react"
import { Card, Select, Spin } from "antd"
import MyDatePicker from "../../utils/MyDatePicker"
import { useDispatch, useSelector } from "react-redux"
import { fetchEnrollmentStatsAction } from "../../redux/actions/dashboardAction"
import { CaretDownFilled } from "@ant-design/icons"
import "./EnrolledPatientsChart.css"
import moment from "moment"
import Plot from "react-plotly.js"

const { RangePicker } = MyDatePicker

const activeTimelineStyle =
  "bg-primary text-[#fff] min-w-[74px] h-[28px] px-2 rounded-[4px] font-semibold"
const defaultTimelineStyle = "bg-[#D8DAE5] min-w-[74px] h-[28px] px-2 rounded-[4px] font-semibold"

const PATIENT_STATUSES = Object.freeze({
  signup_pending: "Signup Pending",
  signup_initiated: "Signup Initiated",
  signed_up: "Signed Up",
  device_received: "Device received",
  denied_services: "Denied Services",
  facing_issue: "Facing Issue",
  active: "Active",
  inactive: "Inactive"
})

const STATUS_CHART_COLOR = Object.freeze({
  "Signup Pending": "#FA9189",
  "Signup Initiated": "#FFE699",
  "Signed Up": "#B3F5BC",
  "Device received": "#88C1FF",
  "Denied Services": "#DE3163",
  "Facing Issue": "#AF7AC5",
  Active: "#00FFFF",
  Inactive: "#5D6D7E"
})

const TIME_LINES = Object.freeze({
  ten_min: "10 min",
  thirty_min: "30 min",
  one_hr: "1 Hr",
  one_day: "1 Day",
  one_week: "1 Week",
  one_month: "1 Month",
  one_year: "1 Year"
})

const GRAPH_TYPES = Object.freeze({
  lines: "lines",
  bar: "bar",
  scatter: "scatter"
})

const getTypeOpt = ({ graphType, index, color }) => {
  if (graphType === GRAPH_TYPES?.lines) {
    return {
      mode: graphType,
      showlegend: true,
      line: {
        shape: "spline",
        color: color,
        width: 4
      }
    }
  } else if (graphType === GRAPH_TYPES?.bar) {
    return {
      type: graphType,
      width: 0.8 - index * 0.1,
      marker: { color }
    }
  } else if (graphType === GRAPH_TYPES?.scatter) {
    return {
      type: graphType,
      mode: "lines",
      fill: "tozeroy",
      line: { color, shape: "spline", width: 4 }
    }
  }
}

const mergeCategories = (...categories) => {
  const allDatesSet = new Set([...categories])
  const allDates = Array.from(allDatesSet).sort((a, b) => new Date(a) - new Date(b))
  return allDates
}

const alignMultiData = (dates, allDates, data) => {
  const dataMap = dates?.reduce((acc, date, index) => {
    acc[date] = data[index]
    return acc
  }, {})
  return allDates?.map(date => dataMap[date] || 0)
}

const processData = (dates, timeline) => {
  const now = moment()
  const data = []
  const categories = []

  switch (timeline) {
    case TIME_LINES.ten_min:
      for (let i = 0; i < 10; i++) {
        const time = now.clone().subtract(9 - i, "minutes")
        categories.push(time.format("HH:mm"))
        data.push(dates?.filter(date => moment(date).isSame(time, "minute")).length || 0)
      }
      break
    case TIME_LINES.thirty_min:
      for (let i = 0; i < 30; i++) {
        const time = now.clone().subtract(29 - i, "minutes")
        categories.push(time.format("HH:mm"))
        data.push(dates?.filter(date => moment(date).isSame(time, "minute")).length || 0)
      }
      break
    case TIME_LINES.one_hr:
      for (let i = 0; i < 60; i++) {
        const time = now.clone().subtract(59 - i, "minutes")
        categories.push(time.format("HH:mm"))
        data.push(dates?.filter(date => moment(date).isSame(time, "minute")).length || 0)
      }
      break
    case TIME_LINES.one_day:
      for (let i = 0; i < 24; i++) {
        const time = now.clone().subtract(23 - i, "hours")
        categories.push(time.format("HH:mm"))
        data.push(dates?.filter(date => moment(date).isSame(time, "hour")).length || 0)
      }
      break
    case TIME_LINES.one_week:
      for (let i = 0; i < 7; i++) {
        const time = now.clone().subtract(6 - i, "days")
        categories.push(time.format("D MMM"))
        data.push(dates?.filter(date => moment(date).isSame(time, "day")).length || 0)
      }
      break
    case TIME_LINES.one_month:
      for (let i = 0; i < 30; i++) {
        const time = now.clone().subtract(29 - i, "days")
        categories.push(time.format("D MMM"))
        data.push(dates?.filter(date => moment(date).isSame(time, "day")).length || 0)
      }
      break
    case TIME_LINES.one_year:
      for (let i = 0; i < 12; i++) {
        const time = now.clone().subtract(11 - i, "months")
        categories.push(time.format("MMM"))
        data.push(dates?.filter(date => moment(date).isSame(time, "month")).length || 0)
      }
      break
    default:
      // it means timeline is an array of date range
      const groupedDates = dates?.reduce((acc, date) => {
        const formattedDate = moment(date).format("YYYY-MM-DD")
        if (!acc[formattedDate]) {
          acc[formattedDate] = []
        }
        acc[formattedDate].push(date)
        return acc
      }, {})

      groupedDates &&
        Object.keys(groupedDates)?.forEach(date => {
          categories.push(moment(date).format("MM/DD/YYYY"))
          data.push(groupedDates[date].length)
        })
      break
  }

  return { data, categories }
}

const timelineToRange = timeline => {
  switch (timeline) {
    case TIME_LINES.ten_min:
      return [moment(new Date()).subtract(10, "minutes"), moment(new Date())]
      break
    case TIME_LINES.thirty_min:
      return [moment(new Date()).subtract(30, "minutes"), moment(new Date())]
      break
    case TIME_LINES.one_hr:
      return [moment(new Date()).subtract(1, "hours"), moment(new Date())]
      break
    case TIME_LINES.one_day:
      return [moment(new Date()).subtract(1, "days"), moment(new Date())]
      break
    case TIME_LINES.one_week:
      return [moment(new Date()).subtract(6, "days"), moment(new Date())]
      break
    case TIME_LINES.one_month:
      return [moment(new Date()).subtract(1, "months").add(1, "day"), moment(new Date())]
      break
    case TIME_LINES.one_year:
      return [moment(new Date()).subtract(1, "years").add(1, "month"), moment(new Date())]
      break
    default:
      break
  }
}

const EnrolledPatientsChart = ({ selectedOrg, showEnrolledPatientsChart }) => {
  const [graphType, setGraphType] = useState(GRAPH_TYPES?.lines)
  const [selectedPatientStatus, setSelectedPatientStatus] = useState([
    PATIENT_STATUSES.signup_pending
  ])
  const [selectedTimeline, setSelectedTimeline] = useState(TIME_LINES?.one_month)
  const [graphSeries, setGraphSeries] = useState([])
  const [range, setRange] = useState(timelineToRange(selectedTimeline))

  const dispatch = useDispatch()

  const { enrollmentStats, loading } = useSelector(state => state.enrollmentStats)

  const handleRangeChange = date => {
    setRange(date)
    if (!date) {
      handleTimeline(TIME_LINES?.one_month)
    } else if (date && date?.length === 2) {
      setSelectedTimeline(null)
    }
  }

  const handleTimeline = timeline => {
    setRange(timelineToRange(timeline))
    setSelectedTimeline(timeline)
  }

  useEffect(() => {
    if (showEnrolledPatientsChart) {
      document
        .getElementById("enrollPatientChart")
        ?.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  }, [showEnrolledPatientsChart])

  useEffect(() => {
    if (range && range.length === 2 && selectedOrg?.id) {
      dispatch(
        fetchEnrollmentStatsAction(
          {
            startDate: moment(range[0]).format("YYYY-MM-DD"),
            endDate: moment(range[1]).format("YYYY-MM-DD")
          },
          selectedOrg.id
        )
      )
    }
  }, [range, selectedOrg])

  useEffect(() => {
    let series = []

    if (selectedPatientStatus.length === 1) {
      const processedData = processData(enrollmentStats?.[selectedPatientStatus], selectedTimeline)
      series = [
        {
          x: processedData.categories,
          y: processedData.data,
          name: selectedPatientStatus?.[0],
          ...getTypeOpt({ graphType, color: STATUS_CHART_COLOR?.[selectedPatientStatus] })
        }
      ]
    } else {
      if (selectedTimeline) {
        const multiData = selectedPatientStatus?.map((status, index) => {
          const { categories, data } = processData(enrollmentStats?.[status], selectedTimeline)
          return {
            x: categories,
            y: data,
            name: status,
            ...getTypeOpt({ graphType, index, color: STATUS_CHART_COLOR?.[status] })
          }
        })
        series = multiData
      } else {
        const allData = selectedPatientStatus?.map(status => {
          const { categories, data } = processData(enrollmentStats?.[status], selectedTimeline)
          return {
            categories,
            data,
            name: status
          }
        })

        const commonCategories = mergeCategories(...allData?.flatMap(d => d?.categories))

        const alignedData = allData?.map((d, index) => ({
          x: commonCategories,
          y: alignMultiData(d?.categories, commonCategories, d?.data),
          name: d?.name,
          ...getTypeOpt({ graphType, index, color: STATUS_CHART_COLOR?.[d?.name] })
        }))

        series = alignedData
      }
    }

    setGraphSeries(series)
  }, [enrollmentStats, selectedPatientStatus, graphType])

  return (
    <Card id="enrollPatientChart">
      <div className="flex justify-between flex-wrap gap-3">
        <h2 className="text-xl font-semibold">Enrolled Patients</h2>
        <div className="min-h-[40px] border border-[#DADADA] rounded-[6px] flex">
          <div className="pl-3 pr-2 border-r border-[#DADADA] text-[#979797] flex items-center">
            Filter By
          </div>
          <span className="pl-2 pr-1 flex items-center font-medium">Patient Status</span>
          <Select
            showSearch={false}
            mode="multiple"
            value={selectedPatientStatus}
            className="drp-dwn-border-rmv m-auto"
            dropdownStyle={{
              width: "155px"
            }}
            onChange={value =>
              value?.length === 0
                ? setSelectedPatientStatus([PATIENT_STATUSES.signup_pending])
                : setSelectedPatientStatus(value)
            }
            options={Object.values(PATIENT_STATUSES)?.map(status => ({
              value: status,
              label: status
            }))}
          />
        </div>
      </div>

      <div className="mt-2 mb-4">
        <span className="text-[#979797]">Graphs: </span>
        <Select
          suffixIcon={<CaretDownFilled style={{ color: "#000" }} />}
          className="drp-dwn-border-rmv"
          dropdownStyle={{
            width: "fit-content"
          }}
          defaultValue={graphType}
          onChange={value => setGraphType(value)}
          options={[
            { label: "Line Graph", value: GRAPH_TYPES?.lines },
            { label: "Bar Graph", value: GRAPH_TYPES?.bar },
            { label: "Mountain Graph", value: GRAPH_TYPES?.scatter }
          ]}
        />
      </div>

      <div className="mb-3 flex justify-between flex-wrap gap-3">
        <div className="flex items-center flex-wrap gap-2">
          <span>Timeline: </span>
          {Object.values(TIME_LINES)?.map((timeline, idx) => (
            <button
              key={idx}
              className={selectedTimeline === timeline ? activeTimelineStyle : defaultTimelineStyle}
              onClick={() => handleTimeline(timeline)}>
              {timeline}
            </button>
          ))}
        </div>

        <RangePicker
          value={range}
          onChange={handleRangeChange}
          format="MM/DD/YYYY"
          style={{ width: "250px" }}
          disabledDate={current => current > moment()}
          allowClear={true}
        />
      </div>

      <div>
        <Spin spinning={loading === true}>
          <Plot
            data={graphSeries}
            style={{ width: "100%", height: "400px" }}
            layout={{
              xaxis: {
                tickmode: "linear",
                automargin: true,
                dtick:
                  selectedTimeline === TIME_LINES?.ten_min
                    ? 0
                    : selectedTimeline === TIME_LINES?.thirty_min
                    ? 2
                    : selectedTimeline === TIME_LINES?.one_hr
                    ? 3
                    : selectedTimeline === TIME_LINES?.one_day
                    ? 0
                    : selectedTimeline === TIME_LINES?.one_week
                    ? 0
                    : selectedTimeline === TIME_LINES?.one_month
                    ? 1
                    : selectedTimeline === TIME_LINES?.one_year
                    ? 0
                    : graphSeries?.[0]?.x?.length < 12
                    ? 0
                    : 3
              },
              yaxis: {
                automargin: true,
                zeroline: false, // This removes the y=0 line
                showgrid: graphType === GRAPH_TYPES?.bar ? true : false,
                rangemode: "normal"
              },
              legend: {
                x: 1,
                y: -0.15,
                xanchor: "right",
                yanchor: "middle",
                orientation: "h",
                traceorder: "normal",
                showForSingleSeries: true,
                font: {
                  family: "sans-serif",
                  size: 12,
                  color: "#000"
                }
              },
              barmode: "overlay",
              margin: {
                l: 30,
                r: 30,
                t: 40,
                b: 60
              },
              autosize: true
            }}
            config={{
              displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "select2d",
                "lasso2d",
                "hoverClosestCartesian",
                "hoverCompareCartesian"
              ]
            }}
          />
        </Spin>
      </div>
    </Card>
  )
}

export default EnrolledPatientsChart
